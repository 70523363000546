import React, { PureComponent, createRef } from "react";
import Measure from "react-measure";

import DevicesFilter from "./DevicesFilter";
import DevicesList from "./DevicesList";

class DevicesContainer extends PureComponent {
  state = {
    dimensions: {
      width: -1,
      height: -1,
    },
  };
  usersListRef = createRef();

  render() {
    const { height, width } = this.state.dimensions;
    const { onFilterChanged, refresh, filteredList, selectedElements, selectedGroups, selectElement, selectGroup, isFetching, devicesListRef } = this.props;

    return (
      <Measure
        bounds
        onResize={contentRect => {
          if (this.state.dimensions.height !== contentRect.bounds.height) { // TODO: add debounce to improve performance?
            this.setState({ dimensions: contentRect.bounds });
          }
        }}>
        {({ measureRef }) => (
          <aside ref={measureRef}>
            <DevicesFilter onChange={onFilterChanged} refresh={refresh} />

            <DevicesList
              filteredDevices={filteredList}
              selectedDevices={selectedElements}
              selectedGroups={selectedGroups}
              selectDevice={selectElement}
              selectGroup={selectGroup}
              isFetching={isFetching}
              refresh={refresh}
              height={height - 46}
              width={width}
              ref={devicesListRef}
            />
          </aside>
        )}
      </Measure>
    );
  }
}

export default DevicesContainer;
