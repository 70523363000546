import React, { Component } from "react";
import PropTypes from "prop-types";

class DynamicConfirmButton extends Component {
  state = {
    showingConfirmation: false,
  };

  componentWillUnmount() {
    this.clearConfirmationTimeout();
  }

  clearConfirmationTimeout = () => {
    if (this.confirmationTimeout) {
      clearTimeout(this.confirmationTimeout);
    }
  };

  buttonClicked = e => {
    e.stopPropagation();

    const { showingConfirmation } = this.state;
    const {
      confirmationTimeout,
      onClick,
      onConfirm,
      onConfirmationTimedOut,
    } = this.props;

    if (showingConfirmation) {
      this.clearConfirmationTimeout();
      this.setState({ showingConfirmation: false }, onConfirm);
    } else {
      this.setState({ showingConfirmation: true }, () => {
        onClick?.();
        if (confirmationTimeout) {
          this.confirmationTimeout = setTimeout(() => {
            if (!this.state.showingConfirmation) {
              return;
            }

            this.confirmationTimeout = undefined;
            this.setState({ showingConfirmation: false }, () => {
              onConfirmationTimedOut?.();
            });
          }, confirmationTimeout);
        }
      });
    }
  };

  render() {
    const { showingConfirmation } = this.state;
    const {
      label,
      confirmLabel,
      onClick,
      onConfirm,
      confirmationTimeout,
      onConfirmationTimedOut,
      ...rest
    } = this.props;

    const buttonLabel = showingConfirmation ? confirmLabel : label;

    return (
      <button type="button" {...rest} onClick={this.buttonClicked}>
        {buttonLabel}
      </button>
    );
  }
}

DynamicConfirmButton.propTypes = {
  label: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onConfirmationTimedOut: PropTypes.func,
  confirmationTimeout: PropTypes.number,
};

export default DynamicConfirmButton;
