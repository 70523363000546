import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Nav from "./Nav";

import Terminals from "../terminals";
import Clients from "../clients";
import Devices from "../devices";
import SimCards from "../simCards";
import Dashboard from "../dashboard";
import Auth from "../../Auth";

const componentToFunction = Component => renderProps => (
  <Component {...renderProps} />
);

const TerminalsComponent = componentToFunction(Terminals);
const ClientsComponent = componentToFunction(Clients);
const DevicesComponent = componentToFunction(Devices);
const SimCardsComponent = componentToFunction(SimCards);
const DashboardComponent = componentToFunction(Dashboard);

const pages = [
  {
    name: "Terminale",
    path: "/terminals",
    pages: [],
    requiredPermissions: ["managingTerminals"]
  },
  {
    name: "Klienci",
    path: "/clients",
    pages: [],
    requiredPermissions: ["managingClients"]
  },
  {
    name: "Pojazdy",
    path: "/devices",
    pages: [],
    requiredPermissions: ["managingDevicesServices"]
  },
  {
    name: "Karty SIM",
    path: "/simcards",
    pages: [],
    requiredPermissions: ["managingSimCards"]
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    pages: [],
    requiredPermissions: ["accessToDashboards"]
  }
];

class Home extends Component {
  render() {
    return (
      <Auth.Consumer>
        {({ hasPermissions }) => {
          const filteredPages = pages.filter(page => !page.requiredPermissions || hasPermissions(page.requiredPermissions));

          return (
            <div className="page">
              <Nav pages={filteredPages} />

              <main>
                <Switch>
                  <Route
                    path="/terminals/(details)?/:terminalId?"
                    render={TerminalsComponent}
                  />
                  <Route
                    path="/clients"
                    render={ClientsComponent}
                  />
                  <Route
                    path="/devices/(services)?/:deviceId?"
                    render={DevicesComponent}
                  />
                  <Route
                    path="/simcards/(details)?/:simCardId?"
                    render={SimCardsComponent}
                  />
                  <Route
                    path="/dashboard"
                    render={DashboardComponent}
                  />
                  <Redirect from="/" exact to={filteredPages.length ? filteredPages[0].path : "/"} />
                </Switch>
              </main>
            </div>
          )}}
      </Auth.Consumer>
    );
  }
}

export default Home;
