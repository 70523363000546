import React, { Component, Fragment } from "react";

import { formatDate } from "../../../../../dateUtils";
import AddService from "./AddService";

import "./DeviceServicesView.css";
import EndServiceButton from "./EndServiceButton";

class DeviceServicesView extends Component {
  render() {
    const { isFetching, services } = this.props;
    
    if (isFetching || !services) {
      return <div className="loading-container"></div>
    }

    return (
      <Fragment>
        <table className="table table-striped services-table" style={{ overflowY: "auto" }}>
          <thead>
            <tr>
              <th style={{ width: "45px" }}>Id</th>
              <th>Nazwa</th>
              <th style={{ width: "130px" }}>Data rozpoczęcia</th>
              <th style={{ width: "95px" }}>Data końca</th>
              <th style={{ width: "60px" }}>Akcja</th>
            </tr>
          </thead>

          <tbody>
            {services.map(service => (
              <tr key={service.id}>
                <td className="valign-middle">{service.id}</td>
                <td className="valign-middle">{service.name}</td>
                <td className="text-center valign-middle">{formatDate(service.start, false, false)}</td>
                <td className="text-center valign-middle">{service.end ? formatDate(service.end, false, false) : "-"}</td>
                <td><EndServiceButton id={service.id} onSuccess={this.props.refreshServices} startDate={service.start} endDate={service.end} /></td>
              </tr>
            ))}
          </tbody>
        </table>

        <AddService deviceId={this.props.device.id} onSuccess={this.props.refreshServices} />
      </Fragment>
    );
  }
}

export default DeviceServicesView;
