import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Filter } from "../../components";
import AddClient from "./AddClient";
import Auth from "../../Auth";

const tooltip = (
  <div className="text-left">
    <span>Filtrowanie po:</span>
    <ul className="mb-0 pl-4">
      <li>loginie i ID operatora</li>
      <li>nazwie i numerze NIP klienta</li>
      <li>imieniu, nazwisku i loginie użytkownika</li>
    </ul>
  </div>
);

class UsersFilter extends PureComponent {
  render() {
    const { options, formatOptionLabel } = this.props;

    return (
      <Auth.Consumer>
        {({ hasPermissions }) => (
          <Filter
            refresh={() => this.props.refresh()}
            onChange={this.props.onChange}
            options={options}
            formatOptionLabel={formatOptionLabel}
            tooltip={tooltip}
            addons={
              hasPermissions("addingClients")
                ? <AddClient onSuccess={this.props.refresh} />
                : undefined
            } />
        )}
      </Auth.Consumer>
    );
  }
}

UsersFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default UsersFilter;
