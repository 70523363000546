import React, { Fragment, PureComponent } from "react";
import DatePicker from "react-datepicker";
import memoize from "memoize-one";
import { startOfMonth } from "date-fns";

import { getServices, getClientsServices } from "../../../../api";
import { DataProvider } from "../../../../components";
import ServicesTable from "./ServicesTable";

import "react-datepicker/dist/react-datepicker.css";

class Input extends PureComponent {
  render() {
    return (
      <input
        type="text"
        style={{ padding: "6px 8px", height: "auto", display: "inline-block" }}
        {...this.props}
      />
    );
  }
}

class Services extends PureComponent {
  state = {
    date: startOfMonth(new Date()),
    services: null,
    clientsServices: null,
    fetchingServices: false,
    fetchingClientsServices: false,
  };

  onChangeDate = date => {
    this.setState(() => ({ date }), this.refreshClientsServices);
  };

  setServices = (response, err) => {
    if (err) {
      return;
    }

    this.setState(() => ({ services: response.list }));
  };

  setFetchingServices = isFetchingServices => {
    this.setState(() => ({ isFetchingServices }));
  };
  
  setClientsServices = (response, err) => {
    if (err) {
      return;
    }
    this.setState(() => ({ clientsServices: response.list }));
  };

  setRefreshClientsServices = refreshClientsServices => {
    this.refreshClientsServices = refreshClientsServices;
  };

  setFetchingClientsServices = isFetchingClientsServices => {
    this.setState(() => ({ isFetchingClientsServices }));
  };

  servicesPayload = memoize(() => ({}));

  clientsServicesPayload = memoize((operatorId, date) => ({ operatorId, date }));

  render() {
    const { services, clientsServices } = this.state;

    const servicesPayload = this.servicesPayload();
    const clientsServicesPayload = this.clientsServicesPayload(this.props.operator.id, this.state.date);

    return (
      <Fragment>
        <DataProvider
          endpoint={getServices}
          data={servicesPayload}
          onDataChanged={this.setServices}
          onDataFetching={this.setFetchingServices}
        />

        <DataProvider
          endpoint={getClientsServices}
          data={clientsServicesPayload}
          onDataChanged={this.setClientsServices}
          fetchMethodSetter={this.setRefreshClientsServices}
          onDataFetching={this.setFetchingClientsServices}
        />

        <DatePicker
          popperClassName="services-date-popper"
          className="form-control"
          wrapperClassName="pb-2 services-date-wrapper"
          customInput={<Input />}
          locale="pl"
          dateFormat="yyyy-MM-dd"
          selected={this.state.date}
          onChange={this.onChangeDate}
          placeholderText={"Wybierz miesiąc"}
          showMonthYearPicker
          showFullMonthYearPicker
          popperPlacement="right"
          popperModifiers={{
            offset: {
              enabled: true,
              offset: "100px, 0px",
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: "scrollParent",
            },
          }}
        />

        <ServicesTable isFetching={this.state.fetchingServices || this.state.isFetchingClientsServices} services={services} clientsServices={clientsServices} />
      </Fragment>
    );
  }
}

export default Services;