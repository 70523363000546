import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputGroup, InputGroupAddon, Button, Tooltip } from "reactstrap";
import classNames from "classnames";

import { isFunction, generateRandomString } from "../utils";

class LabelValue extends PureComponent {
  valueId = generateRandomString(12, false, false);

  state = {
    isEditing: false,
    editValue: this.props.defaultValue,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!prevState.isEditing && this.state.isEditing) {
      document.addEventListener("keydown", this.onDocumentKeyDown, false);
    }
  };

  onDocumentKeyDown = e => {
    if (e.key === "Escape") {
      document.removeEventListener("keydown", this.onDocumentKeyDown, false);
      this.endEditing();
    }
  }; 

  onEditValueChanged = e => {
    const { value } = e.target;

    this.setEditValue(value);
  };

  setEditValue = editValue => {
    this.setState(() => ({ editValue }));
  };

  startEditing = () => {
    const value = isFunction(this.props.value) ? this.props.value({ isEditing: true }) : this.props.value;

    this.setState(prevState => ({ isEditing: true, editValue: value || this.props.defaultValue }));
  };

  endEditing = () => {
    this.setState(() => ({ isEditing: false, editValue: this.props.defaultValue }));
  };

  saveNewValue = (...args) => {
    const { editValue } = this.state;

    Promise.resolve(this.props.onEdited?.(editValue, ...args))
      .then(result => {
        if (result !== false) { // support for validation
          this.endEditing();
        }
      });
  };

  onKeyDown = e => {
    if (e.key === "Escape") {
      this.endEditing();
    }
    else if (e.key === "Enter") {
      e.preventDefault();
      this.saveNewValue();
    }
  };

  render() {
    const { label, hidden, labelWidth, valueWidth, editable, maxLength, description } = this.props;
    const { isEditing, editValue } = this.state;
    const { setEditValue, saveNewValue, onKeyDown } = this;

    if (hidden) {
      return null;
    }

    let valueField = null;

    if (isEditing) {
      valueField = (
        <form>
          <InputGroup size="sm">
            {this.props.inputEdit
              ? this.props.inputEdit({ editValue, setEditValue, onKeyDown })
              : (
                  <Fragment>
                    <input
                      type="text"
                      className="form-control"
                      value={editValue}
                      onChange={this.onEditValueChanged}
                      onKeyDown={this.onKeyDown}
                      maxLength={maxLength}
                      autoFocus
                    />
                    
                    {maxLength && (
                      <Tooltip placement="left" target={this.valueId} isOpen>
                        Pozostało znaków: {maxLength - (editValue.length || 0)}
                      </Tooltip>
                    )}
                  </Fragment>
              )
            }
            <InputGroupAddon addonType="append">
              {this.props.addon ? this.props.addon(this.state, { setEditValue, saveNewValue, editValue }) : null}
              <Button size="sm" title="Zapisz" onClick={this.saveNewValue}><FontAwesomeIcon icon="check" /></Button>
              <Button size="sm" title="Anuluj" onClick={this.endEditing}><FontAwesomeIcon icon="times" /></Button>
            </InputGroupAddon>
          </InputGroup>
        </form>
      );
    }
    else {
      const value = isFunction(this.props.value) ? this.props.value(({ ...this.props, ...this.state })) : this.props.value;

      valueField = (
        <Fragment>
          {!this.props.customValueField && (
            <div className="d-inline-block" style={{ width: "calc(1.125em + 0.5rem)" }}>
              {editable
                ? (
                  <FontAwesomeIcon
                    title="Edytuj"
                    icon="edit"
                    className={classNames("clickable text-primary")}
                    onClick={this.startEditing} />
                )
                : <>&nbsp;</>
              }
            </div>
          )}

          {value === 0
            ? value
            : value || (editable ? null : <>&nbsp;</>)}
        </Fragment>
      );
    }

    return (
      <Fragment>
        <dt className={classNames(labelWidth || "col-4", { "mb-1": !!description })}>
          {label}
          {description && <div><small className="text-muted">{description}</small></div>}
        </dt>
        <dd className={valueWidth || "col-8"} id={this.valueId}>{valueField}</dd>
      </Fragment>
    );
  }
}

LabelValue.defaultProps = {
  defaultValue: ""
};

LabelValue.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element, PropTypes.func]),
  hidden: PropTypes.bool,
  labelWidth: PropTypes.string,
  valueWidth: PropTypes.string,
  editable: PropTypes.bool,
  onEdited: PropTypes.func,
  addons: PropTypes.func,
  customValueField: PropTypes.bool,
  maxLength: PropTypes.number,
};

export default LabelValue;
