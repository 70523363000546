import React, { Component, Fragment } from "react";
import memoize from "memoize-one";

import { getClientPrices, getServices } from "../../../../../api";
import { DataProvider } from "../../../../../components";
import PriceListView from "./PriceListView";

class PriceList extends Component {
  state = {
    services: null,
    isFetchingServices: false,
    clientPrices: null,
    isFetchingClientPrices: false,
  };

  setServices = (response, err) => {
    if (err) {
      return;
    }

    this.setState(() => ({ services: response.list }));
  };

  setFetchingServices = isFetchingServices => {
    this.setState(() => ({ isFetchingServices }));
  };

  setClientPrices = (response, err) => {
    if (err) {
      return;
    }

    this.setState(() => ({ clientPrices: response }));
  };

  setFetchingClientPrices = isFetchingClientPrices => {
    this.setState(() => ({ isFetchingClientPrices }));
  };

  setRefreshClientPrices = refreshClientPrices => {
    this.refreshClientPrices = refreshClientPrices;
  };

  servicesPayload = memoize(() => ({}));

  clientPricesPayload = memoize(clientId => ({ clientId }));

  render() {
    const { services, isFetchingServices, clientPrices, isFetchingClientPrices } = this.state;

    const servicesPayload = this.servicesPayload();
    const clientPricesPayload = this.clientPricesPayload(this.props.client.id);

    return (
      <Fragment>
        <DataProvider
          endpoint={getServices}
          data={servicesPayload}
          onDataChanged={this.setServices}
          onDataFetching={this.setFetchingServices}
        />

        <DataProvider
          endpoint={getClientPrices}
          data={clientPricesPayload}
          onDataChanged={this.setClientPrices}
          onDataFetching={this.setFetchingClientPrices}
          fetchMethodSetter={this.setRefreshClientPrices}
        />

        <PriceListView
          client={this.props.client}
          services={services}
          clientPrices={clientPrices}
          isFetching={isFetchingServices || isFetchingClientPrices}
          refreshClientPrices={this.refreshClientPrices}
        />
      </Fragment>
    )
  }
}

export default PriceList;
