import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { ListGroup, ListGroupItem, Badge } from "reactstrap";
import classNames from "classnames";

import Device from "./Device";

import "./DevicesClient.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class DevicesClient extends PureComponent {
  render() {
    const { client, devices, style } = this.props;

    return (
      <div className="devices-client-group" style={style}>
        <ListGroup className="devices-client-sticky" flush>
          <ListGroupItem
            action
            id={`client#${client.id}`}
            tag="button"
            type="button"
            active={!!this.props.selectedGroups[client.id]}
            onClick={this.props.groupOnClick}>
            <div className="row device-client-container">
              <div className="col-12 d-flex align-items-center justify-content-between">
                <small className="device-client-details w-100 text-center">
                  <strong className={classNames("align-text-top", { "mr-2": !!client.name })}>{client.name}</strong>

                  <small title="Liczba aktywnych pojazdów">{client.activeDevicesCount}</small>

                  {client.deleted && (
                    <small className="ml-1">
                      <Badge color="danger" title="Usunięty" pill><FontAwesomeIcon icon="trash" size="xs" /></Badge>
                    </small>
                  )}
                </small>
              </div>
            </div>
          </ListGroupItem>
        </ListGroup>

        <ListGroup flush>
          {devices.map(device => (
            <Device {...device} key={device.id} onClick={this.props.deviceOnClick} selected={!!this.props.selectedDevices[device.id]} />
          ))}
        </ListGroup>
      </div>
    );
  }
}

DevicesClient.propTypes = {
  deviceOnClick: PropTypes.func.isRequired,
  client: PropTypes.object,
  devices: PropTypes.arrayOf(PropTypes.object),
  refresh: PropTypes.func,
};

export default DevicesClient;
