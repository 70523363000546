import React, { PureComponent } from "react";

import { getStatuses } from "../../api";
import { DataProvider } from "../../components";

class StatusesUpdater extends PureComponent {
  updateStatuses = (statuses, error) => {
    const { terminals } = this.props;
    
    if (error) {
      terminals.forEach(terminal => terminal.lastHeartbeat = null);
    }
    else {
      terminals.forEach(terminal => {
        const status = statuses.list.find(x => x.id === terminal.id);
        terminal.lastHeartbeat = status ? status.lastHeartbeat : null;
      });
    }

    this.props.onUpdate();
  };

  render() {
    return (
      <DataProvider 
        endpoint={getStatuses}
        interval={60 * 1000}
        timeout={60 * 1000}
        onDataChanged={this.updateStatuses} />
    );
  }
}

export default StatusesUpdater;
