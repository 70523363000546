import React, { Component, PureComponent } from "react";
import { Form, FormGroup, Label } from "reactstrap";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./HistoryFilter.css";

class Input extends PureComponent {
  render() {
    return (
      <input
        type="text"
        style={{ padding: "6px 8px", height: "auto", display: "inline-block" }}
        {...this.props}
      />
    );
  }
}

const Date = ({ placeholder, selected, onChange, startDate, endDate, selectsStart = false, selectsEnd = false }) => (
  <DatePicker
    wrapperClassName="history-date-wrapper"
    className="form-control"
    customInput={<Input />}
    locale="pl"
    dateFormat="yyyy-MM-dd"
    selected={selected}
    onChange={onChange}
    selectsStart={selectsStart}
    selectsEnd={selectsEnd}
    placeholderText={placeholder}
    popperPlacement="left"
    popperModifiers={{
      offset: {
        enabled: true,
        offset: "130px, 10px",
      },
      preventOverflow: {
        enabled: true,
        escapeWithReference: false,
        boundariesElement: "scrollParent",
      },
    }}
  />
);

class HistoryFilter extends Component {
  filterOptionChanged = e => {
    const { value } = e.target;

    this.props.setOption(value);
  };

  changeStartDate = date => {
    this.props.setDates(date, this.props.dateEnd);
  };

  changeEndDate = date => {
    this.props.setDates(this.props.dateStart, date);
  };

  render() {
    const { option, dateStart, dateEnd } = this.props;

    return (
      <Form>
        <FormGroup check inline>
          <div className="custom-radio custom-control">
            <input type="radio" id="last30Days" name="filterOption" value="1" checked={option === "1"} onChange={this.filterOptionChanged} />{" "}
            <Label for="last30Days">ostatnie 30 dni</Label>
          </div>
        </FormGroup>

        <FormGroup check inline>
          <div className="custom-radio custom-control">
            <input type="radio" id="last100Entries" name="filterOption" value="2" checked={option === "2"} onChange={this.filterOptionChanged} />{" "}
            <Label for="last100Entries">ostatnie 100 wpisów</Label>
          </div>
        </FormGroup>

        <FormGroup check inline>
          <div className="custom-radio custom-control">
            <input type="radio" id="datePeriod" name="filterOption" value="3" checked={option === "3"} onChange={this.filterOptionChanged} />{" "}
            <Label for="datePeriod">
              <span>okres od</span>

              <Date
                placeholder="data od"
                selected={dateStart}
                startDate={dateStart}
                endDate={dateEnd}
                onChange={this.changeStartDate}
                selectsStart
              />

              <span>do</span>

              <Date
                placeholder="data do"
                selected={dateEnd}
                startDate={dateStart}
                endDate={dateEnd}
                onChange={this.changeEndDate}
                selectsEnd
              />
            </Label>
          </div>
        </FormGroup>
      </Form>
    );
  }
}

export default HistoryFilter;
