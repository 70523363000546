import React from "react";
import PropTypes from "prop-types";

const SaveCancelBar = ({ isDirty, save, cancel, children }) => {
  return (
    <div className="d-flex justify-content-end p-3 save-cancel-bar">
      {children}

      <div>
        <button type="button" className="btn btn-sm btn-secondary mr-1" disabled={!isDirty} onClick={cancel}>Anuluj</button>
        <button type="button" className="btn btn-sm btn-primary" disabled={!isDirty} onClick={save}>Zapisz</button>
      </div>
    </div>
  );
};

SaveCancelBar.propTypes = {
  isDirty: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default SaveCancelBar;
