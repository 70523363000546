import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Filter } from "../../components";

const tooltip = (
  <div className="text-left">
    <span>Filtrowanie po:</span>
    <ul className="mb-0 pl-4">
      <li>nazwie wyświetlanej użytkownika</li>
    </ul>
  </div>
);

class OperatorsFilter extends PureComponent {
  render() {
    const { options, formatOptionLabel } = this.props;

    return (
      <Filter
        refresh={() => this.props.refresh()}
        onChange={this.props.onChange}
        options={options}
        formatOptionLabel={formatOptionLabel}
        tooltip={tooltip} />
    );
  }
}

OperatorsFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default OperatorsFilter;
