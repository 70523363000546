import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  FormText,
  CustomInput,
  FormFeedback
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { addUser } from "../../api";
import { withAlert } from "../../components/Alert";
import { generateRandomPassword, isValidPassword } from "../../utils";
import isEmail from 'validator/es/lib/isEmail';

class AddUser extends PureComponent {
  state = {
    modalIsOpen: false,
    login: null,
    password: null,
    firstName: null,
    surname: null,
    sendEmail: true,
    email: null,
  };

  showModal = e => {
    e.stopPropagation();
    e.preventDefault();

    this.toggleModal();
  };

  toggleModal = callback => {
    const cb = callback && callback.apply ? callback : undefined;

    this.setState(prevState => ({
      modalIsOpen: !prevState.modalIsOpen,
      login: null,
      password: !prevState.modalIsOpen ? generateRandomPassword() : null,
      firstName: null,
      surname: null,
      sendEmail: true,
      email: null
    }), cb);
  };

  addUser = () => {
    const { clientId } = this.props;
    const { login, password, firstName, surname, sendEmail, email } = this.state;

    addUser({
      clientId,
      login: login?.trim(),
      password: password?.trim(),
      firstName: firstName?.trim(),
      surname: surname?.trim(),
      sendEmail,
      email: email?.trim()
    })
      .then(r => {
        if (r.result === 0) {
          this.props.showAlert("Dodano użytkownika", "success");
          this.toggleModal(() => this.props.onSuccess(r.id));
        }
        else {
          this.props.showAlert("Wystąpił błąd", "danger");
        }
      })
      .catch(() => {
        this.props.showAlert("Wystąpił błąd", "danger");
      });
  };

  onInput = e => {
    const { name, value } = e.target;
    this.setState(() => ({ [name]: value }));
  };

  onChange = e => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      this.setState(() => ({ [name]: checked }));
    }
    else {
      this.setState(() => ({ [name]: value }));
    }
  };

  isValidEmail = email => {
    return isEmail(email || "");
  };

  render() {
    const { modalIsOpen, login, password, sendEmail, email } = this.state;

    const loginFieldEmpty = !login || !login.trim().length;
    const passwordMatch = isValidPassword(password);
    const emailValidationRequired = sendEmail || !!email;
    const emailValidationFailed = emailValidationRequired && !this.isValidEmail(email);
    const submitDisabled = loginFieldEmpty || !passwordMatch || emailValidationFailed;

    return (
      <Fragment>
        <Button color="secondary" size="xs" tag="a" title="Dodaj użytkownika" onClick={this.showModal}>
          <FontAwesomeIcon icon="user-plus" color="white" />
        </Button>

        <Modal isOpen={modalIsOpen} toggle={this.toggleModal} returnFocusAfterClose={false} autoFocus={false}
          backdrop="static" size="xl" onClick={e => e.stopPropagation()}>
          <ModalHeader toggle={this.toggleModal}>Dodawanie użytkownika</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Label for="login" sm={2}>Użytkownik</Label>
                <Col sm={10}>
                  <Input type="text" name="login" id="login" onInput={this.onInput} autoFocus invalid={loginFieldEmpty} />
                  <FormFeedback>Pole jest puste</FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label sm={2}>Hasło</Label>
                <Col sm={10}>
                  <Input type="text" name="password" id="password" onChange={this.onInput} value={password || ""} invalid={!passwordMatch} />
                  <FormText color="muted">Hasło powinno mieć długość przynajmniej 6 znaków i zawierać co najmniej jedną cyfrę.</FormText>
                  <FormFeedback>Hasło nie spełnia minimalnych wymagań</FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="email" sm={2}>E-mail</Label>
                <Col sm={10}>
                  <Input type="text" name="email" id="email" onInput={this.onInput} invalid={emailValidationFailed} />
                  <FormFeedback>Nieprawidłowy adres e-mail</FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col sm={2} />
                <Col sm={10}>
                  <CustomInput type="checkbox" name="sendEmail" id="sendEmail" onChange={this.onChange} checked={sendEmail}
                    label="Wyślij potwierdzenie rejestracji na podany adres e-mail" />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label sm={2}>Imię</Label>
                <Col sm={10}>
                  <Input type="text" name="firstName" id="firstName" onInput={this.onInput} />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label sm={2}>Nazwisko</Label>
                <Col sm={10}>
                  <Input type="text" name="surname" id="surname" onInput={this.onInput} />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" disabled={submitDisabled} title={submitDisabled ? "Popraw błędy w formularzu" : ""} onClick={this.addUser}>Dodaj</Button>
            <Button color="secondary" onClick={this.toggleModal}>Anuluj</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

AddUser.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default withAlert(AddUser);
