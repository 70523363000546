import React, { useReducer, useEffect, useCallback } from "react";

import DetailsView from "./DetailsView";
import { getTerminalDetails } from "../../../../api";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_INIT":
      return { ...state, isFetching: true, terminalId: action.payload };
    case "FETCH_SUCCESS":
      return { ...state, isFetching: false, terminal: action.payload };
    case "FETCH_ERROR":
      return { ...state, isFetching: false };
    default:
      throw new Error();
  }
};

const Details = props => {
  const [state, dispatch] = useReducer(reducer, {
    isFetching: false,
    terminal: null,
    terminalId: null
  });

  const fetchDetails = useCallback(terminalId => {
    dispatch({ type: "FETCH_INIT", payload: terminalId });

    getTerminalDetails({ terminalId })
      .then(data => {
        if (!data || data.result !== 0) {
          dispatch({ type: "FETCH_ERROR" });
          return;
        }

        const { result, ...terminal } = data;

        dispatch({ type: "FETCH_SUCCESS", payload: terminal });
      })
      .catch(() => dispatch({ type: "FETCH_ERROR" }));
  }, []);

  useEffect(() => {
    if (props.terminal.id === state.terminalId) {
      return;
    }

    fetchDetails(props.terminal.id);
  }, [props.terminal.id, state.terminalId, fetchDetails]);

  return (
    <DetailsView isFetching={state.isFetching} terminal={state.terminal} refreshTerminalsList={props.refreshTerminalsList} refreshDetails={fetchDetails} />
  );
};

export default Details;
