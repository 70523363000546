import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Button,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Col
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { addClient } from "../../api";
import { withAlert } from "../../components/Alert";
import isVAT from "../../isVAT";

class AddClient extends Component {
  state = {
    modalIsOpen: false,
    name: null,
    vatid: "",
    prefix: null,
  };

  toggleModal = callback => {
    const cb = callback && callback.apply ? callback : undefined;

    this.setState(prevState => ({ modalIsOpen: !prevState.modalIsOpen, name: null, prefix: null }), cb);
  };

  addClient = () => {
    const { name, vatid, prefix } = this.state;

    addClient({ name, vatid, prefix })
      .then(r => {
        if (r.result === 0) {
          this.props.showAlert("Dodano klienta", "success");
          this.toggleModal(this.props.onSuccess);
        }
        else {
          this.props.showAlert("Wystąpił błąd. Sprawdź, czy numer NIP nie został już przypisany do innego klienta.", "danger");
        }
      })
      .catch(() => {
        this.props.showAlert("Wystąpił błąd", "danger");
      });
  };

  onInput = e => {
    const { name, value } = e.target;
    this.setState(() => ({ [name]: value }));
  };

  isVAT = vatid => {
    try {
      return isVAT(vatid, null, true);
    }
    catch {
      return null;
    }
  };

  render() {
    const { modalIsOpen, name, vatid } = this.state;

    const vatidInvalid = !this.isVAT(vatid);
    const submitDisabled = !name || vatidInvalid;

    return (
      <Fragment>
        <InputGroupAddon addonType="append">
          <Button title="Dodaj klienta" onClick={this.toggleModal}>
            <FontAwesomeIcon icon="plus" />
          </Button>
        </InputGroupAddon>

        <Modal isOpen={modalIsOpen} toggle={this.toggleModal} backdrop="static" autoFocus={false}>
          <ModalHeader toggle={this.toggleModal}>Dodawanie klienta</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Label for="name" sm={2}>Nazwa</Label>
                <Col sm={10}>
                  <Input type="text" name="name" id="name" onInput={this.onInput} invalid={!name} autoFocus />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="vatid" sm={2}>NIP</Label>
                <Col sm={10}>
                  <Input type="text" name="vatid" id="vatid" onInput={this.onInput} invalid={vatidInvalid} />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="name" sm={2}>Prefix</Label>
                <Col sm={10}>
                  <Input type="text" name="prefix" id="prefix" onInput={this.onInput} />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.addClient} disabled={submitDisabled}>Dodaj</Button>
            <Button color="secondary" onClick={this.toggleModal}>Anuluj</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

AddClient.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default withAlert(AddClient);
