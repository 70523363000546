import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";

const loadingMessage = () => "Ładowanie";
const noOptionsMessage = () => "Brak pasujących opcji";
const formatGroupLabel = group => <span>{`Wyświetlanie pierwszych ${group.options ? group.options.length : 0} pasujących wyników`}</span>;

class DictionarySelect extends PureComponent {
  getOptionLabel = option => {
    if (!this.props.labelField) return undefined;

    return this.props.labelField && this.props.labelField.substr
      ? option[this.props.labelField]
      : this.props.labelField(option);
  }

  getOptionValue = option => {
    return this.props.valueField && this.props.valueField.substr
      ? option[this.props.valueField]
      : this.props.valueField(option);
  };

  render() {
    const {
      endpoint,
      isMulti,
      value,
      onChange,
      placeholder,
      cache,
      styles,
      formatOptionLabel,
      autoFocus,
      onKeyDown,
    } = this.props;

    return (
      <AsyncSelect
        cacheOptions={cache}
        defaultOptions
        loadOptions={endpoint}
        formatOptionLabel={formatOptionLabel}
        getOptionLabel={this.getOptionLabel}
        getOptionValue={this.getOptionValue}
        isMulti={isMulti}
        value={value || null}
        onChange={onChange}
        loadingMessage={loadingMessage}
        noOptionsMessage={noOptionsMessage}
        placeholder={placeholder}
        styles={styles}
        formatGroupLabel={formatGroupLabel}
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
        isClearable
      />
    );
  }
}

DictionarySelect.propTypes = {
  endpoint: PropTypes.func.isRequired,
  valueField: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    .isRequired,
  labelField: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  placeholder: PropTypes.string,
  cache: PropTypes.bool,
  styles: PropTypes.object,
  formatOptionLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  autoFocus: PropTypes.bool,
  onKeyDown: PropTypes.func,
};

DictionarySelect.defaultProps = {
  labelField: "name",
  valueField: "id",
  placeholder: "Wybierz...",
};

export default DictionarySelect;
