import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { ListGroup } from "reactstrap";

import { withLoading } from "../../components";
import Operator from "./Operator";
import { formatUser } from "../../utils";

class OperatorsList extends PureComponent {
  operatorOnClick = e => {
    e.preventDefault();
    const id = parseInt(e.currentTarget.id.replace("operator#", ""), 10); // id of selected terminal

    this.props.selectOperator(id, true); // single selection
  };

  render() {
    const { selectedOperators, filteredOperators } = this.props;

    return (
      <ListGroup flush>
        {filteredOperators.map(operator => (
          <Operator {...operator} displayName={formatUser(operator)} key={operator.id} onClick={this.operatorOnClick} selected={!!selectedOperators[operator.id]} />
        ))}
      </ListGroup>
    );
  }
}

OperatorsList.propTypes = {
  selectOperator: PropTypes.func.isRequired,
  filteredOperators: PropTypes.array,
  selectedOperators: PropTypes.object,
};

export default withLoading(OperatorsList);
