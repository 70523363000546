import React, { Component } from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";

import PermissionsView from "./PermissionsView";
import { roleAvailablePermissions } from "../../../../../../../api";
import { FilteredList } from "../../../../../../../components";

const groupPermissions = data => {
  if (!data) {
    return [];
  }

  const permissionsGroups = data.reduce((a, x) => {
    let groupId;
    if (!x.group) {
      groupId = 0;
    }
    else {
      groupId = x.group.id;
    }

    if (a[groupId]) {
      a[groupId].push(x);
    }
    else {
      a[groupId] = [x];
    }

    return a;
  }, {});

  return Object.keys(permissionsGroups)
    .map(x => {
      return {
        group: x !== "0" ? permissionsGroups[x][0].group : { id: 0, name: "Brak grupy" },
        permissions: permissionsGroups[x],
      };
    })
    .sort((a, b) => {
      if (!a.group.id) {
        return 1;
      }

      if (!b.group.id) {
        return -1;
      }

      return a.group.id - b.group.id;
    });
};

class Permissions extends Component {
  onListChangedCallback = (filteredList, init) => {
    if (init) {
      const selectedElements = filteredList.reduce((a, x) => {
        if (x.selected) {
          a.push(x.id);
        }

        return a;
      }, []);
  
      if (selectedElements.length) {
        this.selectElements(selectedElements, true);
      }
    }
  };

  memoizeGroupedPermissions = memoize(permissions => {
    return groupPermissions(permissions);
  });

  render() {
    const { role } = this.props;
    const roleId = role.id;
    const _this = this;

    return (
      <FilteredList multiselect dependencyId={roleId} endpoint={roleAvailablePermissions} data={{ roleId }} keyField="id" listChangedCallback={this.onListChangedCallback}>
        {({ fullList, filteredList, selectedElements, isFetching }, { refresh, refreshComponents, onFilterChanged, selectElement, selectAll, deselectAll, areAllSelected, selectedCount, selectElements }) => {
          _this.selectElements = selectElements;
          const permissionsGroups = this.memoizeGroupedPermissions(filteredList);

          return (<PermissionsView isFetching={isFetching} role={role} permissions={fullList} permissionsGroups={permissionsGroups} refreshPermissionsList={refresh} selectedPermissions={selectedElements} selectElement={selectElement} selectElements={selectElements} />);
        }}
      </FilteredList>
    );
  }
}

Permissions.propTypes = {
  role: PropTypes.object.isRequired,
};

export default Permissions;
