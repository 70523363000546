import React, { Fragment, PureComponent } from "react";

import { deleteSimCard, editSimCardNotes, editSimCardPin, editSimCardPrivateAPN, editSimCardRoamingEnabled } from "../../../../api";
import Auth from "../../../../Auth";
import { BlobImage, ButtonLink, ButtonWithConfirmationDialog, LabelValue, LabelValueCheckbox, withLoading } from "../../../../components";
import { withAlert } from "../../../../components/Alert";
import { formatDate } from "../../../../dateUtils";

const DeviceDetails = ({ device, hasAccessToDevices }) => {
  if (!device) {
    return null;
  }

  return (
    <Fragment>
      <h3>
        <span className="mr-3">Pojazd</span>
        {hasAccessToDevices && (
          <span>
            <ButtonLink to={`/devices/services/${device.id}`} />
          </span>
        )}
      </h3>

      <dl className="row">
        <LabelValue label="Klient" value={`${device.clientName} (NIP: ${device.clientVatid || "brak"})`} />
        <LabelValue label="Pojazd usunięty" value={device.deleted ? "Tak" : "Nie"} />
        <LabelValue label="IMEI" value={device.imei} />
        <LabelValue label="Nazwa systemowa" value={device.description} />
        <LabelValue label="Nr rejestracyjny" value={device.vrn} />
        <LabelValue label="Nr seryjny" value={device.serialNumber} />
        <LabelValue label="Nr biznesowy" value={device.etollNumber} />
      </dl>
    </Fragment>
  );
};

const TerminalDetails = ({ terminal, hasAccessToTerminals }) => {
  if (!terminal) {
    return null;
  }

  return (
    <Fragment>
      <h3>
        <span className="mr-3">Terminal</span>
        {hasAccessToTerminals && (
          <span>
            <ButtonLink to={`/terminals/details/${terminal.id}`} />
          </span>
        )}
      </h3>

      <dl className="row">
        <LabelValue label="Klient" value={`${terminal.clientName} (NIP: ${terminal.clientVatid || "brak"})`} />
        <LabelValue label="IMEI" value={terminal.imei} />
        <LabelValue label="Nr seryjny" value={terminal.sn} />
      </dl>
    </Fragment>
  );
};

class DetailsView extends PureComponent {
  componentDidUpdate() {
    if (this.props.simCard && this.props.simCard.device && this.props.simCard.terminal) {
      this.props.showAlert("Wybrana karta jest przypisana jednocześnie do urządzenia i terminala!", "danger", undefined, 5000);
    }
  }

  deleteSimCard = () => {
    deleteSimCard({ simCardId: this.props.simCard.id })
      .then(r => {
        if (r.result === 0) {
          this.props.showAlert("Karta SIM została usunięta.", "success", this.refreshSimCardsList);
        }
        else {
          this.showError();
        }
      })
      .catch(this.showError);
  };
  
  savePin = pin => {
    editSimCardPin({
      simCardId: this.props.simCard.id,
      pin
    })
      .then(r => {
        if (r.result === 0) {
          this.props.showAlert("Zmiany zostały zapisane.", "success", this.refreshSimCardsList);
        }
        else {
          this.showError();
        }
      })
      .catch(this.showError);
  };

  saveNotes = notes => {
    editSimCardNotes({
      simCardId: this.props.simCard.id,
      notes
    })
      .then(r => {
        if (r.result === 0) {
          this.props.showAlert("Zmiany zostały zapisane.", "success", this.refreshSimCardsList);
        }
        else {
          this.showError();
        }
      })
      .catch(this.showError);
  };

  saveRoamingEnabled = roamingEnabled =>
    editSimCardRoamingEnabled({
      simCardId: this.props.simCard.id,
      roamingEnabled
    });

  savePrivateAPN = privateAPN =>
    editSimCardPrivateAPN({
      simCardId: this.props.simCard.id,
      privateAPN
    });

  refreshSimCardsList = () => {
    this.props.refreshSimCardsList(this.props.refreshDetails);
  };

  render() {
    const { className, simCard, fileURL } = this.props;

    if (!simCard) {
      return <div className={className} />;
    }

    return (
      <div className="col-12" key={simCard.id}>
        <h3>
          <span className="mr-3">Karta SIM</span>

          <span className="mr-2">
            <ButtonWithConfirmationDialog
              dialogMessage="Czy na pewno chcesz usunąć tę kartę SIM?"
              color="danger"
              size="sm"
              style={{ verticalAlign: "bottom" }}
              onClick={this.deleteSimCard}
              outline>
              Usuń
            </ButtonWithConfirmationDialog>
          </span>
        </h3>

        <dl className="row">
          <LabelValue label="Konto" value={simCard.simAccount ? simCard.simAccount.name : "-"} />
          <LabelValue label="Data dodania" value={formatDate(simCard.dateAdded, true, false)} />
          <LabelValue label="CCID" value={simCard.ccid} />
          <LabelValue label="Nr telefonu" value={simCard.phone} />
          <LabelValue label="PIN" value={simCard.pin} editable onEdited={this.savePin} />
          <LabelValue label="PUK" value={simCard.puk}/>
          <LabelValue label="Roaming" value={<LabelValueCheckbox
            currentValue={simCard.roamingEnabled}
            save={this.saveRoamingEnabled}
            id="roaming"
            editable
            onChange={this.refreshSimCardsList}
          />} customValueField />
          <LabelValue label="Prywatny APN" value={<LabelValueCheckbox
            currentValue={simCard.privateAPN}
            save={this.savePrivateAPN}
            id="privateAPN"
            editable
            onChange={this.refreshSimCardsList}
          />} customValueField />
          <LabelValue label="Notatki" value={simCard.notes} editable onEdited={this.saveNotes} maxLength={64} />
        </dl>

        {!!fileURL && (
          <div className="mb-3">
            <h3>Zdjęcie</h3>

            <a href={fileURL} target="_blank" rel="noopener noreferrer">
              <BlobImage alt="Zdjęcie karty SIM" src={fileURL} style={{ height: "320px" }} />
            </a>
          </div>
        )}

        <Auth.Consumer>
          {({ hasPermission }) => {
            const hasAccessToDevices = hasPermission("managingDevicesServices");
            const hasAccessToTerminals = hasPermission("managingTerminals");

            return (
              <>
                <DeviceDetails device={simCard.device} hasAccessToDevices={hasAccessToDevices} />

                <TerminalDetails terminal={simCard.terminal} hasAccessToTerminals={hasAccessToTerminals} />
              </>
            )
          }}
        </Auth.Consumer>
      </div>
    );
  }
}

export default withAlert(withLoading(DetailsView));
