import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { NavLink, Switch, Route, Redirect } from "react-router-dom";
import { Nav as NavMenu, NavItem, Card, CardHeader, CardBody } from "reactstrap";

import Permissions from "./permissions";
import Users from "./users";

class Pages extends PureComponent {
  render() {
    const { selectedRoles, refreshRolesList } = this.props;

    return (
      <Card className="h-100">
        <CardHeader>
          <NavMenu card tabs className="mt-2 mt-md-0">
            <Fragment>
              <NavItem><NavLink to="/clients/roles/permissions" exact className="nav-link">Uprawnienia</NavLink></NavItem>
              <NavItem><NavLink to="/clients/roles/users" exact className="nav-link">Użytkownicy</NavLink></NavItem>
            </Fragment>
          </NavMenu>
        </CardHeader>

        <CardBody style={{ overflowY: "auto", display: "grid" }}>
          <Switch>
            <Fragment>
              <Route path="/clients/roles/permissions" render={props => <Permissions {...props} role={Object.values(selectedRoles)[0]} refreshRolesList={refreshRolesList} />} />
              <Route path="/clients/roles/users" render={props => <Users {...props} role={Object.values(selectedRoles)[0]} refreshRolesList={refreshRolesList} />} />
              <Redirect to="/clients/roles/permissions" />
            </Fragment>
          </Switch>
        </CardBody>
      </Card>
    );
  }
}

Pages.propTypes = {
  selectedRoles: PropTypes.object.isRequired,
  refreshRolesList: PropTypes.func.isRequired,
};

export default Pages;
