import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { NavLink, Switch, Route, Redirect } from "react-router-dom";
import { Nav as NavMenu, NavItem, Card, CardHeader, CardBody } from "reactstrap";

import Auth from "../../../Auth";
import Details from "./details";
import Update from "./update";

const TerminalsDetails = ({ oneSelected, selectedTerminals, refreshTerminalsList }) => (
  oneSelected ?
    (
      <Fragment>
        <Route path="/terminals/details/:terminalId?" render={props => <Details {...props} terminal={Object.values(selectedTerminals)[0]} refreshTerminalsList={refreshTerminalsList} />} />
        <Redirect to="/terminals/details" />
      </Fragment>
    )
    : null
);

class Pages extends PureComponent {
  render() {
    const { selectedTerminals, selectedCount, refreshTerminalsList } = this.props;

    const oneSelected = selectedCount === 1;

    return (
      <Auth.Consumer>
        {({ hasPermission }) => (
          <Card className="h-100">
            <CardHeader>
              <NavMenu card tabs className="mt-2 mt-md-0">
                {oneSelected && <NavItem><NavLink to="/terminals/details" className="nav-link">Szczegóły</NavLink></NavItem>}
                {hasPermission("updatingTerminals") && (
                  <NavItem>
                    <NavLink to="/terminals/update" className="nav-link">Aktualizacja</NavLink>
                  </NavItem>
                )}
              </NavMenu>
            </CardHeader>

            <CardBody style={{ overflowY: "auto" }}>
              <Switch>
                <Route path="/terminals/update" render={props => <Update {...props} selectedTerminals={selectedTerminals} />} />
                <TerminalsDetails oneSelected={oneSelected} selectedTerminals={selectedTerminals} refreshTerminalsList={refreshTerminalsList} />
                <Redirect to="/terminals/update" />
              </Switch>
            </CardBody>
          </Card>
        )}
      </Auth.Consumer>
    );
  }
}

Pages.propTypes = {
  selectedTerminals: PropTypes.object.isRequired,
  selectedCount: PropTypes.number.isRequired,
  refreshTerminalsList: PropTypes.func.isRequired,
};

export default Pages;
