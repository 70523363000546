import React, { Component } from "react";
import { Button, InputGroup, InputGroupAddon } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import { getAvailableRoles, userReassignRoles } from "../../../../../api";
import { withAlert } from "../../../../../components/Alert";
import { DictionarySelect } from "../../../../../components";
import { debounce } from "../../../../../utils";

const styles = {
  container: styles => ({
    ...styles,
    flex: "auto",
  }),
};

class Roles extends Component {
  state = {
    isEditing: false,
    newValue: null,
    availableRoles: Promise.resolve([]),
  };

  componentDidMount() {
    this.fetchAvailableRoles();
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.user.id !== this.props.user.id) {
      this.fetchAvailableRoles();
    }
  }

  fetchAvailableRoles = () => {
    if (!this.props.editable) {
      return;
    }

    const availableRoles = getAvailableRoles({
      userId: this.props.user.id,
      onlyUsersClient: true,
    })
      .then(r => ([{ options: r.list }]))
      .catch(this.onError);

    this.setState(() => ({ availableRoles }));
  };

  optionsLoader = debounce((filter, callback) => {
    this.state.availableRoles
      .then(roles => {
        const phrase = filter.toLocaleLowerCase();
        const options = roles[0].options.filter(x => x.name.toLocaleLowerCase().includes(phrase));

        return [{ options }];
      })
      .then(callback);
  }, 500);

  setValue = newValue => {
    this.setState(() => ({ newValue }));
  };

  startEditing = () => {
    const newValue = this.props.user.roles;

    this.setState(() => ({ newValue, isEditing: true }));
  };
  
  save = async () => {
    const availableRoles = await this.state.availableRoles;
    const selectedRolesIds = (this.state.newValue || []).map(x => x.id);
    const { addIds, removeIds } = availableRoles[0].options.reduce((a, x) => {
      const isSelected = selectedRolesIds.includes(x.id);
      const wasSelected = this.props.user.roles.find(y => y.id === x.id);

      if (isSelected && !wasSelected) { // was not selected but it is now
        a.addIds.push(x.id);
      }
      else if (!isSelected && wasSelected) { // is not selected but it was before
        a.removeIds.push(x.id);
      }

      return a;
    }, { addIds: [], removeIds: [] });

    userReassignRoles({
      userId: this.props.user.id,
      addIds,
      removeIds, 
    })
      .then(this.onSuccess)
      .catch(this.onError);
  };

  onSuccess = r => {
    if (r.result === 0) {
      this.setState(() => ({ isEditing: false }), () => {
        this.props.showAlert("Zmiany zostały zapisane.", "success", this.props.onRolesReassigned);
      });
    }
    else {
      this.onError();
    }
  };

  onError = () => {
    this.props.showAlert("Wystąpił błąd.", "danger");
  };

  cancel = () => {
    this.setState(() => ({ isEditing: false }));
  };

  onKeyDown = e => {
    if (e.key === "Escape") {
      this.cancel();
    }
    else if (e.key === "Enter") {
      this.save();
    }
  };
  
  render() {
    const { user } = this.props;
    const { newValue, isEditing } = this.state;
    const label = user.roles.map(x => x.name).join(", ");

    return (
      <div>
        {!isEditing && (
          <div>
            <div className="d-inline-block" style={{ width: "calc(1.125em + 0.5rem)" }}>
              {this.props.editable && (
                <FontAwesomeIcon
                  title="Edytuj"
                  icon="edit"
                  className={classNames("clickable text-primary mr-2")}
                  onClick={this.startEditing} />
              )}
            </div>

            <span>{label || <>&nbsp;</>}</span>
          </div>
        )}

        {isEditing && (
          <form>
            <InputGroup size="sm">
              <DictionarySelect
                key={user.id}
                endpoint={this.optionsLoader}
                onChange={this.setValue}
                value={newValue}
                styles={styles}
                valueField="id"
                placeholder="Wybierz role"
                onKeyDown={this.onKeyDown}
                autoFocus
                isMulti
              />

              <InputGroupAddon addonType="append">
                <Button size="sm" title="Zapisz" onClick={this.save}><FontAwesomeIcon icon="check" /></Button>
                <Button size="sm" title="Anuluj" onClick={this.cancel}><FontAwesomeIcon icon="times" /></Button>
              </InputGroupAddon>
            </InputGroup>
          </form>
        )}
      </div>
    );
  }
}

export default withAlert(Roles);
