import React, { Component } from "react";
import { Form, FormGroup, Label } from "reactstrap";

class UserDevicesFilter extends Component {
  componentDidMount() {
    if (!this.props.option) {
      this.onChange(this.props.defaultValue);
    }
  }

  onRadioChange = e => {
    const { value } = e.target;

    this.onChange(value);
  };

  onChange = option => {
    this.props.onChange({ option });
  };

  render() {
    const { option, unassignedEntries, assignedEntries, allEntries, canAssignDevices } = this.props;

    if (!canAssignDevices) {
      return null;
    }

    return (
      <Form>
        {unassignedEntries && (
          <FormGroup check inline>
            <div className="custom-radio custom-control">
              <input type="radio" id="unassignedEntries" name="filterOption" value="1" checked={option === "1"} onChange={this.onRadioChange} />{" "}
              <Label for="unassignedEntries">nieprzypisane</Label>
            </div>
          </FormGroup>
        )}

        {assignedEntries && (
          <FormGroup check inline>
            <div className="custom-radio custom-control">
              <input type="radio" id="assignedEntries" name="filterOption" value="2" checked={option === "2"} onChange={this.onRadioChange} />{" "}
              <Label for="assignedEntries">przypisane</Label>
            </div>
          </FormGroup>
        )}

        {allEntries && (
          <FormGroup check inline>
            <div className="custom-radio custom-control">
              <input type="radio" id="allEntries" name="filterOption" value="3" checked={option === "3"} onChange={this.onRadioChange} />{" "}
              <Label for="allEntries">wszystkie</Label>
            </div>
          </FormGroup>
        )}
      </Form>
    )
  };
}

export default UserDevicesFilter;
