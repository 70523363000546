import React, { Component, Fragment } from "react";
import { Button } from "reactstrap";

import { DictionarySelect } from "../../../../components";
import { getFirmwareVersions, updateTerminals, restartTerminalConnections } from "../../../../api";
import { debounce } from "../../../../utils";
import { withAlert } from "../../../../components/Alert";

const getTerminalsIds = props => Object.keys(props.selectedTerminals).map(x => parseInt(x, 10));

class Update extends Component {
  state = {};

  optionsLoader = debounce((filter, callback) => {
    const terminalsIds = getTerminalsIds(this.props);

    getFirmwareVersions({
      terminalsIds,
      searchPhrase: filter
    })
      .then(r => r.list)
      .then(callback)
  }, 500);

  onSelectedVersionChanged = selectedVersion => {
    this.setState(() => ({ selectedVersion }));
  };

  alertError = () => {
    this.props.showAlert("Wystąpił błąd", "danger");
  };

  update = () => {
    updateTerminals({
      terminalsIds: getTerminalsIds(this.props),
      targetBuild: this.state.selectedVersion.build
    })
      .then(r => {
        if (r.result === 0) {
          this.setState({ selectedVersion: null }, this.props.showAlert("Zlecono aktualizację aplikacji", "success"));
        }
        else if (r.result === 1) {
          this.setState({ selectedVersion: null }, this.props.showAlert("Nie wszystkie wybrane terminale zostaną zaktualizowane", "warning"));
        }
        else {
          this.alertError();
        }
      })
      .catch(this.alertError)
  };

  restartConnections = () => {
    restartTerminalConnections({
      terminalsIds: getTerminalsIds(this.props)
    })
      .then(r => {
        if (r.result === 0) {
          this.props.showAlert("Zlecono restart połączenia", "success");
        }
        else {
          this.alertError();
        }
      })
      .catch(this.alertError);
  };

  formatVersionLabel = (option, { context }) => {
    const versionDetails = `${option.version || "-"} (build ${option.build})`;

    if (context === "menu") {
      return (
        <Fragment>
          <div>{versionDetails}</div>
          {option.notes && <div><small>{option.notes}</small></div>}
        </Fragment>
      );
    }

    return <span>{versionDetails}</span>;
  };

  render() {
    const { selectedVersion } = this.state;
    const terminalId = getTerminalsIds(this.props);

    return (
      <div className="col-12">
        <div className="mb-3">
          <h3>Aktualizacja</h3>
          <div className="form-row">
            <div className="col">
              <DictionarySelect
                key={terminalId}
                endpoint={this.optionsLoader}
                onChange={this.onSelectedVersionChanged}
                value={selectedVersion}
                valueField="build"
                placeholder="Wybierz wersję"
                cache={false}
                formatOptionLabel={this.formatVersionLabel}
              />
            </div>
            <div>
              <Button disabled={!selectedVersion} onClick={this.update}>Aktualizuj</Button>
            </div>
          </div>
        </div>

        {/* <div>
          <h3>Zarządzanie</h3>
          <Button onClick={this.restartConnections}>Zrestartuj połączenie</Button>
        </div> */}
      </div>
    );
  }
}

export default withAlert(Update);
