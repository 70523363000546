import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

import Nav from "./Nav";
import EtollRegistrationForm from "./EtollRegistrationForm";

class Account extends Component {
  render() {
    return (
      <div className="page">
        <Nav languageSwitcher />

        <main style={{ padding: "1.25rem", gridTemplateRows: "auto 1fr", rowGap: "1.25rem", /*width: "100%"*/ }}>
          <h3 className="text-center">
            <FormattedMessage
              id="etoll:welcomeMessage"
              defaultMessage="Dziękujemy za wybór naszego urządzenia e-TOLL!"
              description="Podziękowanie na górze strony" />
          </h3>

          <div className="col-xl-7" style={{ overflowX: "hidden", overflowY: "auto", padding: "0.5rem", justifySelf: "center" }}>
            <EtollRegistrationForm />
          </div>

          <footer className="text-center">
            <span className="font-weight-light"><FormattedMessage id="etoll:paymentProcessor" defaultMessage="Płatności obsługuje" /></span>
            <span className="ml-2 align-text-bottom"><img src="/img/Przelewy24_logo.svg" alt="Logo Przelewy24" height="20px" /></span>
          </footer>
        </main>
      </div>
    );
  }
}

export default Account;
