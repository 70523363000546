import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { Alert as BsAlert } from "reactstrap";

import RenderInBody from "./RenderInBody";

class Alert extends PureComponent {
  componentDidUpdate(prevProps) {
    const { isOpen, dismissTimeout } = this.props;

    if (!prevProps.isOpen && isOpen && dismissTimeout) {
      this.timeout = setTimeout(() => {
        this.dismiss();
      }, dismissTimeout);
    }
  }

  componentWillUnmount() {
    this.clearTimeout();
  }

  clearTimeout = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  };

  dismiss = () => {
    this.clearTimeout();
    this.props.onDismissed();
  };

  render() {
    const { isOpen, message, color } = this.props;

    return (
      <RenderInBody>
        <BsAlert
          className="toast-alert"
          color={color || "info"}
          isOpen={isOpen}
          toggle={this.dismiss}>
          {message}
        </BsAlert>
      </RenderInBody>
    );
  }
}

Alert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onDismissed: PropTypes.func.isRequired,
  color: PropTypes.string,
  dismissTimeout: PropTypes.number,
};

const getDisplayName = WrappedComponent =>
  WrappedComponent.displayName || WrappedComponent.name || "Component";

export function withAlert(WrappedComponent) {
  class WithAlert extends PureComponent {
    state = {
      showAlert: false,
      alertMessage: "",
      alertColor: undefined,
      callback: undefined,
      dismissTimeout: 2000,
    };

    showAlert = (alertMessage, alertColor, callback, dismissTimeout = 2000) => {
      this.setState(() => ({
        showAlert: true,
        alertMessage,
        alertColor,
        callback,
        dismissTimeout,
      }));
    };

    onAlertDismissed = () => {
      this.setState(() => ({ showAlert: false, dismissTimeout: 2000 }), this.state.callback);
    };

    render() {
      const { alertMessage, alertColor, showAlert, dismissTimeout } = this.state;

      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            showAlert={this.showAlert}
          />

          <Alert
            message={alertMessage}
            color={alertColor}
            isOpen={showAlert}
            dismissTimeout={dismissTimeout}
            onDismissed={this.onAlertDismissed}
          />
        </Fragment>
      );
    }
  }
  WithAlert.displayName = `WithAlert(${getDisplayName(WrappedComponent)})`;

  return WithAlert;
}

export default Alert;
