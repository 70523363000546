import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import { pl, enGB } from "date-fns/locale";
import * as serviceWorker from "./serviceWorker";

import "bootstrap/dist/css/bootstrap.css";
import "./setupIcons";
import "./index.css";
import { LocaleContextProvider } from "./components/LocaleContextProvider";

registerLocale("pl", pl);
registerLocale("en", enGB);

const BootstrapApp = () => (
  <LocaleContextProvider>
    <Router>
      <App />
    </Router>
  </LocaleContextProvider>
);

ReactDOM.render(
  <BootstrapApp />,
  document.getElementById("root")
);
  
serviceWorker.unregister();
