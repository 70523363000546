import React, { Component, Fragment } from "react";
import { Button } from "reactstrap";
import isEmail from 'validator/es/lib/isEmail';

import Auth from "../../../../../Auth";
import { ButtonWithConfirmationDialog, LabelValue, withLoading } from "../../../../../components";
import { withAlert } from "../../../../../components/Alert";
import { editRedirectURL, deleteUser, enableUser, disableUser, changePassword, editFirstNameAndSurname, changeLogin } from "../../../../../api";
import PaymentWarning from "./PaymentWarning";
import Roles from "./Roles";
import { generateRandomPassword, isBoolean, isValidPassword } from "../../../../../utils";
import { formatDate } from "../../../../../dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class UserDetailsView extends Component {
  deleteUser = () => {
    deleteUser({
      userId: this.props.user.id,
    })
      .then(this.onSuccess)
      .catch(this.onError);
  };

  saveRedirectURL = redirectURL => {
    editRedirectURL({
      userId: this.props.user.id,
      redirectURL,
    })
      .then(this.onSuccess)
      .catch(this.onError);
  };

  changeEnabledStatus = () => {
    const { user } = this.props;

    const endpoint = user.enabled ? disableUser : enableUser;

    endpoint({
      userId: user.id,
    })
      .then(this.onSuccess)
      .catch(this.onError);
  };

  saveName = name => {
    const { user } = this.props;
    const [ firstName, surname ] = name;

    editFirstNameAndSurname({
      userId: user.id,
      firstName,
      surname,
    })
      .then(this.onSuccess)
      .catch(this.onError);
  };

  generateNewPassword = setEditValue => () => {
    const newValue = generateRandomPassword(6);

    setEditValue(newValue);
  };

  changeLogin = login => {
    changeLogin({
      userId: this.props.user.id,
      login
    })
      .then(this.onSuccess)
      .catch(this.onError);
  };

  saveNewPassword = (password, sendEmail = false) => {
    if (!isBoolean(sendEmail)) {
      sendEmail = false;
    }

    if (!isValidPassword(password)) {
      this.props.showAlert("Hasło musi mieć przynajmniej 6 znaków, w tym jedną cyfrę.", "danger");
      return false;
    }

    changePassword({
      userId: this.props.user.id,
      password,
      sendEmail
    })
      .then(this.onSuccess)
      .catch(this.onError);
  };

  onSuccess = r => {
    if (r.result === 0) {
      this.props.showAlert("Zmiany zostały zapisane.", "success", this.refresh);
    }
    else {
      this.onError();
    }
  };

  onError = () => {
    this.props.showAlert("Wystąpił błąd.", "danger");
  };

  refresh = () => {
    this.props.refreshUsersList(this.props.refreshDetails);
  };

  setFirstName = (e, editValue, setEditValue) => {
    const { value } = e.target;

    setEditValue([value, editValue[1]]);
  };

  setSurname = (e, editValue, setEditValue) => {
    const { value } = e.target;

    setEditValue([editValue[0], value]);
  };

  render() {
    const { user } = this.props;

    if (!user) {
      return <div className={this.props.className}></div>;
    }

    const userName = user.firstName || user.surname ? [user.firstName, user.surname] : null;
    const isValidEmail = isEmail(user.email || "");

    return (
      <Auth.Consumer>
        {({ hasPermission, hasPermissions, userId: requestingUserId }) => {
          const canEditUsers = hasPermission("managingUsers");

          return (
            <div className="col-12">
              <h3>
                <span>Użytkownik</span>

                {hasPermission("managingUsers") && (
                  <span className="ml-3">
                    <ButtonWithConfirmationDialog
                      outline
                      color="warning"
                      size="sm"
                      style={{ verticalAlign: "bottom" }}
                      onClick={this.changeEnabledStatus}
                      dialogTitle="Potwierdzenie"
                      dialogMessage={`Czy na pewno chcesz ${user.enabled ? "dezaktywować" : "aktywować"} tego użytkownika?`}
                      dialogAcceptButtonCaption="Tak"
                      dialogRejectButtonCaption="Nie">
                      {user.enabled ? "Dezaktywuj" : "Aktywuj"}
                    </ButtonWithConfirmationDialog>
                  </span>
                )}

                {hasPermission("deletingUsers") && user.id !== requestingUserId && (
                  <span className="ml-3">
                    <ButtonWithConfirmationDialog
                      outline
                      color="danger"
                      size="sm"
                      style={{ verticalAlign: "bottom" }}
                      onClick={this.deleteUser}
                      dialogTitle="Potwierdzenie"
                      dialogMessage="Czy na pewno chcesz usunąć tego użytkownika?"
                      dialogAcceptButtonCaption="Tak"
                      dialogRejectButtonCaption="Nie">
                      Usuń
                    </ButtonWithConfirmationDialog>
                  </span>
                )}
              </h3>

              <dl className="row">
                <LabelValue label="Data utworzenia" value={formatDate(user.creationDate, false, false)} />

                <LabelValue label="Login" value={user.login} editable={canEditUsers} onEdited={this.changeLogin} />

                <LabelValue label="E-mail" value={user.email} />

                <LabelValue
                  label="Imię i nazwisko"
                  value={({ isEditing }) => isEditing ? [user.firstName, user.surname] : (userName ? user.firstName ? userName.join(" ") : user.surname : "")}
                  editable={canEditUsers}
                  onEdited={this.saveName}
                  defaultValue={["", ""]}
                  inputEdit={({ editValue, setEditValue, onKeyDown }) => (
                    <Fragment>
                      <input type="text" className="form-control" placeholder="Imię" value={editValue[0] || ""} onChange={e => this.setFirstName(e, editValue, setEditValue)} onKeyDown={onKeyDown} autoFocus />
                      <input type="text" className="form-control" placeholder="Nazwisko" value={editValue[1] || ""} onChange={e => this.setSurname(e, editValue, setEditValue)} onKeyDown={onKeyDown} />
                    </Fragment>
                  )} />

                <LabelValue label="Upomnienie" value={<PaymentWarning user={user} editable={canEditUsers} onPaymentWarningAssigned={this.refresh} />} customValueField />

                <LabelValue label="Przekierowanie" value={user.redirectURL} editable={canEditUsers} onEdited={this.saveRedirectURL} />

                <LabelValue label="Role" value={<Roles user={user} editable={hasPermission("managingRoles") && user.id !== requestingUserId} onRolesReassigned={this.refresh} />} customValueField />
              </dl>

              <h3>Zmiana hasła</h3>
              <dl className="row">
                  <LabelValue label="Nowe hasło" editable={canEditUsers} onEdited={this.saveNewPassword}
                    addon={({ isEditing }, { setEditValue, saveNewValue, editValue }) => (
                      isEditing && (
                        <Fragment>
                          <Button size="sm" title="Generuj losowe hasło" onClick={this.generateNewPassword(setEditValue)}>Generuj</Button>
                          <Button size="sm" title="Zapisz i wyślij e-mail potwierdzający" disabled={!isValidEmail || !isValidPassword(editValue)} onClick={() => saveNewValue(true)}>
                            <FontAwesomeIcon icon="at" size="sm" />
                          </Button>
                        </Fragment>
                      )
                    )}
                  />
              </dl>
            </div>
          );
        }}
      </Auth.Consumer>
    );
  }
}

export default withAlert(withLoading(UserDetailsView));
