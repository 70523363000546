import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Filter } from "../../components";

const tooltip = (
  <div className="text-left">
    <span>Filtrowanie po:</span>
    <ul className="mb-0 pl-4">
      <li>nazwie klienta</li>
      <li>nazwie, nr. rejestracyjnym, nr. IMEI i nr.  pojazdu</li>
    </ul>
  </div>
);

class DevicesFilter extends PureComponent {
  render() {
    return (
      <Filter
        tooltip={tooltip}
        refresh={this.props.refresh}
        onChange={this.props.onChange} />
    );
  }
}

DevicesFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default DevicesFilter;
