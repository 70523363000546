import React from "react";

const Auth = React.createContext({
  logout: () => {},
  user: undefined,
  onLoggedIn: () => {},
  permissionsObject: {},
  hasPermission: () => {},
  hasPermissions: () => {},
});

export default Auth;
