import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { NavLink, Switch, Route, Redirect } from "react-router-dom";
import { Nav as NavMenu, NavItem, Card, CardHeader, CardBody } from "reactstrap";

import Auth from "../../../Auth";
// import DeviceDetails from "./device/details";
import ClientDetails from "./client/details";
// import Calibration from "./device/calibration";
import DeviceServices from "./device/services";

class Pages extends Component {
  render() {
    const { selectedDevices, selectedGroups, refreshDevicesList } = this.props;
    
    const anyClientSelected = Object.keys(selectedGroups).length === 1;
    const client = anyClientSelected
      ? Object.values(selectedGroups)[0]
      : Object.values(selectedDevices)[0].client;

    return (
      <Auth.Consumer>
        {({ hasPermission }) => (
          <Card className="h-100">
            <CardHeader>
              <NavMenu card tabs className="mt-2 mt-md-0">
                {anyClientSelected && (
                  <Fragment>
                    <NavItem><NavLink to="/devices/client/details" exact className="nav-link">Szczegóły</NavLink></NavItem>
                  </Fragment>
                )}
                
                {!anyClientSelected && (
                  <Fragment>
                    <NavItem><NavLink to="/devices/services" className="nav-link">Pakiety</NavLink></NavItem>
                    {/* <NavItem><NavLink to="/devices/details" exact className="nav-link">Szczegóły</NavLink></NavItem> */}
                    {/* <NavItem><NavLink to="/devices/calibration" exact className="nav-link">Kalibracja</NavLink></NavItem> */}
                  </Fragment>
                )}
              </NavMenu>
            </CardHeader>

            <CardBody style={{ overflowY: "auto" }}>
              <Switch>
                {anyClientSelected && (
                  <Fragment>
                    <Route path="/devices/client/details" render={props => <ClientDetails {...props} client={client} refreshUsersList={refreshDevicesList} />} />
                    <Redirect to="/devices/client/details" />
                  </Fragment>
                )}
                {!anyClientSelected && (
                  <Fragment>
                    <Route path="/devices/services/:deviceId?" render={props => <DeviceServices {...props} device={Object.values(selectedDevices)[0]} refreshDevicesList={refreshDevicesList} />} />
                    {/* <Route path="/devices/details" render={props => <DeviceDetails {...props} device={Object.values(selectedDevices)[0]} refreshDevicesList={refreshDevicesList} />} /> */}
                    {/* <Route path="/devices/calibration" render={props => <Calibration {...props} device={Object.values(selectedDevices)[0]} refreshDevicesList={refreshDevicesList} />} /> */}
                    {/* <Redirect to="/devices/details" /> */}
                    <Redirect to="/devices/services" />
                  </Fragment>
                )}
              </Switch>
            </CardBody>
          </Card>
        )}
      </Auth.Consumer>
    );
  }
}

Pages.propTypes = {
  selectedDevices: PropTypes.object.isRequired,
  selectedGroups: PropTypes.object.isRequired,
  refreshDevicesList: PropTypes.func.isRequired,
};

export default Pages;
