import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Button,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Badge,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { addRole, getAvailableRoles } from "../../../../../api";
import { withAlert } from "../../../../../components/Alert";
import { DictionarySelect } from "../../../../../components";
import { debounce } from "../../../../../utils";

class AddRole extends Component {
  state = {
    modalIsOpen: false,
    name: null,
    role: null,
  };

  toggleModal = callback => {
    const cb = callback && callback.apply ? callback : undefined;

    this.setState(prevState => {
      const state = prevState.modalIsOpen ? { name: null, role: null } : {}; // clear fields when closing the modal

      return { ...state, modalIsOpen: !prevState.modalIsOpen };
    }, cb);
  };

  addRole = () => {
    const { clientId } = this.props;
    const { name, role } = this.state;

    addRole({
      clientId,
      name,
      copyRoleId: role?.id,
    })
      .then(r => {
        if (r.result === 0) {
          this.props.showAlert("Dodano rolę", "success");
          this.toggleModal(() => this.props.onSuccess(r.id));
        }
        else {
          this.onError();
        }
      })
      .catch(this.onError);
  };

  onError = () => {
    this.props.showAlert("Wystąpił błąd", "danger");
  };

  onInput = e => {
    const { name, value } = e.target;
    this.setState(() => ({ [name]: value }));
  };

  setRole = role => {
    this.setState(() => ({ role }));
  };

  optionsLoader = debounce((filter, callback) => {
    getAvailableRoles({
      userId: this.props.user.id,
      searchPhrase: filter,
      onlyUsersClient: false,
    })
      .then(r => ([{ options: r.list }]))
      .then(callback)
      .catch(this.onError);
  }, 500);
  
  formatRoleLabel = (option, { context }) => {
    return (
      <span>
        <Badge>{option.client.name}</Badge>
        <span className="ml-1">{option.name}</span>
      </span>
    );
  };

  render() {
    const { modalIsOpen, role } = this.state;

    return (
      <Fragment>
        <InputGroupAddon addonType="append">
          <Button title="Dodaj rolę" onClick={this.toggleModal}>
            <FontAwesomeIcon icon="plus" />
          </Button>
        </InputGroupAddon>

        <Modal isOpen={modalIsOpen} toggle={this.toggleModal} backdrop="static" size="xl" autoFocus={false}>
          <ModalHeader toggle={this.toggleModal}>Dodawanie roli</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Label for="name" sm={3}>Nazwa</Label>
                <Col sm={9}>
                  <Input type="text" name="name" id="name" onInput={this.onInput} autoFocus />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="name" sm={3}>Kopiowanie uprawnień</Label>
                <Col sm={9}>
                  <DictionarySelect
                    key={this.props.clientId}
                    endpoint={this.optionsLoader}
                    onChange={this.setRole}
                    value={role}
                    formatOptionLabel={this.formatRoleLabel}
                    valueField="id"
                    placeholder="Wybierz rolę, z której skopiowane zostaną uprawnienia"
                    cache
                  />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.addRole}>Dodaj</Button>
            <Button color="secondary" onClick={this.toggleModal}>Anuluj</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

AddRole.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default withAlert(AddRole);
