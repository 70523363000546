import { PureComponent } from "react";
import ReactDOM from "react-dom";

class RenderInBody extends PureComponent {
  el = document.createElement("div");

  componentDidMount() {
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
    this.el = null;
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export default RenderInBody;
