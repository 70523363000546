import React, { PureComponent, Fragment } from "react";
import memoize from "memoize-one";

import ClientDetailsView from "./ClientDetailsView";
import { getClientDetails } from "../../../../../api";
import { DataProvider } from "../../../../../components";

class ClientDetails extends PureComponent {
  state = {
    client: null,
    isFetching: false,
  };

  setClient = (client, error) => {
    if (error) {
      return;
    }

    this.setState(() => ({ client }));
  };

  setRefreshDetails = fetchClient => {
    this.refreshDetails = fetchClient;
  };

  setFetching = isFetching => {
    this.setState(() => ({ isFetching }));
  };

  payload = memoize(clientId => ({ clientId }));

  render() {
    const { client, isFetching } = this.state;
    const payload = this.payload(this.props.client.id);

    return (
      <Fragment>
        <DataProvider
          endpoint={getClientDetails}
          data={payload}
          onDataChanged={this.setClient}
          fetchMethodSetter={this.setRefreshDetails}
          onDataFetching={this.setFetching}
        />

        <ClientDetailsView isFetching={isFetching} clientDetails={client} client={this.props.client} refreshUsersList={this.props.refreshUsersList} refreshDetails={this.refreshDetails} />
      </Fragment>
    );
  }
}

export default ClientDetails;
