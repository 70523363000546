import React, { Component, createRef } from "react";

import Pages from "./pages";
import { FilteredList } from "../../components";
import { getDevicesAndClients } from "../../api";
import DevicesContainer from "./DevicesContainer";

const compareStrings = (field, phrase) => {
  return field && field.toLocaleLowerCase().includes(phrase);
};

const filterBasedOnKeywords = (client, keywords) => {
  for (const keyword of keywords) {
    const phrase = keyword.value.toLocaleLowerCase();

    if (compareStrings(client.name, phrase)) {
      return true;
    }

    for (let device of Object.values(client.devices)) {
      const result = compareStrings(device.vrn, phrase)
        || compareStrings(device.vehicleNumber, phrase)
        || compareStrings(device.description, phrase)
        || compareStrings(device.imei, phrase);

      if (result) {
        return true;
      }
    }
  }
    
  return false;
};

const filterDevices = (data, keywords) => {
  if (!keywords || !keywords.length) {
    return data;
  }

  return data.filter(x => filterBasedOnKeywords(x, keywords));
};

const getClientId = client => client.id;

const getDevice = (clients, key) => clients.find(client => client.devices[key])?.devices[key];

const getDevicesFromGroup = group => Object.values(group.devices);

class Devices extends Component {
  devicesListRef = createRef();

  getClientsAndDevices = (data, signal) => {
    return getDevicesAndClients(data, signal)
      .then(r => {
        if (r.result === 0) {
          const clients = r.list.reduce((c, client) => {
            const devices = client.devices.reduce((d, device) => {
              d[device.id] = device;
              device.client = { id: client.id };

              return d;
            }, {});

            c.push({
              ...client,
              devices,
            });

            return c;
          }, []);

          return Promise.resolve({ result: 0, list: clients });
        }

        return Promise.reject(r);
      });
  };

  renderPages = ({selectedElements, selectedGroups, selectedCount, refresh}) => {
    return selectedCount > 0 || Object.keys(selectedGroups).length
      ? <Pages selectedDevices={selectedElements} selectedGroups={selectedGroups} refreshDevicesList={refresh} />
      : <p className="p-3">Wybierz klienta lub pojazd</p>;
  };

  listChangedCallback = () => {
    this.devicesListRef.current?.refreshListCache();
  };

  render() {
    const {
      match: {
        params: { deviceId },
      },
    } = this.props;

    return (
      <FilteredList endpoint={this.getClientsAndDevices} listFilter={filterDevices} keyField="id" itemGetter={getDevice} groupGetter={getClientId} groupItems={getDevicesFromGroup} listChangedCallback={this.listChangedCallback} keepSelectionsAfterRefresh urlSelectKey={deviceId}>
        {({ fullList, filteredList, selectedElements, selectedGroups, isFetching }, { refresh, refreshComponents, onFilterChanged, selectElement, selectElements, selectGroup, selectedCount }) =>
          <div className="content">
            <DevicesContainer
              onFilterChanged={onFilterChanged}
              refresh={refresh}
              filteredList={filteredList}
              selectedElements={selectedElements}
              selectedGroups={selectedGroups}
              selectElement={selectElement}
              selectGroup={selectGroup}
              isFetching={isFetching}
              devicesListRef={this.devicesListRef}
            />

            <section>
              {this.renderPages({selectedElements, selectedGroups, selectedCount: selectedCount(), refresh})}
            </section>
          </div>
        }
      </FilteredList>
    );
  }
}

export default Devices;
