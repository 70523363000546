import React, { PureComponent, createRef } from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";
import { VariableSizeList as List } from 'react-window';

import DevicesClient from "./DevicesClient";
import { withLoading } from "../../components";
import Auth from "../../Auth";

const groupDevices = data => {
  if (!data) {
    return [];
  }

  return data
    .map(x => {
      const { devices, ...client } = x; 

      return {
        client,
        devices: Object.values(devices),
      };
    })
    .sort((a, b) => a.client.name.localeCompare(b.client.name));
};

class DevicesList extends PureComponent {
  listRef = createRef();

  deviceOnClick = e => {
    e.preventDefault();
    const id = parseInt(e.currentTarget.id.replace("device#", ""), 10); // id of selected device

    this.props.selectDevice(id, true); // single selection
  };

  groupOnClick = e => {
    e.preventDefault();
    const id = parseInt(e.currentTarget.id.replace("client#", ""), 10);

    this.props.selectGroup(id, true);
  };

  memoizeGroupedDevices = memoize(devices => {
    return groupDevices(devices);
  });

  refreshListCache = () => {
    this.listRef.current?.resetAfterIndex(0);
  };

  render() {
    const { selectedDevices, selectedGroups, filteredDevices, className, refresh, height, width } = this.props;
    const devicesClients = this.memoizeGroupedDevices(filteredDevices);
    
    return (
      <Auth.Consumer>
        {({ hasPermission }) => {
          const clientHeight = 44;
          const getItemSize = index => clientHeight + devicesClients[index].devices.length * 44;

          return (
            <List ref={this.listRef} className={className} itemCount={devicesClients.length} itemSize={getItemSize} height={height} width={width} style={{ overflowY: "auto" }}>
              {({ index, style }) => (
                <DevicesClient
                  key={devicesClients[index].client.id}
                  client={devicesClients[index].client}
                  devices={devicesClients[index].devices}
                  deviceOnClick={this.deviceOnClick}
                  groupOnClick={this.groupOnClick}
                  selectedGroups={selectedGroups}
                  selectedDevices={selectedDevices}
                  refresh={refresh}
                  style={style}
                  />
              )}
            </List>
          );
        }}
      </Auth.Consumer>
    );
  }
}

DevicesList.propTypes = {
  selectDevice: PropTypes.func.isRequired,
  filteredDevices: PropTypes.array,
  selectedDevices: PropTypes.object,
  isFetching: PropTypes.bool,
  refresh: PropTypes.func,
};

export default withLoading(DevicesList);
