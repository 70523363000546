import React from "react";
import { ListGroupItem } from "reactstrap";

const Operator = ({ filteredOperators, selected, onClick, id, displayName }) => {
  return (
    <ListGroupItem
      action
      id={`operator#${id}`}
      className="list-group-item list-group-item-action"
      tag="button"
      active={selected}
      onClick={onClick}>
      <div className="row no-gutters">
        <div className="col">
          <small>{displayName}</small>
        </div>
      </div>
    </ListGroupItem>
  );
}

export default Operator;