import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { NavLink, Switch, Route, Redirect } from "react-router-dom";
import { Nav as NavMenu, NavItem, Card, CardHeader, CardBody } from "reactstrap";

import Auth from "../../../Auth";

import ClientDetails from "./client/details";
import ClientRoles from "./client/roles";
import ClientDevices from "./client/devices";
import ClientPrices from "./client/priceList";
import ClientHistory from "./client/history";

import UserDetails from "./user/details";
import UserDevices from "./user/devices";
import UserHistory from "./user/history";

class Pages extends Component {
  render() {
    const { selectedUsers, selectedGroups, refreshUsersList } = this.props;
    
    const anyClientSelected = Object.keys(selectedGroups).length === 1;
    const client = anyClientSelected
      ? Object.values(selectedGroups)[0]
      : Object.values(selectedUsers)[0].client;

    return (
      <Auth.Consumer>
        {({ hasPermission }) => (
          <Card className="h-100">
            <CardHeader>
              <NavMenu card tabs className="mt-2 mt-md-0">
                {anyClientSelected && (
                  <Fragment>
                    <NavItem><NavLink to="/clients/details" exact className="nav-link">Szczegóły</NavLink></NavItem>
                    {hasPermission("viewingRoles") && (
                      <NavItem><NavLink to="/clients/roles" className="nav-link">Role</NavLink></NavItem>
                    )}
                    <NavItem><NavLink to="/clients/devices" exact className="nav-link">Urządzenia</NavLink></NavItem>
                    {hasPermission("changingClientPrices") && (
                      <NavItem><NavLink to="/clients/prices" exact className="nav-link">Cennik</NavLink></NavItem>
                    )}
                    {hasPermission("clientHistory") && (
                      <NavItem><NavLink to="/clients/history" exact className="nav-link">Historia</NavLink></NavItem>
                    )}
                  </Fragment>
                )}
                
                {!anyClientSelected && (
                  <Fragment>
                    <NavItem><NavLink to="/clients/user/details" exact className="nav-link">Szczegóły</NavLink></NavItem>
                    <NavItem><NavLink to="/clients/user/devices" exact className="nav-link">Urządzenia</NavLink></NavItem>
                    {hasPermission("userHistory") && (
                      <NavItem><NavLink to="/clients/user/history" exact className="nav-link">Historia</NavLink></NavItem>
                    )}
                  </Fragment>
                )}
              </NavMenu>
            </CardHeader>

            <CardBody style={{ overflowY: "auto", display: "grid" }}>
              <Switch>
                {anyClientSelected && (
                  <Fragment>
                    <Route path="/clients/details" render={props => <ClientDetails {...props} client={client} refreshUsersList={refreshUsersList} />} />
                    <Route path="/clients/roles" render={props => <ClientRoles {...props} client={client} refreshUsersList={refreshUsersList} />} />
                    <Route path="/clients/devices" render={props => <ClientDevices {...props} client={client} refreshUsersList={refreshUsersList} />} />
                    <Route path="/clients/prices" render={props => <ClientPrices {...props} client={client} refreshUsersList={refreshUsersList} />} />
                    <Route path="/clients/history" render={props => <ClientHistory {...props} client={client} refreshUsersList={refreshUsersList} />} />
                    <Redirect to="/clients/details" />
                  </Fragment>
                )}
                {!anyClientSelected && (
                  <Fragment>
                    <Route path="/clients/user/details" render={props => <UserDetails {...props} user={Object.values(selectedUsers)[0]} refreshUsersList={refreshUsersList} />} />
                    <Route path="/clients/user/devices" render={props => <UserDevices {...props} user={Object.values(selectedUsers)[0]} refreshUsersList={refreshUsersList} />} />
                    <Route path="/clients/user/history" render={props => <UserHistory {...props} user={Object.values(selectedUsers)[0]} refreshUsersList={refreshUsersList} />} />
                    <Redirect to="/clients/user/details" />
                  </Fragment>
                )}
              </Switch>
            </CardBody>
          </Card>
        )}
      </Auth.Consumer>
    );
  }
}

Pages.propTypes = {
  selectedUsers: PropTypes.object.isRequired,
  selectedGroups: PropTypes.object.isRequired,
  refreshUsersList: PropTypes.func.isRequired,
};

export default Pages;
