import React, { Component } from "react";

import Pages from "./pages";
import { FilteredList } from "../../../../../components";
import { getRoles } from "../../../../../api";
import RolesFilter from "./RolesFilter";
import RolesList from "./RolesList";

const compareStrings = (field, phrase) => {
  return field && field.toLocaleLowerCase().includes(phrase);
};

const filterBasedOnKeywords = (role, keywords) => {
  let result = true;

  for (const keyword of keywords) {
    const phrase = keyword.value.toLocaleLowerCase();
    result = compareStrings(role.name, phrase)
      || compareStrings(role.description, phrase);

    if (!result) {
      return false;
    }
  }

  return true;
};

const filterRoles = (data, keywords) => {
  if (!keywords || !keywords.length) {
    return data;
  }

  return data.filter(x => filterBasedOnKeywords(x, keywords));
};

const getRole = (roles, key) => roles.find(role => role.id === parseInt(key, 10));

class ClientRoles extends Component {
  renderPages = ({selectedElements, selectedCount, refresh}) => {
    return selectedCount > 0
      ? <Pages selectedRoles={selectedElements} refreshRolesList={refresh} />
      : <p className="p-3">Wybierz rolę</p>;
  };

  onClientAdded = (refresh, selectElement) => id => {
    refresh(() => selectElement(id, true));
  };

  render() {
    const clientId = this.props.client.id;

    return (
      <FilteredList dependencyId={clientId} endpoint={getRoles} data={{ clientId }} keyField="id" itemGetter={getRole} listFilter={filterRoles} keepSelectionsAfterRefresh>
        {({ fullList, filteredList, selectedElements, isFetching }, { refresh, refreshComponents, onFilterChanged, selectElement, selectedCount }) =>
          <div className="content" style={{ margin: "-1.25rem" }}>
            <aside>
              <RolesFilter clientId={clientId} onChange={onFilterChanged} refresh={refresh} onClientAdded={this.onClientAdded(refresh, selectElement)} />

              <RolesList
                filteredRoles={filteredList}
                selectedRoles={selectedElements}
                selectRole={selectElement}
                isFetching={isFetching}
                refreshRolesList={refresh}
                users={this.props.client.users}
                />
            </aside>

            <section>
              {this.renderPages({selectedElements, selectedCount: selectedCount(), refresh})} {/* TODO: add new params */}
            </section>
          </div>
        }
      </FilteredList>
    );
  }
}

export default ClientRoles;
