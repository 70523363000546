import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ButtonLink = ({ to, size }) => {
  const btnSize = size ? `btn-${size}` : "btn-sm";

  return (
    <Link to={to} className={`btn btn-outline-info ${btnSize}`} style={{ verticalAlign: "bottom" }}>
      <FontAwesomeIcon icon="link" size={size} />
      <span className="ml-1">Przejdź</span>
    </Link>
  );
};

export default ButtonLink;
