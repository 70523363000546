import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUser,
  faTimes,
  faCheck,
  faPlus,
  faTasks,
  faSyncAlt,
  faEdit,
  faCircle,
  faUserPlus,
  faTrash,
  faDesktop,
  faAt,
  faTruck,
  faTabletAlt,
  faLink,
} from "@fortawesome/free-solid-svg-icons";

library.add(faUser, faTimes, faCheck, faPlus, faTasks, faSyncAlt, faEdit, faCircle, faUserPlus, faTrash, faDesktop, faAt, faTruck, faTabletAlt, faLink);
