import React, { Component } from "react";
import { ListGroup } from "reactstrap";

import Role from "./Role";
import Auth from "../../../../../Auth";

class RolesList extends Component {
  roleOnClick = e => {
    // e.preventDefault();
    const id = parseInt(e.currentTarget.id.replace("role#", ""), 10); // id of selected role

    this.props.selectRole(id, true); // single selection
  };

  render() {
    const { selectedRoles, filteredRoles, refreshRolesList } = this.props;

    return (
      <Auth.Consumer>
        {({ hasPermission }) => {
          const canRemoveRoles = hasPermission("deletingRoles");

          return (
            <div style={{ overflowY: "auto" }}>
              <ListGroup flush>
                {filteredRoles.map(role => (
                  <Role
                    {...role}
                    key={role.id}
                    onClick={this.roleOnClick}
                    selected={!!selectedRoles[role.id]}
                    refreshRolesList={refreshRolesList}
                    canRemoveRoles={canRemoveRoles}
                    />
                ))}
              </ListGroup>
            </div>
          );
        }}
      </Auth.Consumer>
    );
  }
}

export default RolesList;
