import React, { Component } from "react";
import { Button, InputGroup, InputGroupAddon, CustomInput } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import PropTypes from "prop-types";

// import { editClientScanReminders } from "../../../../../api";
import { withAlert } from "./Alert";

class LabelValueCheckbox extends Component {
  state = {
    isEditing: false,
    newValue: null
  };

  setValue = e => {
    const { checked } = e.target;

    this.setState(() => ({ newValue: checked }));
  };

  startEditing = () => {
    const newValue = this.props.currentValue;

    this.setState(() => ({ newValue, isEditing: true }));
  };
  
  save = () => {
    const { newValue } = this.state;
    // editClientScanReminders({
    //   clientId: this.props.client.id,
    //   scanReminders: this.state.newValue,
    // })
    this.props.save(newValue)
      .then(this.onSuccess)
      .catch(this.onError);
  };

  onSuccess = r => {
    if (r.result === 0) {
      this.setState(() => ({ isEditing: false }), () => {
        this.props.showAlert("Zmiany zostały zapisane.", "success", this.props.onChange);
      });
    }
    else {
      this.onError();
    }
  };

  onError = () => {
    this.props.showAlert("Wystąpił błąd.", "danger");
  };

  cancel = () => {
    this.setState(() => ({ isEditing: false }));
  };

  onKeyDown = e => {
    if (e.key === "Escape") {
      this.cancel();
    }
    else if (e.key === "Enter") {
      const { newValue } = this.state;
      const { currentValue } = this.props;

      //if (this.props.client.scanReminders !== scanReminders) {
      if (currentValue !== newValue) {
        this.save();
      }
    }
  };
  
  render() {
    const { newValue, isEditing } = this.state;

    return (
      <div>
        {!isEditing && (
          <div>
            <div className="d-inline-block" style={{ width: "calc(1.125em + 0.5rem)" }}>
              {this.props.editable && (
                <FontAwesomeIcon
                  title="Edytuj"
                  icon="edit"
                  className={classNames("clickable text-primary mr-2")}
                  onClick={this.startEditing} />
              )}
            </div>

            {/* <span className="d-inline-block"><CustomInput type="checkbox" id="scanReminder" checked={this.props.client.scanReminders} disabled /></span> */}
            <span className="d-inline-block"><CustomInput type="checkbox" id={this.props.id} checked={this.props.currentValue} disabled /></span>
          </div>
        )}

        {isEditing && (
          <form>
            <InputGroup size="sm">
              <span className="flex-fill"><CustomInput type="checkbox" id={this.props.id} checked={newValue} onChange={this.setValue} onKeyDown={this.onKeyDown} autoFocus /></span>

              <InputGroupAddon addonType="append">
                <Button size="sm" title="Zapisz" onClick={this.save} disabled={this.props.currentValue === newValue}><FontAwesomeIcon icon="check" /></Button>
                <Button size="sm" title="Anuluj" onClick={this.cancel}><FontAwesomeIcon icon="times" /></Button>
              </InputGroupAddon>
            </InputGroup>
          </form>
        )}
      </div>
    );
  }
}

LabelValueCheckbox.propTypes = {
  currentValue: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
};

export default withAlert(LabelValueCheckbox);
