import React, { Fragment, PureComponent } from "react";
import { Button } from "reactstrap";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LabelValue, withLoading, AssignDictionaryItemButton, ButtonWithConfirmationDialog, LabelValueCheckbox, ButtonLink } from "../../../../components";
import { formatDate, formatDuration } from "../../../../dateUtils";
import { deleteTerminal, assignClient, getClientsDictionary, getDevicesUnassignedToTerminals, assignDevice, editSerialNumber, editTerminalNotes, editPhoneNumber, editTeamViewerNumber, editNeedsLogin } from "../../../../api";
import { withAlert } from "../../../../components/Alert";
import Auth from "../../../../Auth";

const TERMINAL_NEEDS_LOGIN_DESCRIPTION = "Terminal wyświetli okno logowania dla kierowców. Mogą się logować kierowcy, którzy mają podane login i hasło w ewidencji kierowców. Dotyczy pojazdów bez tachografu.";

const DeviceNavigationDetails = ({ navigation }) => {
  if (!navigation) {
    return null;
  }

  return (
    <Fragment>
      <h3>Dane nawigacji</h3>
      <dl className="row">
        <LabelValue label="Ostatnia aktualizacja" value={navigation.date && formatDate(navigation.date)} />
        <LabelValue label="Dystans" value={navigation.distance ? `${navigation.distance}m` : ""} />
        <LabelValue label="Szacowany czas dojazdu" value={formatDuration(navigation.eta)} />
        <LabelValue label="Status" value={navigation.status} />
      </dl>
    </Fragment>
  );
};

class DeviceDetails extends PureComponent {
  state = {
    selectedDevice: null
  };

  assignDevice = () => {
    assignDevice({
      terminalId: this.props.terminalId,
      deviceId: this.state.selectedDevice ? this.state.selectedDevice.id : null
    })
      .then(r => {
        if (r.result === 0) {
          this.setState(() => ({ selectedDevice: null }), () => this.props.onDeviceAssigned?.());
        }
      })
      .catch(() => { });
  };

  onSelectedDeviceChanged = selectedDevice => {
    this.setState(() => ({ selectedDevice }));
  };

  formatDevice = option => `${option.description}${option.vrn ? ` (nr rej. ${option.vrn})` : ""}`;

  render() {
    const { device } = this.props;
    const { selectedDevice } = this.state;

    return (
      <Fragment>
        <Auth.Consumer>
          {({ hasPermission }) => (
            <h3>
              <span>Pojazd</span>

              {hasPermission("assigningTerminalsToDevices") && (
                <div className="d-inline-block ml-3">
                  <AssignDictionaryItemButton
                    placeholder="Wybierz pojazd"
                    needsConfirmation={true}
                    dropdownClassName="dropdown-inline font-weight-normal"
                    buttonSize="sm"
                    labelField={this.formatDevice}
                    endpoint={getDevicesUnassignedToTerminals}
                    selectedItem={selectedDevice}
                    onSelectedItemChanged={this.onSelectedDeviceChanged}
                    assign={this.assignDevice}
                    isButtonDisabled={selectedDevice && device && device.id === selectedDevice.id}
                    buttonColor="secondary"
                    dropdownMenuStyle={{ minWidth: "400px" }}
                    cache={false}
                  />
                </div>
              )}
            </h3>
          )}
        </Auth.Consumer>

        {device && (
          <Fragment>
            <dl className="row">
              <LabelValue label="Pojazd usunięty" value={device.deleted ? "Tak" : "Nie"} />
              <LabelValue label="Nazwa systemowa" value={device.description} />
              <LabelValue label="Nr rejestracyjny" value={device.vrn} />
              <LabelValue label="Nr boczny" value={device.vehicleNumber} />
            </dl>

            <DeviceNavigationDetails navigation={device.navigation} />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

class ClientDetails extends PureComponent {
  state = {
    selectedClient: null
  };

  assignClient = () => {
    assignClient({
      terminalId: this.props.terminalId,
      clientId: this.state.selectedClient ? this.state.selectedClient.id : null
    })
      .then(r => {
        if (r.result === 0) {
          this.props.onClientAssigned?.();
        }
      })
      .catch(() => { });
  };

  onSelectedClientChanged = selectedClient => {
    this.setState(() => ({ selectedClient }));
  };

  render() {
    const { client } = this.props;
    const { selectedClient } = this.state;

    return (
      <Auth.Consumer>
        {({ hasPermission }) => (
          <div className="d-inline">
            {client && <span>{client.name}</span>}

            {hasPermission("assigningTerminalsToClients") && (
              <div className={classNames("d-inline-block w-75", { "ml-2": !!client })}>
                <AssignDictionaryItemButton
                  placeholder="Wybierz klienta"
                  needsConfirmation={true}
                  endpoint={getClientsDictionary}
                  selectedItem={selectedClient}
                  onSelectedItemChanged={this.onSelectedClientChanged}
                  assign={this.assignClient}
                  isButtonDisabled={(!selectedClient && !hasPermission("sysop")) || (selectedClient && client && client.id === selectedClient.id)}
                  buttonColor="secondary"
                />
              </div>
            )}
          </div>
        )}
      </Auth.Consumer>
    );
  }
}

class DetailsView extends PureComponent {
  showError = () => {
    this.props.showAlert("Wystąpił błąd.", "danger");
  };

  deleteTerminal = () => {
    deleteTerminal({ terminalId: this.props.terminal.id })
      .then(r => {
        if (r.result === 0) {
          this.props.showAlert("Terminal został usunięty.", "success", () => this.props.refreshTerminalsList(this.props.refreshDetails));
        }
        else {
          this.showError();
        }
      })
      .catch(this.showError);
  };

  saveSerialNumber = serialNumber => {
    editSerialNumber({
      terminalId: this.props.terminal.id,
      sn: serialNumber
    })
      .then(r => {
        if (r.result === 0) {
          this.props.showAlert("Zmiany zostały zapisane.", "success", () => this.props.refreshTerminalsList(this.props.refreshDetails));
        }
        else {
          this.showError();
        }
      })
      .catch(this.showError);
  };

  saveTeamViewerNumber = teamViewerNumber => {
    editTeamViewerNumber({
      terminalId: this.props.terminal.id,
      teamViewerNumber
    })
      .then(r => {
        if (r.result === 0) {
          this.props.showAlert("Zmiany zostały zapisane.", "success", () => this.props.refreshTerminalsList(this.props.refreshDetails));
        }
        else {
          this.showError();
        }
      })
      .catch(this.showError);
  };

  saveNotes = notes => {
    editTerminalNotes({
      terminalId: this.props.terminal.id,
      notes
    })
      .then(r => {
        if (r.result === 0) {
          this.props.showAlert("Zmiany zostały zapisane.", "success", () => this.props.refreshTerminalsList(this.props.refreshDetails));
        }
        else {
          this.showError();
        }
      })
      .catch(this.showError);
  };

  savePhoneNumber = phoneNumber => {
    editPhoneNumber({
      terminalId: this.props.terminal.id,
      phoneNumber
    })
      .then(r => {
        if (r.result === 0) {
          this.props.showAlert("Zmiany zostały zapisane.", "success", () => this.props.refreshTerminalsList(this.props.refreshDetails));
        }
        else {
          this.showError();
        }
      })
      .catch(this.showError);
  };

  saveNeedsLogin = needsLogin =>
    editNeedsLogin({
      terminalId: this.props.terminal.id,
      needsLogin
    });

  refreshTerminalsList = () => {
    this.props.refreshTerminalsList(this.props.refreshDetails);
  };

  connectRemote = () => {
    global.window.open(`http://start.teamviewer.com/${this.props.terminal.teamViewerNumber}/authorization/password/mode/control`, "_blank");
  };

  render() {
    const { className, terminal } = this.props;

    if (!terminal) {
      return <div className={className} />;
    }

    const deleteButtonArgs = {
      outline: true,
      color: "danger",
      size: "sm",
      style: {
        verticalAlign: "bottom"
      },
      onClick: this.deleteTerminal
    };

    return (
      <Auth.Consumer>
        {({ hasPermission }) => {
          const canEditTerminals = hasPermission("editingTerminals");
          const hasRemoteAccess = hasPermission("terminalsRemoteAccess");
          const hasAccessToSimCards = hasPermission("managingSimCards");
          const phoneNumberLabel = (
            <>
              <span className="mr-2">Nr karty SIM</span>
              {hasAccessToSimCards && !!terminal.simCardId && <ButtonLink size="xs" to={`/simCards/details/${terminal.simCardId}`} />}
            </>
          );

          return (
            <div className="col-12" key={terminal.id}>
              <h3>
                <span className="mr-3">Terminal</span>

                {hasPermission("deletingTerminals") && (
                  <span className="mr-2">
                    {terminal.client || terminal.device
                      ? (
                        <ButtonWithConfirmationDialog
                          dialogMessage="Terminal jest przypisany do klienta i/lub pojazdu. Usunięcie terminala spowoduje usunięcie tych przypisań. Czy na pewno chcesz usunąć ten terminal?"
                          {...deleteButtonArgs}>
                          Usuń
                        </ButtonWithConfirmationDialog>
                      )
                      : <Button {...deleteButtonArgs}>Usuń</Button>
                    }
                  </span>
                )}
                {hasRemoteAccess && !!terminal.teamViewerNumber && (
                  <span className="mr-2">

                    <Button
                      color="info"
                      size="sm"
                      style={{ verticalAlign: "bottom" }}
                      onClick={this.connectRemote}
                      outline>
                      <FontAwesomeIcon icon="desktop" /> Połącz
                    </Button>
                  </span>
                )}
              </h3>

              <dl className="row">
                <LabelValue label="Klient" value={<ClientDetails client={terminal.client} terminalId={terminal.id} onClientAssigned={this.refreshTerminalsList} />} />
                <LabelValue label="Id urządzenia" value={terminal.imei} />
                <LabelValue label="Nr seryjny" value={terminal.sn} editable={canEditTerminals} onEdited={this.saveSerialNumber} />
                <LabelValue label="Nr TeamViewer" value={terminal.teamViewerNumber} hidden={!hasRemoteAccess} editable={hasRemoteAccess} onEdited={this.saveTeamViewerNumber} />
                <LabelValue label={phoneNumberLabel} value={terminal.phoneNumber} editable={canEditTerminals} onEdited={this.savePhoneNumber} />
                <LabelValue label="Wersja aplikacji" value={terminal.appVersion} />
                <LabelValue label="Docelowa wersja aplikacji" value={terminal.targetAppVersion} />
                <LabelValue label="Nr licencji PTV" value={terminal.ptvLicenseNumber} />
                <LabelValue label="Wymaga ręcznego logowania" description={TERMINAL_NEEDS_LOGIN_DESCRIPTION} value={<LabelValueCheckbox
                  currentValue={terminal.needsLogin}
                  save={this.saveNeedsLogin}
                  id="needsLogin"
                  editable={canEditTerminals}
                  onChange={this.refreshTerminalsList}
                />} customValueField />
                <LabelValue label="Notatki" value={terminal.notes} editable={canEditTerminals} onEdited={this.saveNotes} maxLength={128} />
              </dl>

              <DeviceDetails device={terminal.device} terminalId={terminal.id} onDeviceAssigned={this.refreshTerminalsList} />
            </div>
          )}
        }
      </Auth.Consumer>
    );
  }
}

export default withAlert(withLoading(DetailsView));
