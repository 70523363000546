import React, { Component, Fragment } from "react";
import { CustomInput } from "reactstrap";
import PropTypes from "prop-types";
import classNames from "classnames";

import Auth from "../../../../../../../Auth";
import { withLoading, SaveCancelBar } from "../../../../../../../components";
import { withAlert } from "../../../../../../../components/Alert";
import { roleReassignUsers } from "../../../../../../../api";
import { formatDate } from "../../../../../../../dateUtils";

const getChangedUsers = (users, selectedUsers) => {
  return users.reduce((a, x) => {
    const selected = !!selectedUsers[x.id];

    if (selected && !x.selected) {
      a.selected.push(x);
    }
    else if (!selected && x.selected) {
      a.deselected.push(x);
    }

    return a;
  }, { selected: [], deselected: [] });
};

class UsersView extends Component {
  handleResponse = r => {
    if (r.result === 0) {
      this.props.showAlert("Zmiany zostały zapisane!", "success", () => this.props.refreshUsersList(this.props.refreshRolesList));
    }
    else {
      this.handleError();
    }
  };

  handleError = () => {
    this.props.showAlert("Zmiany nie zostały zapisane!", "danger");
  };

  cancel = (selectElements) => () => {
    selectElements(this.props.users.reduce((a, x) => {
      if (x.selected) {
        a.push(x.id);
      }

      return a;
    }, []), true);
  };

  save = (selected, deselected) => () => {
    roleReassignUsers({
      roleId: this.props.role.id,
      addIds: selected.map(x => x.id),
      removeIds: deselected.map(x => x.id)
    })
      .then(this.handleResponse)
      .catch(this.handleError);
  };

  select = e => {
    const id = parseInt(e.target.id.replace("uid#", ""), 10);

    this.props.selectElement(id, false);
  };

  render() {
    const { usersFiltered, className, selectedUsers, selectElements } = this.props;

    if (!usersFiltered) {
      return <div className={className} />;
    }

    const { selected, deselected } = getChangedUsers(usersFiltered, selectedUsers);
    const isDirty = !!(selected.length || deselected.length);

    return (
      <Auth.Consumer>
        {({ hasPermission }) => {
          const canManageRoles = hasPermission("managingRoles");

          return (
            <div style={{ overflowY: "scroll", margin: "-1.25rem", height: "calc(100% - 1.5rem)" }}>
              <div style={{ margin: "1.25rem" }}>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {canManageRoles && <th>&nbsp;</th>}
                      <th>Login</th>
                      <th>Imię</th>
                      <th>Nazwisko</th>
                      <th>Data utworzenia</th>
                      <th>Nazwa</th>
                      {/* <th>Aktywny</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {usersFiltered.map(user => (
                      <tr key={`uid#${user.id}`} className={classNames({ "text-decoration-line-through": !user.enabled })}>
                        {canManageRoles && (
                          <td>
                            <CustomInput type="checkbox" id={`uid#${user.id}`} checked={!!selectedUsers[user.id]} onChange={this.select} />
                          </td>
                        )}
                        <td>{user.login}</td>
                        <td>{user.firstName}</td>
                        <td>{user.surname}</td>
                        <td>{formatDate(user.creationDate, false, false)}</td>
                        <td>{user.name}</td>
                        {/* <td><CustomInput type="checkbox" id={`euid#${user.id}`} checked={user.enabled} disabled /></td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <SaveCancelBar isDirty={isDirty} cancel={this.cancel(selectElements)} save={this.save(selected, deselected)}>
                <Fragment>
                  <span className="mr-2">Dodane: {selected.length}</span>
                  <span className="mr-2">Usunięte: {deselected.length}</span>
                </Fragment>
              </SaveCancelBar>
            </div>
          );
        }}
      </Auth.Consumer>
    );
  }
}

UsersView.propTypes = {
  role: PropTypes.object.isRequired,
  refreshUsersList: PropTypes.func.isRequired,
  selectElement: PropTypes.func.isRequired,
  selectElements: PropTypes.func.isRequired,
  usersFiltered: PropTypes.array,
  users: PropTypes.array,
};

export default withAlert(withLoading(UsersView));
