import { PureComponent } from "react";
import PropTypes from "prop-types";

class DataProvider extends PureComponent {
  isFetching = 0;

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.fetchData();

      this.setupTimer();
    }, this.props.timeout || 0);

    this.props.fetchMethodSetter?.(this.fetchData);
  }

  componentDidUpdate() {
    this.fetchData();
  }

  componentWillUnmount() {
    this.cancelled = true;

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    if (this.props.interval && this.intervalHandler) {
      clearInterval(this.intervalHandler);
    }
  }

  setupTimer = () => {
    this.timeout = null;

    if (this.props.interval) {
      this.intervalHandler = setInterval(this.fetchData, this.props.interval);
    }
  };

  fetchingCallback = () => {
    this.props.onDataFetching?.(!!this.isFetching);
  };

  fetchData = () => {
    this.isFetching += 1;

    if (this.isFetching !== 1) {
      this.abortController?.abort();
    }
    this.abortController = new global.AbortController();

    this.props
      .endpoint(this.props.data, this.abortController.signal)
      .then(data => {
        if (this.cancelled) {
          return;
        }

        this.isFetching -= 1;
        this.props.onDataFetching?.(!!this.isFetching);

        this.props.onDataChanged?.(data, false);
      })
      .catch(() => {
        if (this.cancelled) {
          return;
        }

        this.isFetching -= 1;
        this.props.onDataFetching?.(!!this.isFetching);

        console.error("Oopsie...");
        this.props.onDataChanged?.(null, true);
      });
  };

  render() {
    return null;
  }
}

DataProvider.propTypes = {
  endpoint: PropTypes.func.isRequired,
  data: PropTypes.object,
  onDataFetching: PropTypes.func,
  onDataChanged: PropTypes.func,
  interval: PropTypes.number,
  timeout: PropTypes.number,
  fetchMethodSetter: PropTypes.func,
};

export default DataProvider;
