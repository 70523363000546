import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { CustomInput } from "reactstrap";

class DeviceTableRow extends PureComponent {
  select = e => {
    const id = parseInt(e.target.id.replace("device#", ""), 10);

    this.props.selectElement(id, false);
  };

  render() {
    const { device, hasReassignPermission, canAccessDeveloperSettings, selected, showCheckboxes, canAccessSimCards } = this.props;
    
    return (
      <tr>
        {showCheckboxes && (
          <td>
            <CustomInput type="checkbox" id={`device#${device.id}`} checked={selected} onChange={this.select} disabled={!hasReassignPermission} />
          </td>
        )}
        <td>{device.description}</td>
        <td>{device.make} {device.model}</td>
        <td>{device.vrn}</td>
        <td>{device.vehicleNumber}</td>
        <td>{device.serialNumber}</td>
        <td>{device.imei}</td>
        <td>
          <span>{device.ccid}</span>
          {canAccessSimCards && !!device.simCardId && (
            <span className="ml-1">
              <Link to={`/simCards/details/${device.simCardId}`} title="Przejdź do szczegółów karty SIM">
                <FontAwesomeIcon icon="link" size="xs" color="#17a2b8" />
              </Link>
            </span>
          )}
        </td>
        <td>
          <span>{device.simCardPhoneNumber}</span>
        </td>
        {canAccessDeveloperSettings && <td>{device.id}</td>}
      </tr>
    );
  }
}

export default DeviceTableRow;
