import React, { Component, PureComponent, createRef } from "react";
import Select, { components } from "react-select";
import { Button, InputGroup, InputGroupAddon } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import { editPaymentWarning } from "../../../../../api";
import { withAlert } from "../../../../../components/Alert";

const paymentWarningOptions = [
  { label: "nie", value: "Off" },
  { label: "monit na ekranie startowym", value: "FrontPage" },
];

const styles = {
  container: styles => ({
    ...styles,
    flex: "auto",
  }),
};

class SelectContainer extends PureComponent {
  render() {
    const { children, save, cancel, onKeyDown, ...props } = this.props;

    return (
      <components.SelectContainer {...props} innerProps={{ ...props.innerProps, onKeyDown }}>
        {children}
      </components.SelectContainer>
    );
  }
}

class PaymentWarning extends Component {
  state = {
    isEditing: false,
    newValue: null,
    menuIsOpen: false,
  };
  selectRef = createRef();

  getOption = () => {
    const value = this.props.user.paymentWarning;

    if (value) {
      for (let option of paymentWarningOptions) {
        if (option.value === value) {
          return option;
        }
      }
    }

    return null;
  };

  formatLabel = () => {
    const option = this.getOption();

    if (option) {
      return option.label;
    }

    return null;
  };

  setValue = newValue => {
    this.setState(() => ({ newValue }));
  };

  startEditing = () => {
    const newValue = this.getOption();

    this.setState(() => ({ newValue, isEditing: true }));
  };
  
  save = () => {
    editPaymentWarning({
      userId: this.props.user.id,
      paymentWarning: this.state.newValue.value,
    })
    .then(this.onSuccess)
    .catch(this.onError);
  };

  onSuccess = r => {
    if (r.result === 0) {
      this.setState(() => ({ isEditing: false }), () => {
        this.props.showAlert("Zmiany zostały zapisane.", "success", this.props.onPaymentWarningAssigned);
      });
    }
    else {
      this.onError();
    }
  };

  onError = () => {
    this.props.showAlert("Wystąpił błąd.", "danger");
  };

  cancel = () => {
    this.setState(() => ({ isEditing: false }));
  };

  toggleMenuIsOpen = () => {
    this.setState(state => ({ menuIsOpen: !state.menuIsOpen }));
  };

  onKeyDown = baseKeyDown => e => {
    const { menuIsOpen } = this.state;
    
    if (!menuIsOpen) {
      if (e.key === "Enter") {
        e.preventDefault();
        const { newValue } = this.state;
        const label = this.formatLabel();
        
        if (newValue && newValue.label !== label) { // the option was changed
          this.save();
          return;
        }
      }
      else if (e.key === "Escape") {
        this.cancel();
        return;
      }
    }
    
    return baseKeyDown(e);
  };

  onMenuOpen = () => {
    this.setState(() => ({ menuIsOpen: true }));
  };
  
  onMenuClose = () => {
    this.setState(() => ({ menuIsOpen: false }), () => this.selectRef.current?.select?.focus());
  };

  render() {
    const { newValue, isEditing } = this.state;
    const label = this.formatLabel();

    return (
      <div>
        {!isEditing && (
          <div>
            <div className="d-inline-block" style={{ width: "calc(1.125em + 0.5rem)" }}>
              {this.props.editable && (
                <FontAwesomeIcon
                  title="Edytuj"
                  icon="edit"
                  className={classNames("clickable text-primary mr-2")}
                  onClick={this.startEditing} />
              )}
            </div>

            <span>{label || <>&nbsp;</>}</span>
          </div>
        )}

        {isEditing && (
          <form>
            <InputGroup size="sm">
              <Select
                placeholder="Wybierz..."
                ref={this.selectRef}
                options={paymentWarningOptions}
                value={newValue}
                onChange={this.setValue}
                styles={styles}
                components={{ SelectContainer: ({ innerProps, ...props }) => <SelectContainer {...props} onKeyDown={this.onKeyDown(innerProps.onKeyDown)} /> }}
                onMenuOpen={this.onMenuOpen}
                onMenuClose={this.onMenuClose}
                autoFocus
              />

              <InputGroupAddon addonType="append">
                <Button size="sm" title="Zapisz" onClick={this.save} disabled={!newValue || newValue.label === label}><FontAwesomeIcon icon="check" /></Button>
                <Button size="sm" title="Anuluj" onClick={this.cancel}><FontAwesomeIcon icon="times" /></Button>
              </InputGroupAddon>
            </InputGroup>
          </form>
        )}
      </div>
    );
  }
}

export default withAlert(PaymentWarning);
