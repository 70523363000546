import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { ListGroup, ListGroupItem } from "reactstrap";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AddUser from "./AddUser";
import User from "./User";

import "./UsersClient.css";

class UsersClient extends PureComponent {
  onUserAdded = id => {
    this.props.data.refresh(() => this.props.data.selectUser(id, true));
  };

  render() {
    const { data, index, style } = this.props;
    const { usersClients, userOnClick, groupOnClick, selectedGroups, selectedUsers, canAddUsers } = data;
    const { client, users } = usersClients[index];

    return (
      <div className="client-group" style={style}>
        <ListGroup className="client-sticky" flush>
          <ListGroupItem
            action
            id={`client#${client.id}`}
            tag="button"
            type="button"
            active={!!selectedGroups[client.id]}
            onClick={groupOnClick}>
            <div className="row client-container">
              <div className="col-12 d-flex align-items-center justify-content-between">
                <small className="client-details w-100 text-center">
                  <strong className={classNames("align-text-top", { "mr-2": !!client.name })}>{client.name}</strong>

                  <small title="Liczba aktywnych pojazdów">{client.activeDevicesCount}</small>

                  {client.deleted && (
                    <small className="ml-1 text-danger" title="Usunięty">
                      <FontAwesomeIcon icon="trash" />
                    </small>
                  )}
                </small>

                {canAddUsers && (
                  <small className="client-buttons" onClick={e => e.stopPropagation()}>
                    <AddUser key={`addUser#${client.id}`} clientId={client.id} onSuccess={this.onUserAdded} />
                  </small>
                )}
              </div>
            </div>
          </ListGroupItem>
        </ListGroup>

        <ListGroup flush>
          {users.map(user => (
            <User {...user} key={user.id} onClick={userOnClick} selected={!!selectedUsers[user.id]} />
          ))}
        </ListGroup>
      </div>
    );
  }
}

UsersClient.propTypes = {
  data: PropTypes.shape({
    usersClients: PropTypes.array.isRequired,
    userOnClick: PropTypes.func.isRequired,
    refresh: PropTypes.func,
  }).isRequired,
};

export default UsersClient;
