import React, { Component, Fragment } from "react";
import { Modal, Button, Col, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, CustomInput, FormText, FormFeedback, Alert, Row } from "reactstrap";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import countries from "i18n-iso-countries";
import isEmail from "validator/es/lib/isEmail";
import isMobilePhone from "validator/es/lib/isMobilePhone";
import isPostalCode from "validator/es/lib/isPostalCode";

import { ValidatableSelect } from "../../components"; 
import { getEtollSubscriptionPlans, registerTransaction } from "../../api";
import { withAlert } from "../../components/Alert";
import { LocaleContext } from "../../components/LocaleContextProvider";
import isVAT from "../../isVAT";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/pl.json"));

const getInitialFormState = () => ({
  subscriptionPlanId: null,
  email: "",
  phone: "",
  client: "",
  address: "",
  city: "",
  country: null,
  zip: "",
  vatid: ""
});

class PaymentDialog extends Component {
  static contextType = LocaleContext;

  state = {
    ...getInitialFormState(),
    modalIsOpen: false,
    subscriptionPlans: [],
    countriesList: []
  };

  initializeDictionaries = () => {
    const { locale } = this.context;

    getEtollSubscriptionPlans({
      lang: locale
    })
      .then(r => {
        let subscriptionPlans = [];
        const countriesList = Object.entries(countries.getNames(locale, { select: "official" }))
          .map(([value, label]) => ({ value, label, requiresVAT: value === "PL" }));
        countriesList.sort((a, b) => a.label.localeCompare(b.label));

        if (r.result === 0) {
          subscriptionPlans = r.list;
        }

        this.setState(() => ({
          countriesList,
          subscriptionPlans,
          subscriptionPlanId: subscriptionPlans.length ? subscriptionPlans[0].id : null
        }));
      });
  }

  registerTransaction = () => {
    const { locale } = this.context;
    const { intl } = this.props;
    const { subscriptionPlanId, email, phone, client, address, city, country, zip, vatid } = this.state;

    registerTransaction({
      lang: locale,
      etollNumber: this.props.etollNumber,
      etollPin: this.props.etollPin,
      subscriptionPlanId,
      email,
      phone: phone.replace(/[^\d]/g, ""), // removes all non-digits,
      client,
      address,
      city,
      country: country.value,
      zip,
      vatid
    })
      .then(r => {
        if (r.result === 0) {
          global.location.href = r.redirect;
          return;
        }
        
        this.props.showAlert(intl.formatMessage({ id: "etoll:generalError", defaultMessage: "Wystąpił błąd" }), "danger");
      });
  };

  toggleModal = callback => {
    const cb = callback && callback.apply ? callback : undefined;

    this.setState(prevState => {
      const modalIsOpen = !prevState.modalIsOpen;

      if (modalIsOpen) {
        this.initializeDictionaries();

        return {
          modalIsOpen
        };
      }
      
      return {
        ...getInitialFormState(), // reset form on modal close
        modalIsOpen
      }
    }, cb);
  };

  onInput = e => {
    const { name, value } = e.target;
    this.setState(() => ({ [name]: value }));
  };

  onCountryChange = country => {
    this.setState(() => ({ country }));
  };

  onSubscriptionPlanChange = e => {
    const { name, value } = e.target;

    this.setState(() => ({ [name]: parseInt(value, 10) }));
  };

  isVatIdValid = () => {
    const { country, vatid } = this.state;

    try {
      return country && isVAT(vatid, country.value);
    }
    catch {
      return null; // in case the validator can't handle the country
    }
  };

  isPostalCodeValid = () => {
    const { country, zip } = this.state;

    try {
      return country && isPostalCode(zip, country.value);
    }
    catch {
      return null; // in case the validator can't handle the country
    }
  };

  render() {
    const { etollNumber, activeToDate, intl } = this.props;
    const { modalIsOpen, subscriptionPlans, subscriptionPlanId, client, phone, email, countriesList, country, vatid, zip, address, city } = this.state;

    const subscriptionPlan = subscriptionPlans && subscriptionPlanId
      ? subscriptionPlans.find(x => x.id === subscriptionPlanId)
      : null;
    const subscriptionPlanMonths = subscriptionPlan?.months || 0;
    const phoneNumberValidationFailed = !isMobilePhone(phone, "any", { strictMode: true });
    const emailValidationFailed = !isEmail(email);
    const vatidValidationResult = this.isVatIdValid();
    const vatidValidationSuccessful = vatidValidationResult === true;
    const vatidValidationFailed = vatidValidationResult === false;
    const zipValidationResult = this.isPostalCodeValid();
    const zipValidationSuccessful = zipValidationResult === true;
    const zipValidationFailed = zipValidationResult === false;
    const isVATRequired = country && country.value === "PL";

    const formIsValid = country && subscriptionPlan && client && email && phone && address && city
      && (zipValidationResult === null || zipValidationSuccessful)
      && (vatidValidationResult === null || vatidValidationSuccessful);

    return (
      <Row>
        <Button color="primary" className="ml-auto" onClick={this.toggleModal}><FormattedMessage id="etoll:resubscribeButton" defaultMessage="Przedłuż abonament" /></Button>

        <Modal isOpen={modalIsOpen} toggle={this.toggleModal} backdrop="static" autoFocus={false} size="xl" keyboard={false}>
          <ModalHeader><FormattedMessage id="etoll:invoiceData" defaultMessage="Dane do faktury" /></ModalHeader>
          <ModalBody>
            <Alert color="primary">
              <FormattedMessage
                id="etoll:customOrderNotice"
                defaultMessage="W przypadku pytań lub chęci zbiorowego przedłużenia większej ilości abonamentów, prosimy o kontakt na e-mail <mail>bok@sigen.pl</mail>" values={{
                  mail: chunks => (
                    <a
                      className="alert-link"
                      href="mailto:bok@sigen.pl">
                        {chunks}
                    </a>
                  )}}
                />
            </Alert>

            <Form>
              <FormGroup row>
                <Label for="etollNumber" sm={4}><FormattedMessage id="etoll:businessId" defaultMessage="Identyfikator biznesowy" /></Label>
                <Col sm={8}>
                  <Input id="etollNumber" value={etollNumber} plaintext readOnly />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="activeToDate" sm={4}><FormattedMessage id="etoll:activeToDate" defaultMessage="Data aktywności usługi" /></Label>
                <Col sm={8}>
                  <Input id="activeToDate" value={activeToDate} plaintext readOnly />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="country" sm={4}>
                  <FormattedMessage id="etoll:countryLabel" defaultMessage="Kraj" />
                </Label>
                <Col sm={8}>
                  <ValidatableSelect
                    id="country"
                    options={countriesList}
                    value={country}
                    placeholder={intl.formatMessage({ id: "component:select:placeholder", defaultMessage: "Wybierz..." })}
                    valid={!!country}
                    invalid={!country}
                    onChange={this.onCountryChange}
                  />
                  {isVATRequired && (
                    <FormText><FormattedMessage id="etoll:vatNotification" defaultMessage="Do podanej kwoty doliczony zostanie podatek VAT" /></FormText>
                  )}
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label tag="legend" sm={4}><FormattedMessage id="etoll:subscription" defaultMessage="Abonament" /></Label>
                <Col sm={8}>
                  {subscriptionPlans.map(x => (
                    <FormGroup key={x.id}>
                      <CustomInput
                        type="radio"
                        name="subscriptionPlanId"
                        id={`plan${x.id}`}
                        value={x.id}
                        checked={subscriptionPlanId === x.id}
                        onChange={this.onSubscriptionPlanChange}
                        label={(
                          <Fragment>
                            {x.name}{" "}
                            <strong>
                              <FormattedNumber
                                value={x.amount / 100}
                                // eslint-disable-next-line react/style-prop-object
                                style="currency"
                                currency={x.currency}
                              />
                            </strong>{" "}
                            {isVATRequired && "+ 23% VAT "}
                            (
                              <FormattedNumber
                                value={x.amount / 100 / x.months}
                                // eslint-disable-next-line react/style-prop-object
                                style="currency"
                                currency={x.currency}
                              />{" "}
                              <FormattedMessage id="etoll:exVat" defaultMessage="netto" /> / <FormattedMessage id="etoll:month" defaultMessage="miesiąc" />
                            )
                          </Fragment>
                        )}
                      />
                    </FormGroup>
                  ))}

                  <FormText>
                    <FormattedMessage
                      id="etoll:subscriptionInfo"
                      defaultMessage="Okres aktywności usługi zostanie przedłużony o {months, plural, =0 {# miesięcy} one {#miesiąc} few {# miesiące} other {# miesięcy}} od dnia zakończenia bieżącego okresu"
                      description="Wyjaśnienie odnośnie daty obowiązywania nowego abonamentu"
                      values={{
                        months: subscriptionPlanMonths
                      }}
                    />
                  </FormText>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="client" sm={4}>
                  <FormattedMessage id="etoll:companyNameLabel" defaultMessage="Nazwa firmy lub imię i nazwisko" />
                </Label>
                <Col sm={8}>
                  <Input type="text" name="client" id="client" maxLength={40} onChange={this.onInput} value={client} valid={!!client} invalid={!client} />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="email" sm={4}>E-mail</Label>
                <Col sm={8}>
                  <Input type="text" name="email" id="email" maxLength={50} onChange={this.onInput} value={email} valid={!emailValidationFailed} invalid={emailValidationFailed} />
                  <FormFeedback><FormattedMessage id="etoll:emailFeedback" defaultMessage="Wprowadź prawidłowy adres e-mail" /></FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="phone" sm={4}>
                  <FormattedMessage id="etoll:phoneNumberLabel" defaultMessage="Numer telefonu komórkowego" />
                </Label>
                <Col sm={8}>
                  <Input type="text" name="phone" id="phone" maxLength={30} onChange={this.onInput} value={phone} valid={!phoneNumberValidationFailed}
                    invalid={phoneNumberValidationFailed} placeholder={intl.formatMessage({ id: "etoll:phoneNumberPlaceholder", defaultMessage: "np. +48 500600700" })} />
                  <FormFeedback><FormattedMessage id="etoll:phoneNumberFeedback" defaultMessage="Wprowadź prawidłowy numer telefonu komórkowego" /></FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="address" sm={4}>
                  <FormattedMessage id="etoll:addressLabel" defaultMessage="Adres" />
                </Label>
                <Col sm={8}>
                  <Input type="text" name="address" id="address" maxLength={80} onChange={this.onInput} value={address} valid={!!address} invalid={!address} />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="city" sm={4}>
                  <FormattedMessage id="etoll:cityLabel" defaultMessage="Miasto" />
                </Label>
                <Col sm={8}>
                  <Input type="text" name="city" id="city" maxLength={50} onChange={this.onInput} value={city} valid={!!city} invalid={!city} />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="zip" sm={4}>
                  <FormattedMessage id="etoll:zipLabel" defaultMessage="Kod pocztowy" />
                </Label>
                <Col sm={8}>
                  <Input type="text" name="zip" id="zip" maxLength={10} onChange={this.onInput} value={zip} valid={zipValidationSuccessful} invalid={zipValidationFailed} />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="vatid" sm={4}>
                  <FormattedMessage id="etoll:vatidLabel" defaultMessage="NIP" />
                </Label>
                <Col sm={8}>
                  <Input type="text" name="vatid" id="vatid" onChange={this.onInput} value={vatid}
                    valid={vatidValidationSuccessful}
                    invalid={vatidValidationFailed} />
                </Col>
              </FormGroup>

            </Form>
          </ModalBody>
          <ModalFooter>
            {subscriptionPlan && country && (
              <span><FormattedMessage id="etoll:amountTotal" defaultMessage="Kwota do zapłaty" />:{" "}
                <strong>
                  <FormattedNumber
                    value={(country.requiresVAT ? subscriptionPlan.amountWithVAT : subscriptionPlan.amount) / 100}
                    // eslint-disable-next-line react/style-prop-object
                    style="currency"
                    currency={subscriptionPlan.currency}
                  />
                </strong>
              </span>
            )}
            <Button
              color="primary"
              onClick={this.registerTransaction}
              disabled={!formIsValid}
              title={!formIsValid ? intl.formatMessage({ id: "etoll:fixFormErrors", defaultMessage: "Popraw błędy w formularzu"}) : ""}>
              <FormattedMessage id="etoll:payButton" defaultMessage="Zapłać" />
            </Button>
            <Button color="secondary" onClick={this.toggleModal}><FormattedMessage id="etoll:cancelButton" defaultMessage="Anuluj" /></Button>
          </ModalFooter>
        </Modal>
      </Row>
    );
  }
}

export default withAlert(injectIntl(PaymentDialog));
