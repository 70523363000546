import React, { Component } from "react";

import { withLoading } from "../../../../../components";
import { formatDate } from "../../../../../dateUtils";
import { formatUser } from "../../../../../utils";

class HistoryView extends Component {
  render() {
    const { isFetching, history } = this.props;

    if (isFetching) {
      return <div className={this.props.className}></div>
    }

    if (!history) {
      return null;
    }

    return (
      <div>
        <table className="table table-striped history-table">
          <thead>
            <tr>
              <th>Data</th>
              <th>Akcja</th>
              <th>Opis</th>
              <th>Użytkownik</th>
            </tr>
          </thead>
          <tbody>
            {history.list.map(entry => (
              <tr key={entry.id}>
                <td>{formatDate(entry.date)}</td>
                <td>{entry.action}</td>
                <td>{entry.description}</td>
                <td>{formatUser(entry.user)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withLoading(HistoryView);
