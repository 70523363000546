import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { State } from "react-automata";

import { getUserInfo, logout } from "./api";
import { withStateMachine } from "./components";
import { getChannel } from "./stateMachineUtils";

import "./App.css";
import Auth from "./Auth";

import Login from "./pages/login";
import Home from "./pages/home";
import Account from "./pages/account";

const LoginComponent = renderProps => (
  <Auth.Consumer>
    {authProps => <Login {...authProps} {...renderProps} />}
  </Auth.Consumer>
);
const HomeComponent = renderProps => <Home {...renderProps} />;
const AccountComponent = renderProps => <Account {...renderProps} />;

export const statechart = {
  initial: "establishment",
  states: {
    establishment: {
      onEntry: "establishTarget",
      on: {
        account: "account",
        loading: "loading",
      }
    },
    account: {},
    loading: {
      onEntry: "getUserInfo",
      on: {
        SUCCESS: "loggedIn",
        FAILURE: "notLoggedIn",
      },
    },
    loggedIn: {},
    notLoggedIn: {
      on: {
        loginSuccessful: "loading",
      },
    },
  },
};

export class App extends Component {
  state = {
    logout: cb => this.logout(cb),
    user: undefined,
    userId: undefined,
    onLoggedIn: () => this.props.transition("loginSuccessful"),
    permissionsObject: {},
    hasPermission: () => {},
    hasPermissions: () => {},
  };

  logout = successCallback => {
    logout()
      .then(response => {
        this.setState({ user: null, userId: null }, () => successCallback(response));
      })
      .catch(() => { });
  };

  getUserInfo = () => {
    getUserInfo()
      .then(user => {
        const userId = user.id;
        const permissionsObject = user.permissions.reduce((a, x) => {
          a[x] = x;

          return a;
        }, {});

        this.setState(() => ({
          user,
          userId,
          permissionsObject,
          hasPermission: p => !!permissionsObject[p],
          hasPermissions: (...perms) => perms.every(p => !!permissionsObject[p]),
        }), () => this.props.transition("SUCCESS"));
      })
      .catch(() => {
        this.props.transition("FAILURE");
      });
  };

  establishTarget = () => {
    if (window.location.host.startsWith("account")) {
      this.props.transition("account");
    }
    else {
      this.props.transition("loading");
    }
  };

  render() {
    return (
      <Auth.Provider value={this.state}>
        <State channel={getChannel(this)} is="notLoggedIn">
          <Switch>
            <Route path="/login" render={LoginComponent} />
            <Redirect to="/login" />
          </Switch>
        </State>
        <State channel={getChannel(this)} is="account">
          <Switch>
            <Route path="/" render={AccountComponent} />
          </Switch>
        </State>
        <State channel={getChannel(this)} is="loggedIn">
          <Switch>
            <Redirect
              from="/login"
              to={new URL(window.location).searchParams.get("redir") || "/"}
            />
            <Route path="/" render={HomeComponent} />
          </Switch>
        </State>
      </Auth.Provider>
    );
  }
}

export default withStateMachine(statechart)(App);
