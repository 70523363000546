import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { NavLink, Switch, Route, Redirect } from "react-router-dom";
import { Nav as NavMenu, NavItem, Card, CardHeader, CardBody } from "reactstrap";

import Details from "./details";

class Pages extends PureComponent {
  render() {
    const { selectedSimCards, refreshSimCardsList } = this.props;

    return (
      <Card className="h-100">
        <CardHeader>
          <NavMenu card tabs className="mt-2 mt-md-0">
            <NavItem><NavLink to="/simcards/details" className="nav-link">Szczegóły</NavLink></NavItem>
          </NavMenu>
        </CardHeader>

        <CardBody style={{ overflowY: "auto" }}>
          <Switch>
            <Route path="/simcards/details/:simCardId?" render={props => <Details {...props} simCard={Object.values(selectedSimCards)[0]} refreshSimCardsList={refreshSimCardsList} />} />
            <Redirect to="/simcards/details" />
          </Switch>
        </CardBody>
      </Card>
    );
  }
}

Pages.propTypes = {
  selectedSimCards: PropTypes.object.isRequired,
  selectedCount: PropTypes.number.isRequired,
  refreshSimCardsList: PropTypes.func.isRequired,
};

export default Pages;
