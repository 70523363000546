import React, { PureComponent, Fragment } from "react";
import memoize from "memoize-one";

import UserDetailsView from "./UserDetailsView";
import { getUserDetails } from "../../../../../api"; // TODO
import { DataProvider } from "../../../../../components";

class UserDetails extends PureComponent {
  state = {
    user: null,
    isFetching: false,
  };

  setUser = (user, error) => {
    if (error) {
      return;
    }

    this.setState(() => ({ user }));
  };

  setRefreshDetails = fetchUser => {
    this.refreshDetails = fetchUser;
  };

  setFetching = isFetching => {
    this.setState(() => ({ isFetching }));
  };

  payload = memoize(userId => ({ userId }));

  render() {
    const { user, isFetching } = this.state;
    const payload = this.payload(this.props.user.id);

    return (
      <Fragment>
        <DataProvider
          endpoint={getUserDetails}
          data={payload}
          onDataChanged={this.setUser}
          fetchMethodSetter={this.setRefreshDetails}
          onDataFetching={this.setFetching}
        />

        <UserDetailsView isFetching={isFetching} user={user} refreshUsersList={this.props.refreshUsersList} refreshDetails={this.refreshDetails} />
      </Fragment>
    );
  }
}

export default UserDetails;
