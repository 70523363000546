import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

import Dialog from "./Dialog";

class ButtonWithConfirmationDialog extends Component {
  state = {
    isConfirmationDialogOpen: false,
  };

  showConfirmationDialog = e => {
    if (this.props.stopPropagation) {
      e.stopPropagation();
    }

    this.setState({ isConfirmationDialogOpen: true });
  };

  confirm = () => {
    this.setState({ isConfirmationDialogOpen: false }, this.props.onClick);
  };

  closeConfirmationDialog = () => {
    this.setState({ isConfirmationDialogOpen: false });
  };

  render() {
    const { outline, color, size, style, className, children, dialogTitle, dialogMessage, dialogAcceptButtonCaption, dialogRejectButtonCaption, title, tag } = this.props;
    const { isConfirmationDialogOpen } = this.state;

    return (
      <Fragment>
        <Button
          outline={outline}
          color={color}
          size={size || "sm"}
          style={style}
          className={className}
          title={title}
          tag={tag || "button"}
          onClick={this.showConfirmationDialog}>{children}</Button>

        <Dialog
          isOpen={isConfirmationDialogOpen}
          accept={this.confirm}
          reject={this.closeConfirmationDialog}
          title={dialogTitle || "Potwierdzenie"}
          message={dialogMessage || "Czy na pewno chcesz usunąć przypisanie?"}
          acceptCaption={dialogAcceptButtonCaption || "Tak"}
          rejectCaption={dialogRejectButtonCaption || "Nie"} />
      </Fragment>
    );
  }
}

ButtonWithConfirmationDialog.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  outline: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  dialogTitle: PropTypes.string,
  dialogMessage: PropTypes.string,
  dialogAcceptButtonCaption: PropTypes.string,
  dialogRejectButtonCaption: PropTypes.string,
  title: PropTypes.string,
  stopPropagation: PropTypes.bool,
};

export default ButtonWithConfirmationDialog;
