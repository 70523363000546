import React, { createRef, PureComponent } from "react";
import PropTypes from "prop-types";
import { VariableSizeList as List } from 'react-window';
import memoize from "memoize-one";

import { withLoading } from "../../components";
import SimCard from "./SimCard";
import { ListGroup } from "reactstrap";

const getItemKey = (index, data) => `listsimcard#${data.filteredSimCards[index].id}`;

class SimCardsList extends PureComponent {
  listRef = createRef();

  simCardOnClick = e => {
    e.preventDefault();
    const id = parseInt(e.currentTarget.id.replace("simcard#", ""), 10); // id of selected SIM card

    this.props.selectSimCard(id, true); // single selection
  };

  refreshListCache = () => {
    this.listRef.current?.resetAfterIndex(0);
  };

  createItemData = memoize((filteredSimCards, selectedSimCards) => ({
    filteredSimCards,
    onClick: this.simCardOnClick,
    selectedSimCards
  }));

  render() {
    const { selectedSimCards, filteredSimCards, className, height, width } = this.props;

    const itemData = this.createItemData(filteredSimCards, selectedSimCards);
    const getItemSize = index => 48;

    return (
      <ListGroup flush>
        <List ref={this.listRef} className={className} itemCount={filteredSimCards.length} itemSize={getItemSize}
            height={height} width={width} style={{ overflowY: "auto" }} itemKey={getItemKey} itemData={itemData}>
          {SimCard}
        </List>
      </ListGroup>
    );
  }
}

SimCardsList.propTypes = {
  selectSimCard: PropTypes.func.isRequired,
  filteredSimCards: PropTypes.array,
  selectedSimCards: PropTypes.object,
};

export default withLoading(SimCardsList);
