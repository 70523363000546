import React, { Component } from "react";
import { ListGroupItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { removeRole } from "../../../../../api";
import { withAlert } from "../../../../../components/Alert";
import { ButtonWithConfirmationDialog } from "../../../../../components";

class Role extends Component {
  removeRole = () => {
    const { id } = this.props;
    removeRole({ roleId: id })
      .then(r => {
        if (r.result === 0) {
          this.props.showAlert("Rola usunięta", "success", this.props.refreshRolesList);
        }
        else {
          this.props.showAlert("Wystąpił błąd", "danger");
        }
      })
      .catch(() => {
        this.props.showAlert("Wystąpił błąd", "danger");
      });
  };

  render() {
    const { id, name, selected, onClick, canRemoveRoles, activeUsersCount } = this.props;

    const deleteMessage = activeUsersCount
      ? `Do tej roli przypisanych jest ${activeUsersCount} aktywnych użytkowników. Czy na pewno chcesz usunąć tę rolę?`
      : "Czy na pewno chcesz usunąć tę rolę?";

    return (
      <ListGroupItem
        action
        id={`role#${id}`}
        tag="button"
        active={selected}
        onClick={onClick}>
        <div className="row">
          <div className="col-12 d-flex align-items-center justify-content-between">
            <small style={{ overflow: "auto" }}>{name}</small>

            {canRemoveRoles && (
              <small>
                <ButtonWithConfirmationDialog
                  color="secondary"
                  size="xs"
                  tag="a"
                  title="Usuń rolę"
                  dialogMessage={deleteMessage}
                  onClick={this.removeRole}
                  stopPropagation>
                  <FontAwesomeIcon icon="times" color="white" />
                </ButtonWithConfirmationDialog>
              </small>
            )}
          </div>
        </div>
      </ListGroupItem>
    );
  }
}

export default withAlert(Role);
