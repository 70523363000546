import React, { Component, Fragment, PureComponent } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Col,
  CustomInput,
} from "reactstrap";
import DatePicker from "react-datepicker";
import classNames from "classnames";

import { withAlert } from "../../../../../components/Alert";
import { DictionarySelect } from "../../../../../components";
import { debounce } from "../../../../../utils";
import { addService, getServices } from "../../../../../api";

import "react-datepicker/dist/react-datepicker.css";
import "./AddService.css";

class DateInput extends PureComponent {
  render() {
    return (
      <input
        type="text"
        style={{ padding: "6px 8px", height: "auto", display: "inline-block" }}
        {...this.props}
      />
    );
  }
}

const DateComponent = ({ placeholder, selected, onChange, startDate, endDate, isWarning, disabled, selectsStart = false, selectsEnd = false }) => (
  <DatePicker
    wrapperClassName="service-date-wrapper"
    popperClassName="service-date-popper"
    className={classNames("form-control", { "is-warning": !!isWarning })}
    customInput={<DateInput />}
    locale="pl"
    dateFormat="yyyy-MM-dd"
    selected={selected}
    onChange={onChange}
    selectsStart={selectsStart}
    selectsEnd={selectsEnd}
    placeholderText={placeholder}
    disabled={disabled}
    popperPlacement="left"
    popperModifiers={{
      offset: {
        enabled: true,
        offset: "130px, 10px",
      },
      preventOverflow: {
        enabled: true,
        escapeWithReference: false,
        boundariesElement: "scrollParent",
      },
    }}
  />
);

const dictionaryStyles = {
  menu: (base, state) => ({
    ...base,
    zIndex: 2
  })
};

const getLabelField = option => `${option.name} - ${option.recurring ? `cykl rozl. ${option.months}mc` : "jednorazowy"} - ${option.currency}`;

class AddService extends Component {
  state = {
    modalIsOpen: false,
    service: null,
    start: new Date(),
    end: null,
    allDevices: false,
  };

  toggleModal = callback => {
    const cb = callback && callback.apply ? callback : undefined;

    this.setState(prevState => ({
      modalIsOpen: !prevState.modalIsOpen,
      service: null,
      start: new Date(),
      end: null,
      allDevices: false,
    }), cb);
  };

  addService = () => {
    const {
      service,
      start,
      end,
      allDevices,
    } = this.state;

    addService({
      subscriptionPlanId: service.id,
      deviceId: this.props.deviceId,
      start,
      end,
      allDevices,
    })
      .then(r => {
        if (r.result === 0) {
          this.props.showAlert("Dodano pakiet", "success");
          this.toggleModal(this.props.onSuccess);
        }
        else {
          this.props.showAlert("Błąd dodawania pakietu", "danger");
        }
      })
      .catch(() => {
        this.props.showAlert("Wystąpił błąd", "danger");
      });
  };

  onInput = e => {
    const { name, value } = e.target;

    this.setState(() => ({ [name]: value }));
  };

  onChange = e => {
    const { name, value } = e.target;

    this.setState(() => ({ [name]: value }));
  };

  optionsLoader = debounce((filter, callback) => {
    getServices({
      searchPhrase: filter
    })
      .then(r => r.list)
      .then(callback)
      .catch(this.onError);
  }, 500);

  onError = () => {
    this.props.showAlert("Błąd pobierania pakietów", "danger");
  };

  setService = service => {
    this.setState(prevState => ({ service }));
  };

  changeStartDate = start => {
    this.setState(prevState => ({ start, end: start && start >= prevState.end ? null : prevState.end }));
  };

  changeEndDate = end => {
    this.setState(prevState => ({ start: end && prevState.start >= end ? null : prevState.start, end }));
  };

  onAddToAllDevicesChange = e => {
    const checked = e.target.checked;

    this.setState(() => ({ allDevices: checked }));
  };

  render() {
    const { modalIsOpen, service, start, end, allDevices } = this.state;

    const submitDisabled = !service || !start || (end && !service.recurring);;

    return (
      <Fragment>
        <div className="text-right">
          <Button title="Dodaj pakiet" onClick={this.toggleModal} color="primary">Dodaj pakiet</Button>
        </div>

        <Modal isOpen={modalIsOpen} toggle={this.toggleModal} backdrop="static" autoFocus={false}>
          <ModalHeader toggle={this.toggleModal}>Dodawanie pakietu</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Label sm={3}>Pakiet</Label>
                <Col sm={9}>
                  <DictionarySelect
                    endpoint={this.optionsLoader}
                    onChange={this.setService}
                    value={service}
                    labelField={getLabelField}
                    styles={dictionaryStyles}
                    cache
                    autoFocus
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Od</Label>
                <Col sm={9}>
                  <DateComponent
                    placeholder="data od"
                    selected={start}
                    startDate={start}
                    endDate={end}
                    onChange={this.changeStartDate}
                    selectsStart
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Do</Label>
                <Col sm={9}>
                  <DateComponent
                    placeholder="data do"
                    selected={end}
                    startDate={start}
                    endDate={end}
                    minDate={start}
                    onChange={this.changeEndDate}
                    selectsEnd
                    disabled={service && !service.recurring}
                  />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <CustomInput type="checkbox" id="addToAllDevicesCheckbox" label="Dodaj do wszystkich pojazdów klienta" checked={allDevices} onChange={this.onAddToAllDevicesChange} />
            <Button color="primary" onClick={this.addService} disabled={submitDisabled}>Dodaj</Button>
            <Button color="secondary" onClick={this.toggleModal}>Anuluj</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default withAlert(AddService);
