import React, { Fragment } from "react";
import { ListGroupItem } from "reactstrap";
import classNames from "classnames";

const DeviceName = ({ description, imei }) => (
  <Fragment>
    <small className="col-auto">{description}</small>
    <small className="flex-fill text-right text-muted"><em>{imei}</em></small>
  </Fragment>
);

const Device = ({ id, description, imei, deleted, selected, onClick }) => {
  const deviceName = <DeviceName description={description} imei={imei} />;

  return (
    <ListGroupItem
      action
      id={`device#${id}`}
      className="list-group-item list-group-item-action"
      tag="button"
      active={selected}
      onClick={onClick}
      style={{ height: "44px" }}>
      <div className={classNames("row no-gutters")}>
        {!deleted
          ? deviceName
          : <del className="row no-gutters col-12">{deviceName}</del>
        }
      </div>
    </ListGroupItem>
  );
}

export default Device;
