import React, { Component, Fragment } from "react";
import { FormattedNumber } from "react-intl";

import Service from "./Service";

import "./Services.css";

class ServicesTable extends Component {
  render() {
    const { services, clientsServices, isFetching } = this.props;

    if (isFetching) {
      return <div className="loading-container"></div>;
    }

    if (!services || !clientsServices) {
      return null;
    }

    return (
      <Fragment>
        <table className="table table-striped" style={{ overflowY: "auto", height: "calc(100% - 4rem)", display: "block" }}>
          <thead>
            <tr>
              <th>Klient</th>
              {services.map(x => (
                <th key={`subcol#${x.id}`} className="text-center">{x.name}</th>
              ))}
              <th>Razem</th>
            </tr>
          </thead>
          <tbody>
            {clientsServices.map(client => (
              <tr key={`client#${client.id}`}>
                <td>{client.name}</td>
                {services.map(service => (
                  <Service key={`sub#${service.id}`} id={service.id} service={client.services[service.id]} />
                ))}
                <td>{client.totals.map(totalPart => (
                  <div key={`client#${client.id}_total#${totalPart.currency}`}>
                    <FormattedNumber
                      value={totalPart.amount / 100}
                      // eslint-disable-next-line react/style-prop-object
                      style="currency"
                      currency={totalPart.currency}
                    />
                  </div>
                ))}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Fragment>
    );
  }
}

export default ServicesTable;
