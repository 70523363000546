import React from "react";
import HistoryView from "./HistoryView";
import HistoryComponent from "../../../../../components/actionsHistory";
import { getUserHistory } from "../../../../../api";

const History = ({ user }) => {
  const data = {
    userId: user.id,
  };

  return (
    <HistoryComponent
      endpoint={getUserHistory}
      data={data}
      HistoryView={HistoryView}
    />
  );
};

export default History;
