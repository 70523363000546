import React, { PureComponent, createRef } from "react";
import Measure from "react-measure";
import { debounce } from "../../utils";

import UsersFilter from "./UsersFilter";
import UsersList from "./UsersList";

class UsersContainer extends PureComponent {
  state = {
    dimensions: {
      width: -1,
      height: -1,
    },
  };
  usersListRef = createRef();

  onResize = debounce(contentRect => {
    if (this.state.dimensions.height !== contentRect.bounds.height) { // if height changed, set new dimensions
      this.setState(() => ({ dimensions: contentRect.bounds }));
    }
  });

  render() {
    const { height, width } = this.state.dimensions;
    const { onFilterChanged, refresh, filteredList, selectedElements, selectedGroups, selectElement, selectGroup, isFetching, usersListRef, filterOptions, filterFormatOptionLabel } = this.props;

    return (
      <Measure
        bounds
        onResize={this.onResize}>
        {({ measureRef }) => (
          <aside ref={measureRef}>
            <UsersFilter onChange={onFilterChanged} refresh={refresh} options={filterOptions} formatOptionLabel={filterFormatOptionLabel} />

            <UsersList
              filteredUsers={filteredList}
              selectedUsers={selectedElements}
              selectedGroups={selectedGroups}
              selectUser={selectElement}
              selectGroup={selectGroup}
              isFetching={isFetching}
              refresh={refresh}
              height={height - 46}
              width={width}
              ref={usersListRef}
              />
          </aside>
        )}
      </Measure>
    );
  }
}

export default UsersContainer;
