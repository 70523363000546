import React, { PureComponent } from "react";

import Pages from "./pages";
import { FilteredList } from "../../components";
import { getOperators } from "../../api";
import OperatorsFilter from "./OperatorsFilter";
import OperatorsList from "./OperatorsList";
import { formatUser } from "../../utils";

const compareStrings = (field, phrase) => field && field.toLocaleLowerCase().includes(phrase);

const isOperatorMatchingKeyword = (operator, keyword) => {
  const phrase = keyword.value.toLocaleLowerCase();

  return compareStrings(formatUser(operator), phrase);
};

const filterBasedOnKeywords = (operator, keywords) => {
  for (const keyword of keywords) {
    const isKeywordMatching = isOperatorMatchingKeyword(operator, keyword);
    
    if (!isKeywordMatching) {
      return false;
    }
  }

  return true;
};

const filterOperators = (data, keywords) => {
  if (!keywords || !keywords.length) {
    return data;
  }

  return data.filter(x => filterBasedOnKeywords(x, keywords));
};

const sortOperators = data => {
  return data.sort((a, b) => 
    formatUser(a).localeCompare(formatUser(b)) // sort by name asc
  );
};

class Dashboard extends PureComponent {
  renderPages = (selectedElements, selectedCount, refresh) => {
    return selectedCount > 0
      ? <Pages selectedOperators={selectedElements} selectedCount={selectedCount} refreshOperators={refresh} />
      : <p className="p-3">Wybierz przynajmniej jednego operatora</p>;
  };

  getOperator = (operators, key) => operators.find(operator => operator.id === parseInt(key, 10));

  render() {
    return (
      <FilteredList endpoint={getOperators} keyField="id" itemGetter={this.getOperator} listFilter={filterOperators} listSorter={sortOperators}  listChangedCallback={this.listChangedCallback} keepSelectionsAfterRefresh>
        {({ filteredList, selectedElements, isFetching }, { refresh, onFilterChanged, selectElement, selectedCount }) =>
          <div className="content">
            {/* <DashboardContainer
              onFilterChanged={onFilterChanged}
              refresh={refresh}
              filteredList={filteredList}
              selectedElements={selectedElements}
              isFetching={isFetching}
            /> */}

            <aside>
              <OperatorsFilter onChange={onFilterChanged} refresh={refresh} />

              <OperatorsList filteredOperators={filteredList} selectedOperators={selectedElements} selectOperator={selectElement} isFetching={isFetching} />
            </aside>

            <section>
              {this.renderPages(selectedElements, selectedCount(), refresh)}
            </section>
          </div>
        }
      </FilteredList>
    );
  }
}

export default Dashboard;
