const prepareDateObject = date => {
  date = date && new Date(date);

  if (!date || isNaN(date)) {
    return;
  }

  return date;
};

const getYearString = date => date.getFullYear();

const getMonthString = date => (date.getMonth() + 1).toString().padStart(2, "0");

const getDayString = date => date
  .getDate()
  .toString()
  .padStart(2, "0");

const getHoursString = date => date
  .getHours()
  .toString()
  .padStart(2, "0");

const getMinutesString = date => date
  .getMinutes()
  .toString()
  .padStart(2, "0");

const getSecondsString = date => date
  .getSeconds()
  .toString()
  .padStart(2, "0");

export function formatDate(date, includeTime = true, includeSeconds = false) {
  date = prepareDateObject(date);

  if (!date) {
    return;
  }

  const year = getYearString(date);
  const month = getMonthString(date);
  const day = getDayString(date);
  let str = `${day}.${month}.${year}`;

  if (includeTime) {
    str += " " + formatTime(date, includeSeconds);
  }

  return str;
}

export function formatTime(date, includeSeconds = false) {
  date = prepareDateObject(date);

  if (!date) {
    return;
  }

  const hours = getHoursString(date);
  const minutes = getMinutesString(date);

  let str = `${hours}:${minutes}`;
  if (includeSeconds) {
    const seconds = getSecondsString(date);

    str += `:${seconds}`;
  }

  return str;
}

export function formatDuration(durationSeconds) {
  if (!durationSeconds || isNaN(durationSeconds)) {
    return;
  }

  const secondsInHour = 60 * 60;
  const hours = Math.floor(durationSeconds / secondsInHour);
  const minutes = Math.floor(durationSeconds % secondsInHour / 60);
  const seconds = Math.floor(durationSeconds % secondsInHour % 60);

  return `${hours}h ${minutes}m ${seconds}s`;
}
