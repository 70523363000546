import API, { convertToBLOB, getBLOBHeaders } from "./api";

const api = new API("https://api.sigen.pl/admin");

// auth
export const login = api.login;
export const logout = api.logout;

// dictionaries
export const getDictionaryEntries = api.fetchJSON("/dictionary/entries");

// terminals
export const getTerminals = api.fetchJSON("/terminals/list");
export const getTerminalDetails = api.fetchJSON("/terminals/details");
export const getFirmwareVersions = api.fetchJSON("/terminals/firmwareVersions");
export const updateTerminals = api.fetchJSON("/terminals/update");
export const restartTerminalConnections = api.fetchJSON("/terminals/restart");
export const addAppVersion = api.fetchJSON("/terminals/addVersion");
export const deleteTerminal = api.fetchJSON("/terminals/delete");
export const assignClient = api.fetchJSON("/terminals/assignClient");
export const assignDevice = api.fetchJSON("/terminals/assignDevice");
export const editSerialNumber = api.fetchJSON("/terminals/editSerialNumber");
export const editTeamViewerNumber = api.fetchJSON("/terminals/editTeamViewerNumber");
export const editTerminalNotes = api.fetchJSON("/terminals/editNotes");
export const editPhoneNumber = api.fetchJSON("/terminals/editPhoneNumber");
export const getStatuses = api.fetchJSON("/terminals/statuses");
export const editNeedsLogin = api.fetchJSON("/terminals/editNeedsLogin");

// clients
export const getClientsDictionary = api.fetchJSON("/clients/dictionaryList");
export const addClient = api.fetchJSON("/clients/add");
export const getClientDetails = api.fetchJSON("/clients/details");
export const deleteClient = api.fetchJSON("/clients/delete");
export const editClientName = api.fetchJSON("/clients/editName");
export const getRoles = api.fetchJSON("/clients/roles");
export const reassignClientsDevices = api.fetchJSON("/clients/reassignDevices");
export const getClientsAndUsers = api.fetchJSON("/clients/list");
export const editClientFullName = api.fetchJSON("/clients/editFullName");
export const editClientVatid = api.fetchJSON("/clients/editVatid");
export const editClientAddress = api.fetchJSON("/clients/editAddress");
export const editClientContactPerson = api.fetchJSON("/clients/editContactPerson");
export const editClientContactPersonPhoneNumber = api.fetchJSON("/clients/editContactPersonPhoneNumber");
export const assignSMTPConfig = api.fetchJSON("/clients/assignSMTPConfig");
export const editClientScanReminders = api.fetchJSON("/clients/editScanReminders");
export const editClientAutoAcceptOrders = api.fetchJSON("/clients/editAutoAcceptOrders");
export const editClientNotes = api.fetchJSON("/clients/editNotes");
export const getSMTPConfigsList = api.fetchJSON("/clients/smtpConfigsList");
export const getClientPrices = api.fetchJSON("/clients/prices");
export const saveClientPrices = api.fetchJSON("/clients/savePrices");
export const editClientTerminalNeedsLogin = api.fetchJSON("/clients/editTerminalNeedsLogin");
export const editClientTerminalBeepOffline = api.fetchJSON("/clients/editTerminalBeepOffline");
export const editClientTerminalForceStatusOrder = api.fetchJSON("/clients/editTerminalForceStatusOrder");
export const editClientTerminalParallelActions = api.fetchJSON("/clients/editTerminalParallelActions");
export const editClientTerminalMenuExpenses = api.fetchJSON("/clients/editTerminalMenuExpenses");
export const editClientTerminalMenuMap = api.fetchJSON("/clients/editTerminalMenuMap");
export const editClientTerminalMenuDriverCard = api.fetchJSON("/clients/editTerminalMenuDriverCard");
export const editClientTerminalMenuAlert = api.fetchJSON("/clients/editTerminalMenuAlert");
export const editClientTerminalMenuWhatsApp = api.fetchJSON("/clients/editTerminalMenuWhatsApp");
export const editClientTerminalMenuRouteView = api.fetchJSON("/clients/editTerminalMenuRouteView");
export const editClientTerminalMenuUserNav = api.fetchJSON("/clients/editTerminalMenuUserNav");
export const editClientTerminalFooter = api.fetchJSON("/clients/editTerminalFooter");
export const editClientTerminalAlertEmail = api.fetchJSON("/clients/editTerminalAlertEmail");
export const editDataAvailabilityMonths = api.fetchJSON("/clients/editDataAvailabilityMonths");
export const editDetectClientPoints = api.fetchJSON("/clients/editDetectClientPoints");
export const editClientPointsRadius = api.fetchJSON("/clients/editClientPointsRadius");
export const editTraderVisitDuration = api.fetchJSON("/clients/editTraderVisitDuration");
export const getClientHistory = api.fetchJSON("/clients/history");

// users
export const getUserInfo = api.fetchJSON("/users/userInfo");
export const getUserDetails = api.fetchJSON("/users/details");
export const addUser = api.fetchJSON("/users/add");
export const reassignUsersDevices = api.fetchJSON("/users/reassignDevices");
export const getUsersDevices = api.fetchJSON("/users/devices");
export const editPaymentWarning = api.fetchJSON("/users/editPaymentWarning");
export const editRedirectURL = api.fetchJSON("/users/editRedirectURL");
export const deleteUser = api.fetchJSON("/users/delete");
export const enableUser = api.fetchJSON("/users/enable");
export const disableUser = api.fetchJSON("/users/disable");
export const changeLogin = api.fetchJSON("/users/changeLogin");
export const changePassword = api.fetchJSON("/users/changePassword");
export const getUserHistory = api.fetchJSON("/users/history");
export const editFirstNameAndSurname = api.fetchJSON("/users/editFirstNameAndSurname");
export const getAvailableRoles = api.fetchJSON("/users/availableRoles");
export const userReassignRoles = api.fetchJSON("/users/reassignRoles");

// devices
export const getDevicesUnassignedToTerminals = api.fetchJSON("/devices/unassignedToTerminals");
export const getClientsDevices = api.fetchJSON("/devices/list");
export const getDevicesAvailableToUser = api.fetchJSON("/devices/availableToUser");
export const getDevicesAvailableToClient = api.fetchJSON("/devices/availableToClient");
export const getDevicesAndClients = api.fetchJSON("/devices/list");
export const getDeviceDetails = api.fetchJSON("/devices/details");
export const deleteDevice = api.fetchJSON("/devices/delete");
export const editDeviceMake = api.fetchJSON("/devices/editMake");
export const editDeviceModel = api.fetchJSON("/devices/editModel");
export const editVRN = api.fetchJSON("/devices/editVRN");
export const getDeviceCalibration = api.fetchJSON("/devices/calibration");
export const saveDeviceCalibration = api.fetchJSON("/devices/saveCalibration");
export const getDeviceServices = api.fetchJSON("/devices/services");
export const addService = api.fetchJSON("/devices/addService");
export const endService = api.fetchJSON("/devices/endService");

// roles
export const addRole = api.fetchJSON("/roles/add");
export const removeRole = api.fetchJSON("/roles/remove");
export const roleAvailableUsers = api.fetchJSON("/roles/availableUsers");
export const roleReassignUsers = api.fetchJSON("/roles/reassignUsers");
export const roleAvailablePermissions = api.fetchJSON("/roles/availablePermissions");
export const roleReassignPermissions = api.fetchJSON("/roles/reassignPermissions");

// sim cards
export const getSimCards = api.fetchJSON("/simCards/list");
export const getSimCardDetails = api.fetchJSON("/simCards/details");
export const editSimCardPin = api.fetchJSON("/simCards/editPin");
export const editSimCardNotes = api.fetchJSON("/simCards/editNotes");
export const editSimCardRoamingEnabled = api.fetchJSON("/simCards/editRoamingEnabled");
export const editSimCardPrivateAPN = api.fetchJSON("/simCards/editPrivateAPN");
export const deleteSimCard = api.fetchJSON("/simCards/delete");
export const addSimCard = api.fetchJSON("/simCards/add");
export const getSimAccountsList = api.fetchJSON("/simCards/accountsList");

// dashboard
export const getOperators = api.fetchJSON("/users/operators");
export const getServices = api.fetchJSON("/dashboard/services");
export const getClientsServices = api.fetchJSON("/dashboard/clientsServices");

// account section
export const registerEtollAccount = api.fetchJSON("/account/registerEtoll");
export const checkEtollDevice = api.fetchJSON("/account/checkEtollDevice");
export const getEtollSubscriptionPlans = api.fetchJSON("/account/etollSubscriptionPlans");
export const registerTransaction = api.fetchJSON("/account/registerTransaction");
export const restoreStoreSession = api.fetchJSON("/account/restoreStoreSession");

// map.sigen files
export const getFile = ({ fileId }, signal) => api.fetch(`https://map.sigen.pl/download.aspx?t=file&id=${fileId}&desc=admin`, "GET", convertToBLOB, getBLOBHeaders)(null, signal);