import React from "react";
import { FormattedNumber } from "react-intl";

const Service = ({ id, service }) => {
  if (!service) {
    return <td className="text-center">-</td>;
  }

  return (
    <td>
      <div key={`subc#${id}`} className="service-amount text-center">
        <div><strong>{service.count}</strong></div>
        <FormattedNumber
          value={service.amount / 100}
          // eslint-disable-next-line react/style-prop-object
          style="currency"
          currency={service.currency}
        />
      </div>
    </td>
  );
};

export default Service;
