import React from "react";
import { ListGroupItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import { formatDate } from "../../dateUtils";

const Terminal = ({ id, imei, lastHeartbeat, device, appVersion, selected, onClick }) => {
  const lastHeartbeatDate = lastHeartbeat ? new Date(lastHeartbeat) : "";
  const dateFormatted = lastHeartbeatDate ? formatDate(lastHeartbeatDate, true, true) : "";
  const staleData = lastHeartbeat && (new Date() - lastHeartbeatDate) / 1000 > 3 * 60; // data older than 3 minutes

  return (
    <ListGroupItem
      action
      id={`terminal#${id}`}
      className="list-group-item list-group-item-action"
      tag="button"
      active={selected}
      onClick={onClick}>
      <div className="row no-gutters">
        <small className="col-5">{imei}</small>
        <small className="col-4">{device ? device.description : ""}</small>
        <small className="col-3 text-right">
          <span className="mr-1">{appVersion}</span>
          <FontAwesomeIcon icon="circle" title={dateFormatted} className={classNames({
            "text-danger": !lastHeartbeat,
            "text-warning": lastHeartbeat && staleData,
            "text-success": lastHeartbeat && !staleData
          })} />
        </small>
      </div>
    </ListGroupItem>
  );
}

export default Terminal;
