import React, { PureComponent } from "react";
import Measure from "react-measure";
import { debounce } from "../../utils";

import SimCardsFilter from "./SimCardsFilter";
import SimCardsList from "./SimCardsList";

class SimCardsContainer extends PureComponent {
  state = {
    dimensions: {
      width: -1,
      height: -1,
    },
  };

  onResize = debounce(contentRect => {
    if (this.state.dimensions.height !== contentRect.bounds.height) { // if height changed, set new dimensions
      this.setState(() => ({ dimensions: contentRect.bounds }));
    }
  });

  render() {
    const { height, width } = this.state.dimensions;
    const { onFilterChanged, refresh, filteredList, selectedElements, selectElement, isFetching, simCardsListRef, filterOptions } = this.props;

    return (
      <Measure
        bounds
        onResize={this.onResize}>
        {({ measureRef }) => (
          <aside ref={measureRef}>
            <SimCardsFilter onChange={onFilterChanged} refresh={refresh} options={filterOptions} selectSimCard={selectElement} />

            <SimCardsList
              filteredSimCards={filteredList}
              selectedSimCards={selectedElements}
              selectSimCard={selectElement}
              isFetching={isFetching}
              refresh={refresh}
              height={height - 46}
              width={width}
              ref={simCardsListRef}
              />
          </aside>
        )}
      </Measure>
    );
  }
}

export default SimCardsContainer;
