import React, { PureComponent } from "react";

class BlobImage extends PureComponent {
  componentWillUnmount() {
    URL.revokeObjectURL(this.props.src);
  }

  render() {
    const { alt, src } = this.props;

    return <img {...this.props} alt={alt} src={src} />
  }
}

export default BlobImage;
