import React, { PureComponent } from "react";

import Pages from "./pages";
import TerminalsList from "./TerminalsList";
import TerminalsFilter from "./TerminalsFilter";
import StatusesUpdater from "./StatusesUpdater";
import { FilteredList } from "../../components";
import { getTerminals } from "../../api";

import "./Terminals.css";

const compareStrings = (field, phrase) => {
  return field && field.toLocaleLowerCase().includes(phrase);
};

const isTerminalMatchingKeyword = (terminal, keyword) => {
  const phrase = keyword.value.toLocaleLowerCase();
  const isTerminalMatching = compareStrings(terminal.imei, phrase)
    || compareStrings(terminal.sn, phrase)
    || compareStrings(terminal.appVersion, phrase);

  if (isTerminalMatching) {
    return true;
  }

  if (terminal.client) {
    const isClientMatching = compareStrings(terminal.client.name, phrase);

    if (isClientMatching) {
      return true;
    }
  }

  if (terminal.device) {
    const isDeviceMatching = compareStrings(terminal.device.description, phrase)
      || compareStrings(terminal.device.vrn, phrase)
      || compareStrings(terminal.device.vehicleNumber, phrase);

    if (isDeviceMatching) {
      return true;
    }

    if (terminal.device.client) {
      const isDeviceClientMatching = compareStrings(terminal.device.client.name, phrase);

      if (isDeviceClientMatching) {
        return true;
      }
    }
  }

  return false;
};

const filterBasedOnKeywords = (terminal, keywords) => {
  for (const keyword of keywords) {
    const result = isTerminalMatchingKeyword(terminal, keyword);

    if (!result) {
      return false;
    }
  }

  return true;
};

const filterTerminals = (data, keywords) => {
  if (!keywords || !keywords.length) {
    return data;
  }

  return data.filter(x => filterBasedOnKeywords(x, keywords));
};

const sortTerminals = data => {
  return data.sort((a, b) => {
    return (a.device ? a.device.description || "" : "").localeCompare(b.device ? b.device.description || "" : "", "pl", { numeric: true }) // sort by device description ASC
      || (a.imei || "").localeCompare(b.imei || "", "pl", { numeric: true }); // then sort by imei ASC
  });
};

class Terminals extends PureComponent {
  renderPages = (selectedElements, selectedCount, refresh) => {
    return selectedCount > 0
      ? <Pages selectedTerminals={selectedElements} selectedCount={selectedCount} refreshTerminalsList={refresh} />
      : <p className="p-3">Wybierz przynajmniej jeden terminal</p>;
  };

  getTerminal = (terminals, key) => terminals.find(terminal => terminal.id === parseInt(key, 10));

  render() {
    const {
      match: {
        params: { terminalId },
      },
    } = this.props;

    return (
      <FilteredList multiselect endpoint={getTerminals} keyField="id" itemGetter={this.getTerminal} listFilter={filterTerminals} listSorter={sortTerminals} keepSelectionsAfterRefresh urlSelectKey={terminalId}>
        {({ fullList, filteredList, selectedElements, isFetching }, { refresh, refreshComponents, onFilterChanged, selectElement, selectAll, deselectAll, areAllSelected, selectedCount }) =>
          <div className="content">
            <aside>
              <TerminalsFilter onChange={onFilterChanged} selectAll={selectAll} deselectAll={deselectAll} allSelected={areAllSelected()} refresh={refresh} />

              <TerminalsList filteredTerminals={filteredList} selectedTerminals={selectedElements} selectTerminal={selectElement} isFetching={isFetching} />
            </aside>

            <StatusesUpdater terminals={fullList} onUpdate={refreshComponents} />

            <section>
              {this.renderPages(selectedElements, selectedCount(), refresh)}
            </section>
          </div>
        }
      </FilteredList>
    );
  }
}

export default Terminals;
