import React, { PureComponent } from "react";
import { Button, InputGroup, InputGroupAddon, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import DebouncedMultiInput from "./DebouncedMultiInput";

class Filter extends PureComponent {
  onFilterChanged = keywords => {
    this.props.onChange(keywords);
  };

  render() {
    const { selectAll, deselectAll, allSelected, refresh, addons, options, formatOptionLabel, tooltip, maxWidth } = this.props;

    const link = allSelected ? deselectAll : selectAll;
    const defaultInputMaxWidth = addons ? 261 : 301;

    return (
      <InputGroup className="p-1">
        <InputGroupAddon addonType="prepend">
          {selectAll && deselectAll
            ? <Button title={`${allSelected ? "Odznacz" : "Zaznacz"} wszystko`} onClick={link}><FontAwesomeIcon icon="tasks" /></Button>
            : null
          }
          <Button title="Odśwież" onClick={refresh}><FontAwesomeIcon icon="sync-alt" /></Button>
        </InputGroupAddon>

        <DebouncedMultiInput
          id="asideFilter"
          placeholder="Filtr"
          onChange={this.onFilterChanged}
          maxWidth={maxWidth ?? defaultInputMaxWidth}
          options={options}
          formatOptionLabel={formatOptionLabel}
        />

        {tooltip && (
          <UncontrolledTooltip placement="right" target="asideFilter">
            {tooltip}
          </UncontrolledTooltip>
        )}

        {addons}
      </InputGroup>
    );
  }
}

Filter.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectAll: PropTypes.func,
  deselectAll: PropTypes.func,
  refresh: PropTypes.func.isRequired,
  allSelected: PropTypes.bool,
  addons: PropTypes.element,
};

export default Filter;
