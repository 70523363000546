import React, { Component } from 'react';
import PropTypes from "prop-types";
import CreatableSelect from 'react-select/creatable';

import { debounce } from "../utils";

const components = {
  DropdownIndicator: null,
};

const styles = ({ maxWidth }) => ({
  container: styles => ({
    ...styles,
    flex: "auto",
    maxWidth: maxWidth + "px",
  })
});

const formatCreateLabel = inputValue => inputValue;

const transformKeywords = (options, newKeywords) => {
  const lastElement = newKeywords.length ? newKeywords[newKeywords.length - 1] : null;
  if (lastElement && (options || []).find(x => x.prefix && x.value === lastElement?.value)) { // if the last selected keyword is a prefix in the options array
    return [newKeywords.slice(0, newKeywords.length - 1), lastElement.value]; // remove last element from keywords and set the value as the text in input
  }

  return [newKeywords, ""];
};

class DebouncedMultiInput extends Component {
  state = {
    value: "",
    keywords: [],
  };

  debouncedOnChangeCallback = debounce(value => this.props.onChange(value));

  onKeywordsChanged = newKeywords => {
    const [keywords, value] = transformKeywords(this.props.options, newKeywords || []);

    this.setState({ keywords, value }, () => this.debouncedOnChangeCallback(keywords));
  };

  onInputChange = value => {
    if (value === this.state.value) {
      return;
    }

    this.setState({ value });
  };

  onKeyDown = e => {
    const { value, keywords } = this.state;

    if (!value) {
      return;
    }

    switch (e.key) {
      case "Enter":
      case "Tab": {
        if (!(keywords || []).find(x => x.value === value)) {
          this.setState(prevState => ({
            value: "",
            keywords: [...prevState.keywords, { value, label: value }]
          }),
          () => this.debouncedOnChangeCallback(this.state.keywords));
        }
        e.preventDefault();

        break;
      }
      default:
        break;
    }
  };

  render() {
    const { id, placeholder, maxWidth, options, formatOptionLabel } = this.props;
    const { value, keywords } = this.state;

    return (
      <CreatableSelect
        id={id}
        components={components}
        styles={styles({ maxWidth })}
        inputValue={value}
        placeholder={placeholder}
        isClearable
        isMulti
        closeMenuOnSelect
        defaultMenuIsOpen={false}
        menuIsOpen={options ? undefined : false} // allow showing menu only when there are options
        onChange={this.onKeywordsChanged}
        onInputChange={this.onInputChange}
        onKeyDown={this.onKeyDown}
        value={keywords}
        options={options}
        formatCreateLabel={formatCreateLabel}
        formatOptionLabel={formatOptionLabel}
      />
    );
  }
};

DebouncedMultiInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  maxWidth: PropTypes.number,
};

export default DebouncedMultiInput;
