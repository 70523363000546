import Cookie from "js-cookie";

import HTTPForbiddenError from "./HTTPForbiddenError";
import HTTPUnauthorizedError from "./HTTPUnauthorizedError";

const AUTH_DOMAIN = ".sigen.pl";
export const AUTH_BASE_URL = `https://api${AUTH_DOMAIN}/auth`;

const makeRequest = async (url, method, data, headers, signal) => {
  return await fetch(url, {
    mode: "cors", // TODO: should probably allow only some specific domains to use the api
    method,
    body: method === "POST" ? JSON.stringify(data) : null, // TODO: support params?
    headers,
    signal
  });
};

export const getJSONHeaders = (customHeaders = {}) => new Headers({ // TODO: support and accept other types?
  ...customHeaders,
  "Content-Type": "application/json",
  Accept: "application/json",
});
export const getBLOBHeaders = (customHeaders = {}) => new Headers({
  ...customHeaders,
  contentType: "text/plain",
  Accept: "*/*",
})

export const convertToJSON = async response => await response.json();
export const convertToBLOB = async response => await response.blob();

class API {
  baseURL = null;
  autoRefreshToken = null;
  abortController = null;

  accessToken = null;
  refreshToken = null;

  constructor(baseURL, autoRefreshToken = true, abortController = new global.AbortController()) {
    this.baseURL = baseURL;

    this.autoRefreshToken = autoRefreshToken;
    this.abortController = abortController;

    this.refreshToken = Cookie.get("auth.rt");
  }

  login = async data => {
    const response = await makeRequest(
      `${AUTH_BASE_URL}/login`,
      "POST",
      data,
      getJSONHeaders(),
      this.abortController.signal
    );

    if (!response.ok) {
      throw new HTTPUnauthorizedError("Wrong username or password.");
    }

    const responseJSON = await response.json();
    const { token: { accessToken, refreshToken/*, expiresIn*/ } } = responseJSON;

    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    Cookie.set("auth.rt", refreshToken, { expires: 1, domain: AUTH_DOMAIN });

    return responseJSON;
  };

  logout = async () => {
    const responseJSON = await this.fetch(`${AUTH_BASE_URL}/logout`, "POST", null, false)(null, this.abortController.signal);
    this.accessToken = null;
    this.refreshToken = null;
    Cookie.remove("auth.rt", { domain: AUTH_DOMAIN });

    return responseJSON;
  };

  tryRefreshToken = async signal => {
    try {
      const response = await makeRequest(
        `${AUTH_BASE_URL}/refreshAccessToken`,
        "POST",
        {
          refreshToken: this.refreshToken,
        },
        getJSONHeaders(),
        signal
      );

      const responseJSON = await this.handleResponse(response, convertToJSON, signal);
      const { accessToken } = responseJSON;

      this.accessToken = accessToken;

      return responseJSON;
    }
    catch {
      this.refreshToken = null;
      Cookie.remove("auth.rt", { domain: AUTH_DOMAIN });

      //throw new HTTPUnauthorizedError("Not logged in, refresh token expired or deactivated.");
      if (!global.location.pathname.startsWith("/login")) { // TODO: make the login page a parameter
        // redirect to login page
        global.location.href = `${global.location.origin}/login?redir=${global.location.pathname}`; // TODO: make the redirection or other action
        return;
      }
    };
  };

  handleResponse = async (response, transform, signal) => {
    if (!response.ok) {
      const path = global.location.pathname;
      if (response.status === 401) {
        // either no auth token or it expired
        this.accessToken = null;

        if (this.autoRefreshToken && this.refreshToken) {
          // try to get a new one with the refresh token
          // return await this.tryRefreshToken(signal);
          await this.tryRefreshToken(signal);
          return false;
        }
        else if (!path.startsWith("/login")) { // TODO: make the login page a parameter
          // redirect to login page
          global.location.href = `${global.location.origin}/login?redir=${path}`; // TODO: make the redirection or other action
          return;
        }

        throw new HTTPUnauthorizedError("Not logged in.");
      } else if (response.status === 403) {
        throw new HTTPForbiddenError("Access denied!");
      }
      else {
        throw new Error("Fetch error");
      }
    }

    return transform ? transform(response) : response;
  };

  fetch = (path, method = "POST", transform = null, retry = true, headers = null) => async (data, signal) => {
    const request = async () => {
      const url = !path.startsWith("/") ? path : `${this.baseURL}${path}`;
      const authorizationHeaders = this.accessToken
        ? {
          Authorization: `Bearer ${this.accessToken}`
        }
        : undefined;

      const response = await makeRequest(
        url,
        method,
        data,
        headers && headers.apply ? headers(authorizationHeaders) : getJSONHeaders(authorizationHeaders),
        signal
      );

      return await this.handleResponse(response, transform, signal);
    };

    try {
      const response = await request();

      if (!response) { // retry
        return await request();
      }

      return response;
    }
    catch (e) {
      if (e instanceof HTTPForbiddenError) {
        throw e;
      }
      else if (e instanceof HTTPUnauthorizedError) {
        throw e;
      }
      else if (retry) {
        return await request();
      }
    };
  }

  fetchJSON = (path, method = "POST", retry = true) => async (data, signal) => {
    return await this.fetch(path, method, convertToJSON, retry)(data, signal);
  };
}

export default API;
