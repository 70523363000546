import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

import DictionarySelect from "./DictionarySelect";
import Dialog from "./Dialog";
import { debounce } from "../utils";

class AssignDictionaryItem extends PureComponent {
  state = {
    isConfirmationDialogOpen: false,
  };

  optionsLoader = debounce((filter, callback) => {
    this.props.endpoint({
      searchPhrase: filter
    })
      .then(r => ([{ options: r.list }]))
      .then(callback);
  }, 500);

  tryAssign = () => {
    const { needsConfirmation } = this.props;

    if (needsConfirmation) {
      this.showConfirmationDialog();
    }
    else {
      this.props.assign();
    }
  };

  showConfirmationDialog = () => {
    this.setState({ isConfirmationDialogOpen: true });
  };

  confirm = () => {
    this.setState({ isConfirmationDialogOpen: false }, this.props.assign);
  };

  closeConfirmationDialog = () => {
    this.setState({ isConfirmationDialogOpen: false });
  };

  render() {
    const { selectedItem, onSelectedItemChanged, placeholder, labelField, isButtonDisabled, isMulti, assignButtonColor, containerClassName, selectClassName, cache = true } = this.props;
    const { isConfirmationDialogOpen } = this.state;

    return (
      <Fragment>
        <div className={containerClassName || "form-row"}>
          <div className={selectClassName || "col"}>
            <DictionarySelect
              endpoint={this.optionsLoader}
              onChange={onSelectedItemChanged}
              value={selectedItem}
              placeholder={placeholder}
              labelField={labelField || "name"}
              valueField="id"
              isMulti={!!isMulti}
              autoFocus
              cache={cache}
            />
          </div>
          <div>
            <Button color={assignButtonColor} disabled={!!isButtonDisabled} onClick={this.tryAssign}>Przypisz</Button>
          </div>
        </div>

        <Dialog
          isOpen={isConfirmationDialogOpen}
          accept={this.confirm}
          reject={this.closeConfirmationDialog}
          title="Potwierdzenie"
          message="Czy na pewno chcesz przypisać wybraną opcję?"
          acceptCaption="Tak"
          rejectCaption="Nie" />
      </Fragment>
    );
  }
}

AssignDictionaryItem.propTypes = {
  endpoint: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  assign: PropTypes.func.isRequired,
  selectedItem: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onSelectedItemChanged: PropTypes.func,
  placeholder: PropTypes.string,
  labelField: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  needsConfirmation: PropTypes.bool,
  isButtonDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  assignButtonColor: PropTypes.string,
  cache: PropTypes.bool,
};

export default AssignDictionaryItem;
