import React, { Component, Fragment } from "react";
import {
  Button,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Col
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { addAppVersion } from "../../api";
import { withAlert } from "../../components/Alert";

class AddVersion extends Component {
  state = {
    modalIsOpen: false,
    build: null,
    version: null,
    url: null,
    notes: null,
  };

  toggleModal = () => {
    this.setState(prevState => ({ modalIsOpen: !prevState.modalIsOpen, build: null, version: null, url: null, notes: null, }));
  };

  addVersion = () => {
    const { build, version, url, notes } = this.state;

    addAppVersion({ build: parseInt(build, 10), version, url, notes })
      .then(r => {
        if (r.result === 0) {
          this.props.showAlert("Dodano wersję", "success");
          this.toggleModal();
        }
        else {
          this.props.showAlert("Podana wersja już istnieje", "danger");
        }
      })
      .catch(() => {
        this.props.showAlert("Wystąpił błąd", "danger");
      });
  };

  onInput = e => {
    const { name, value } = e.target;
    this.setState(() => ({ [name]: value }));
  };

  render() {
    const { modalIsOpen } = this.state;

    return (
      <Fragment>
        <InputGroupAddon addonType="append">
          <Button title="Dodaj wersję" onClick={this.toggleModal}><FontAwesomeIcon icon="plus" /></Button>
        </InputGroupAddon>

        <Modal isOpen={modalIsOpen} toggle={this.toggleModal} backdrop="static" autoFocus={false}>
          <ModalHeader toggle={this.toggleModal}>Dodawanie wersji</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Label sm={2}>Build</Label>
                <Col sm={10}>
                  <Input type="number" name="build" id="build" onInput={this.onInput} autoFocus />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={2}>Wersja</Label>
                <Col sm={10}>
                  <Input type="text" name="version" id="version" onInput={this.onInput} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={2}>URL</Label>
                <Col sm={10}>
                  <Input type="text" name="url" id="url" onInput={this.onInput} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={2}>Notes</Label>
                <Col sm={10}>
                  <Input type="textarea" name="notes" id="notes" onInput={this.onInput} />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.addVersion}>Dodaj</Button>
            <Button color="secondary" onClick={this.toggleModal}>Anuluj</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default withAlert(AddVersion);
