import React, { Fragment, PureComponent } from "react";
import memoize from "memoize-one";

import DetailsView from "./DetailsView";
import { getFile, getSimCardDetails } from "../../../../api";
import { DataProvider } from "../../../../components";

class Details extends PureComponent {
  state = {
    simCardDetails: null,
    isFetching: false,
    fileURL: null,
  };

  setDetails = (simCardDetails, error) => {
    if (error) {
      return;
    }

    this.setState(() => ({ simCardDetails, fileURL: null }));
  };

  setRefreshDetails = refreshDetails => {
    this.refreshDetails = refreshDetails;
  };

  setFetching = isFetching => {
    this.setState(() => ({ isFetching }));
  };

  detailsPayload = memoize(simCardId => ({ simCardId }));

  filePayload = memoize(fileId => ({ fileId }));

  setFile = (file, error) => {
    if (error) {
      return;
    }

    this.setState(() => ({ fileURL: URL.createObjectURL(file) }));
  };

  render() {
    const { simCard } = this.props;
    const { isFetching, simCardDetails, fileURL } = this.state;

    const detailsPayload = this.detailsPayload(simCard.id);
    const filePayload = simCardDetails && this.filePayload(simCardDetails.fileId);

    return (
      <Fragment>
        <DataProvider
          endpoint={getSimCardDetails}
          data={detailsPayload}
          onDataChanged={this.setDetails}
          fetchMethodSetter={this.setRefreshDetails}
          onDataFetching={this.setFetching}
        />

        {!!simCardDetails && !!simCardDetails.fileId && (
          <DataProvider
            endpoint={getFile}
            data={filePayload}
            onDataChanged={this.setFile}
          />
        )}

        <DetailsView isFetching={isFetching} simCard={simCardDetails} refreshSimCardsList={this.props.refreshSimCardsList} refreshDetails={this.refreshDetails} fileURL={fileURL} />
      </Fragment>
    );
  }
};

export default Details;
