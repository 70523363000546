import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Nav as RNav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
} from "reactstrap";

import { LocaleContext } from "../../components/LocaleContextProvider";
import { isProduction } from "../../config";
import "./Nav.css";

class Nav extends Component {
  render() {
    const { languageSwitcher } = this.props;

    return (
      <LocaleContext.Consumer>
        {context => (
          <Navbar
            color={isProduction ? "dark" : "dark-dev"}
            dark
            expand="sm"
            className="sticky-top">
            <NavbarBrand href="/">
              Sigen
              {isProduction ? (
                ""
              ) : (
                  <small style={{ fontSize: "70%" }}>dev</small>
                )}
            </NavbarBrand>
            
            {languageSwitcher && (
              <RNav className="ml-auto" navbar>
                <NavItem>
                  <NavLink href="#" onClick={() => context.setLocale("en")} active={context.locale === "en"}>
                    <ImgFlagGB />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#" onClick={() => context.setLocale("pl")} active={context.locale === "pl"}>
                    <ImgFlagPL />
                  </NavLink>
                </NavItem>
              </RNav>
            )}
          </Navbar>
        )}
      </LocaleContext.Consumer>
    );
  }
}

const ImgFlagGB = () => <img alt="🇬🇧" title="English" className="flag-img" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAMAAABiM0N1AAAAgVBMVEVHcEzPGyvPGyvPGyvu7u7kqK3PGyvu7u7u7u7u7u7SLj3u7u7f4efPGysPMYTs4eIAJH3krLHByNnq1NbgkpnQ1eCkr8tKY6BofK53ibbZXWhZcKezvNLVQ1AePYs8V5nXUFzmub3banTfhY3dd4DoxsktSpLTNUOGlr3RKDeVosSByXpFAAAAC3RSTlMAz2CvIO8gv89gryK6YvcAAAHeSURBVHhe7ZfXkqNADEWFBxzmNskxh4lh//8DtwvVLU+XAMPWPrk4r5hD01dGanlcBgZmEwBuW4TAk/4CniJk64DyaSRKPIWyzvqJsjWQv/or0Vg84wTEbfqINg54+Uo9P0ksIlH6DoLvrKso+wbwVl245piKjLzx9AGy+uwm+lwBH6dqOUt4ZvJcSZcg2HUR7QAsf9LbIibC5S1A5ud7ovMcyK+V/Q0KBNywEsRtW0QMvVrO1wuIMMI03ecg66xZxNA9r7/ukOJQ53ebepGGbt/hUIh/4yPfOCyEOlEYOjn6XRWTAVnViYLQg5xFq2KuPwgLwYrqHjjXyhNbp4sWEUMP/gsUKRe7iVYEE8qlKCgimS0EK2Lo9nshaMKKWnlkUfqfGEStDKJBNHyP7ouCj7/CHtAkWuzZ+N0f7UWmHSnsSlZEOCqYdqQNkrC1N4uwYJ/UBhm0bMLWfie1ZaqdO2zZOxCwtTcPEfaBHCLORxC29taxhrxzC6BjzcGZTWwdtEwopQ5aQega693Rj+T72x1i/J2GUfsOYkLvNB7bQpCJyaDjwB4WwrPMgtB7HCHCQhiJTMHQex1qwkKIRCROGHr/YxZJ9MAWMcL+Bz9lGosyeir//SiKyUweloGBv/mkyBCafKWfAAAAAElFTkSuQmCC"/>;
const ImgFlagPL = () => <img alt="🇵🇱" title="Polski" className="flag-img" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAMAAABiM0N1AAAAJ1BMVEVHcEzcFDzu7u7cFDzu7u7cFDzu7u7cFDzu7u7u7u7cFDzu7u7cFDw6k8RwAAAAC3RSTlMAIM9gv78gz2Dv7xoaXVoAAABsSURBVHja7dDBDcAgDENRQwIE0v3nrYq6QcIF+Q/wZBmM3ZupB1L7mV49WO3bmR5uflL1hCpgnpJBcyCFJ0WIECFChM5DT1KECBEiROg81HKcBsmBBBgZzgBQVtxZBZ80wnsKsJMW+lnA2LW9ItGl2eKGQx0AAAAASUVORK5CYII="/>;

export default withRouter(Nav);
