import React, { Component } from "react";
import memoize from "memoize-one";

import { getDevicesAvailableToClient, reassignClientsDevices } from "../../../../../api";
import { FilteredList, DevicesFilter, DevicesPickerTable } from "../../../../../components";
import Auth from "../../../../../Auth";

const filterDevices = (data, filters) => {
  if (!filters) {
    return data;
  }

  const { option } = filters;

  return data.filter(x => {
    switch (option) {
      case "1":
        return !x.clientId;
      case "2":
        return x.selected;
      case "3":
      default:
        return true;
    }
  });
};

const sortDevices = data => {
  return data.sort((a, b) => {
    return (a.description || "").localeCompare(b.description || "", "pl", { numeric: true }); // sort by device description ASC
  });
};

const groupDevices = data => {
  if (!data) {
    return [];
  }

  const devicesGroups = data.reduce((a, x) => {
    let groupId;
    if (!x.group) {
      groupId = 0;
    }
    else {
      groupId = x.group.id;
    }

    if (a[groupId]) {
      a[groupId].push(x);
    }
    else {
      a[groupId] = [x];
    }

    return a;
  }, {});

  return Object.keys(devicesGroups)
    .map(x => {
      return {
        group: x !== "0" ? devicesGroups[x][0].group : { id: 0, name: "Brak grupy" },
        devices: devicesGroups[x],
      };
    })
    .sort((a, b) => {
      if (!a.group.id) {
        return 1;
      }

      if (!b.group.id) {
        return -1;
      }

      return a.group.name.localeCompare(b.group.name);
    });
};

const getChangedDevices = (devicesGroups, selectedDevices) => {
  return devicesGroups.reduce((a, x) => {
    return x.devices.reduce((ac, y) => {
      const selected = !!selectedDevices[y.id];
      if (selected && !y.selected) {
        ac.selected.push(y);
      }
      else if (!selected && y.selected) {
        ac.deselected.push(y);
      }

      return ac;
    }, a);
  }, { selected: [], deselected: [] });
};

class ClientDevices extends Component {
  onListChangedCallback = (filteredList, init, fullList) => {
    if (init) {
      const selectedElements = fullList.reduce((a, x) => {
        if (x.selected) {
          a.push(x.id);
        }

        return a;
      }, []);
  
      if (selectedElements.length) {
        this.selectElements(selectedElements, true);
      }
    }
  };

  memoizeGroupedDevices = memoize(devices => {
    return groupDevices(devices);
  });

  save = (selected, deselected) => () => {
    return reassignClientsDevices({
      clientId: this.props.client.id,
      addIds: selected.map(x => x.id),
      removeIds: deselected.map(x => x.id)
    });
  };

  render() {
    const { client } = this.props;
    const clientId = client.id;
    const _this = this;

    return (
      <Auth.Consumer>
        {({ hasPermission }) => (
          <FilteredList multiselect dependencyId={clientId} endpoint={getDevicesAvailableToClient} data={{ clientId }} keyField="id" listFilter={filterDevices} listSorter={sortDevices} listChangedCallback={this.onListChangedCallback}>
            {({ fullList, filteredList, selectedElements, isFetching, filterPhrase: { option } }, { refresh, refreshComponents, onFilterChanged, selectElement, selectAll, deselectAll, areAllSelected, selectedCount, selectElements }) => {
              _this.selectElements = selectElements;
              const devicesGroups = this.memoizeGroupedDevices(filteredList);
              const { selected, deselected } = getChangedDevices(devicesGroups, selectedElements);
              const isDirty = !!(selected.length || deselected.length);
              const canAssignDevicesToClients = hasPermission("managingClientsDevices");
              const canAccessDeveloperSettings = hasPermission("accessToDeveloperSettings");
              const canAccessSimCards = hasPermission("managingSimCards");

              return (
                <div style={{ overflowY: "hidden", margin: "-1.25rem", height: "calc(100% - 1.5rem)" }}>
                  <div style={{ margin: "1.25rem", height: "calc(100% - 2.5rem)" }}>
                    <DevicesFilter defaultValue="2" unassignedEntries assignedEntries allEntries option={option} onChange={onFilterChanged} canAssignDevices={canAssignDevicesToClients} />

                    <DevicesPickerTable
                      isFetching={isFetching} devices={fullList} devicesGroups={devicesGroups} refreshDevicesList={refresh}
                      selectedDevices={selectedElements} selectElement={selectElement} selectElements={selectElements} selected={selected}
                      deselected={deselected} isDirty={isDirty} canAssignDevices={canAssignDevicesToClients} canAccessDeveloperSettings={canAccessDeveloperSettings}
                      selectAll={selectAll} deselectAll={deselectAll} areAllSelected={areAllSelected} save={this.save(selected, deselected)}
                      canAccessSimCards={canAccessSimCards}
                    />
                  </div>
                </div>
              );
            }}
          </FilteredList>
        )}
      </Auth.Consumer>
    );
  }
}

export default ClientDevices;
