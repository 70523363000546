import React from "react";

import HistoryView from "./HistoryView";
import HistoryComponent from "../../../../../components/actionsHistory";
import { getClientHistory } from "../../../../../api";

const History = ({ client }) => {
  const data = {
    clientId: client.id,
  };

  return (
    <HistoryComponent
      endpoint={getClientHistory}
      data={data}
      HistoryView={HistoryView}
    />
  );
};

export default History;
