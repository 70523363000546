import React, { PureComponent } from "react";
import { Input } from "reactstrap";
import { injectIntl, FormattedNumber } from "react-intl";
import TextareaAutosize from "react-textarea-autosize";

import { saveClientPrices } from "../../../../../api";
import { SaveCancelBar } from "../../../../../components";
import { withAlert } from "../../../../../components/Alert";

const initializeStatePrices = (services, clientPrices) => 
  (services || []).reduce((a, service) => {
    const amount = clientPrices?.[service.id]?.amount;
    a[service.id] = amount !== undefined && amount !== null ? parseFloat(amount / 100).toFixed(2) : undefined;
    
    return a;
  }, {});

const initializeStateNotes = (services, clientPrices) =>
  (services || []).reduce((a, service) => {
    a[service.id] = clientPrices?.[service.id]?.notes ?? undefined;

    return a;
  }, {});

class PriceListView extends PureComponent {
  state = {
    newPrices: initializeStatePrices(this.props.services, this.props.clientPrices),
    newNotes: initializeStateNotes(this.props.services, this.props.clientPrices),
  };

  componentDidUpdate(prevProps) {
    if (prevProps.clientPrices !== this.props.clientPrices) {
      this.resetState();
    }
  }

  resetState = () => {
    this.setState(() => ({
      newPrices: initializeStatePrices(this.props.services, this.props.clientPrices),
      newNotes: initializeStateNotes(this.props.services, this.props.clientPrices)
    }));
  }

  setAmount = (name, value, valueAsNumber) => {
    this.setState(prevState => ({ newPrices: {...prevState.newPrices, [name]: isNaN(valueAsNumber) ? undefined : value } }));
  };

  onAmounChanged = e => {
    const { name, value, valueAsNumber } = e.target;

    this.setAmount(name, value, valueAsNumber)
  };

  onNotesChanged = e => {
    const { name, value } = e.target;

    this.setState(prevState => ({ newNotes: {...prevState.newNotes, [name]: !value || !value.trim() ? undefined : value }}));
  };

  getChangedPrices = () => {
    const { clientPrices } = this.props;
    const { newPrices } = this.state;

    return Object.entries(newPrices).filter(([planId, amount]) => {
      const newPrice = newPrices[planId] !== null && newPrices[planId] !== undefined
        ? parseFloat(newPrices[planId]) * 100
        : undefined;

      return (!clientPrices[planId] && newPrice) || (clientPrices[planId] && (clientPrices[planId].amount ?? undefined) !== newPrice); // TODO: check if works
    });
  }

  getChangedNotes = () => {
    const { clientPrices } = this.props;
    const { newNotes } = this.state;

    return Object.entries(newNotes).filter(([planId, notes]) => {
      const newNote = newNotes[planId];

      return (!clientPrices[planId] && newNote) || (clientPrices[planId] && (clientPrices[planId].notes ?? undefined) !== newNote);
    });
  };

  didPricesChange = () =>  !!this.getChangedPrices().length;

  didNotesChange = () => !!this.getChangedNotes().length;

  save = () => {
    const changedPrices = this.getChangedPrices().reduce((a, [planId, amount]) => {
      a[planId] = amount ? Math.floor(parseFloat(amount) * 100) : undefined;

      return a;
    }, {});

    const changedNotes = this.getChangedNotes().reduce((a, [planId, notes]) => {
      a[planId] = notes;

      return a;
    }, {});

    const changedPlans = this.props.services.filter(x => x.id in changedPrices || x.id in changedNotes)
      .map(x => ({
        subscriptionPlanId: x.id,
        amount: x.id in changedPrices ? changedPrices[x.id] : this.state.newPrices[x.id],
        notes: x.id in changedNotes ? changedNotes[x.id] : this.state.newNotes[x.id],
      }));

    saveClientPrices({ clientId: this.props.client.id, prices: changedPlans })
      .then(r => {
        if (r.result === 0) {
          this.props.showAlert("Ceny zostały zaktualizowane", "success");
          this.setState(() => ({ newPrices: {}, newNotes: {} }), this.props.refreshClientPrices);
        }
        else {
          this.props.showAlert("Wystąpił błąd", "danger");
        }
      })
      .catch(() => {
        this.props.showAlert("Wystąpił błąd", "danger");
      });
  };

  onAmountBlur = e => {
    const { name, valueAsNumber } = e.target;

    const value = parseFloat(e.target.value).toFixed(2);
    this.setAmount(name, value, valueAsNumber);
  };

  render() {
    const { services, isFetching, clientPrices, intl } = this.props;
    const { newPrices, newNotes } = this.state;

    
    if (isFetching || !services || !clientPrices) {
      return <div className="loading-container"></div>;
    }
    
    const didTableChange = this.didPricesChange() || this.didNotesChange();

    return (
      <div style={{ overflowY: "scroll", margin: "-1.25rem", height: "calc(100% - 1.5rem)" }}>
        <div style={{ margin: "1.25rem" }}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Id</th>
                <th>Nazwa</th>
                <th>Cykliczny</th>
                <th>Waluta</th>
                <th>Domyślna kwota</th>
                <th>Kwota</th>
                <th>Notatki</th>
              </tr>
            </thead>
            <tbody>
              {services.map(x => (
                <tr key={x.id}>
                  <td className="valign-middle">{x.id}</td>
                  <td className="valign-middle">{x.name}</td>
                  <td className="valign-middle">{x.recurring ? "tak" : "nie"}</td>
                  <td className="valign-middle">{x.currency}</td>
                  <td className="valign-middle text-right">
                    <FormattedNumber
                      // eslint-disable-next-line react/style-prop-object
                      style="decimal"
                      minimumFractionDigits={2}
                      value={x.amount / 100}
                    />
                  </td>
                  <td className="valign-middle">
                    <Input
                      bsSize="sm"
                      type="number"
                      name={x.id}
                      placeholder={`domyślna kwota: ${intl.formatNumber(x.amount / 100, { style: "decimal", minimumFractionDigits: 2 })}`}
                      step={0.01}
                      value={newPrices[x.id] !== undefined
                        ? newPrices[x.id]
                        : ""}
                      onChange={this.onAmounChanged}
                      onBlur={this.onAmountBlur}
                    />
                  </td>
                  <td className="valign-middle">
                    <TextareaAutosize
                      className="form-control-sm form-control"
                      minRows={1}
                      name={x.id}
                      value={newNotes[x.id] || ""}
                      onChange={this.onNotesChanged}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <SaveCancelBar isDirty={didTableChange} cancel={this.resetState} save={this.save} />
        </div>
      </div>
    )
  }
}

export default injectIntl(withAlert(PriceListView));
