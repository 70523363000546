import React, { Component } from "react";
import { Button, InputGroup, InputGroupAddon } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import { getSMTPConfigsList, assignSMTPConfig } from "../../../../../api";
import { withAlert } from "../../../../../components/Alert";
import { DictionarySelect } from "../../../../../components";
import { debounce } from "../../../../../utils";

const styles = {
  container: styles => ({
    ...styles,
    flex: "auto",
    zIndex: 2
  }),
};

class SMTPConfig extends Component {
  state = {
    isEditing: false,
    newValue: null
  };

  optionsLoader = debounce((filter, callback) => {
    getSMTPConfigsList({
      searchPhrase: filter
    })
      .then(r => r.list)
      .then(callback)
      .catch(this.onError);
  }, 500);

  formatLabel = () => {
    const option = this.props.client.smtpConfig;

    return option?.name;
  };

  setValue = newValue => {
    this.setState(() => ({ newValue }));
  };

  startEditing = () => {
    const newValue = this.props.client.smtpConfig;

    this.setState(() => ({ newValue, isEditing: true }));
  };
  
  save = () => {
    assignSMTPConfig({
      clientId: this.props.client.id,
      smtpConfig: this.state.newValue,
    })
    .then(this.onSuccess)
    .catch(this.onError);
  };

  onSuccess = r => {
    if (r.result === 0) {
      this.setState(() => ({ isEditing: false }), () => {
        this.props.showAlert("Zmiany zostały zapisane.", "success", this.props.onSMTPConfigAssigned);
      });
    }
    else {
      this.onError();
    }
  };

  onError = () => {
    this.props.showAlert("Wystąpił błąd.", "danger");
  };

  cancel = () => {
    this.setState(() => ({ isEditing: false }));
  };
  
  onKeyDown = e => {
    if (e.key === "Escape") {
      this.cancel();
    }
    else if (e.key === "Enter") {
      const { newValue } = this.state;
      const label = this.formatLabel();

      if (newValue && newValue.name !== label) {
        this.save();
      }
    }
  };
  
  render() {
    const { newValue, isEditing } = this.state;
    const label = this.formatLabel();

    return (
      <div>
        {!isEditing && (
          <div>
            <div className="d-inline-block" style={{ width: "calc(1.125em + 0.5rem)" }}>
              {this.props.editable && (
                <FontAwesomeIcon
                  title="Edytuj"
                  icon="edit"
                  className={classNames("clickable text-primary mr-2")}
                  onClick={this.startEditing} />
              )}
            </div>

            <span>{label || <>&nbsp;</>}</span>
          </div>
        )}

        {isEditing && (
          <form>
            <InputGroup size="sm">
              <DictionarySelect
                key={this.props.client.id}
                endpoint={this.optionsLoader}
                onChange={this.setValue}
                value={newValue}
                styles={styles}
                valueField="id"
                placeholder="Wybierz konfigurację"
                onKeyDown={this.onKeyDown}
                autoFocus
                cache
              />

              <InputGroupAddon addonType="append">
                <Button size="sm" title="Zapisz" onClick={this.save} disabled={!newValue || newValue.name === label}><FontAwesomeIcon icon="check" /></Button>
                <Button size="sm" title="Anuluj" onClick={this.cancel}><FontAwesomeIcon icon="times" /></Button>
              </InputGroupAddon>
            </InputGroup>
          </form>
        )}
      </div>
    );
  }
}

export default withAlert(SMTPConfig);
