import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";

import TerminalsClient from "./TerminalsClient";
import { withLoading } from "../../components";

const groupTerminals = data => {
  if (!data) {
    return [];
  }

  const terminalsClients = data.reduce((a, x) => {
    let clientId;
    if (!x.client) {
      clientId = 0;
    }
    else {
      clientId = x.client.id;
    }

    if (a[clientId]) {
      a[clientId].push(x);
    }
    else {
      a[clientId] = [x];
    }

    return a;
  }, {});

  return Object.keys(terminalsClients)
    .map(x => {
      return {
        client: x !== "0" ? terminalsClients[x][0].client : { id: 0, name: "Nieprzypisane" },
        terminals: terminalsClients[x],
      };
    })
    .sort((a, b) => {
      if (!a.client.id) {
        return 1;
      }

      if (!b.client.id) {
        return -1;
      }

      return a.client.name.localeCompare(b.client.name);
    });
};

class TerminalsList extends PureComponent {
  terminalOnClick = e => {
    e.preventDefault();
    const id = parseInt(e.currentTarget.id.replace("terminal#", ""), 10); // id of selected terminal

    if (e.ctrlKey) {
      e.ctrlKey = false;

      this.props.selectTerminal(id); // multiple selection
      return;
    }

    this.props.selectTerminal(id, true); // single selection
  };

  memoizeGroupedTerminals = memoize(terminals => {
    return groupTerminals(terminals);
  });

  render() {
    const { selectedTerminals, filteredTerminals, className } = this.props;
    const terminalsClients = this.memoizeGroupedTerminals(filteredTerminals);

    return (
      <div className={className} style={{ overflowY: "auto" }}>
        {terminalsClients && terminalsClients.map(terminalsClient => (
          <TerminalsClient key={terminalsClient.client.id} client={terminalsClient.client} terminals={terminalsClient.terminals} terminalOnClick={this.terminalOnClick} selectedTerminals={selectedTerminals} />
        ))}
      </div>
    );
  }
}

TerminalsList.propTypes = {
  selectTerminal: PropTypes.func.isRequired,
  filteredTerminals: PropTypes.array,
  selectedTerminals: PropTypes.object,
};

export default withLoading(TerminalsList);
