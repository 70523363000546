import { Component } from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";

import { withAlert } from "./Alert";

class FilteredList extends Component {
  state = {
    fullList: [],
    filteredList: [],
    filterPhrase: "",
    selectedElements: {},
    selectedGroups: {},
    isFetching: false,
  };
  abortController = new global.AbortController();

  componentDidMount() {
    const callback = () => {
      if (this.props.urlSelectKey) {
        this.selectElement(parseInt(this.props.urlSelectKey, 10), true);
      }
    };

    this.fetchList(callback);
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dependencyId !== this.props.dependencyId) {
      this.fetchList();
    }
  }

  listChangedCallback = (init = false) => {
    this.props.listChangedCallback?.(this.state.filteredList, init, this.state.fullList);
  };

  fetchList = callback => {
    if (this.props.endpoint) {
      this.setState({ isFetching: true });
      this.props.endpoint(this.props.data, this.abortController.signal)
        .then(r => {
          if (r.result === 0) {
            const fullList = this.props.listSorter ? this.props.listSorter(r.list) : r.list;
            const filteredList = this.getFilteredList(fullList, this.state.filterPhrase);

            let selectedElements = {};
            let selectedGroups = {};
            if (this.props.keepSelectionsAfterRefresh) {
              if (Object.keys(this.state.selectedElements).length) {
                selectedElements = Object.keys(this.state.selectedElements).reduce((a, key) => {
                  const item = this.props.itemGetter?.(fullList, key) ?? fullList.find(x => x[this.props.keyField] === key);

                  if (item) {
                    a[item[this.props.keyField]] = item;
                  }

                  return a;
                }, {});
              }

              const selectedGroupsKeys = Object.keys(this.state.selectedGroups).map(x => parseInt(x, 10));
              if (selectedGroupsKeys.length) {
                selectedGroups = fullList.reduce((a, x) => {
                  const groupKey = this.props.groupGetter(x);
  
                  if (selectedGroupsKeys.includes(groupKey)) {
                    a[groupKey] = x;
                  }
  
                  return a;
                }, {});
              }
            }

            this.setState(() => ({ fullList, filteredList, selectedElements, selectedGroups, isFetching: false }),
              () => {
                this.listChangedCallback(true);
                callback?.();
              });
          }
        })
        .catch(e => {
          if (e instanceof DOMException && e.code === DOMException.ABORT_ERR) { // request aborted
            return;
          }

          this.setState({ isFetching: false }, () => this.props.showAlert("Nie udało się pobrać listy", "danger"));
        });
    }
  };

  refresh = callback => {
    this.fetchList(callback);
  };

  refreshComponents = () => {
    this.setState(prevState => ({ filteredList: [...prevState.filteredList] }));
  };

  getFilteredList = memoize((fullList, filterPhrase) => {
    return this.props.listFilter ? this.props.listFilter(fullList, filterPhrase) : fullList;
  });

  onFilterChanged = filterPhrase => {
    const filteredList = this.getFilteredList(this.state.fullList, filterPhrase);

    this.setState(() => ({ filteredList, filterPhrase }), this.listChangedCallback);
  };

  selectElement = (key, singleSelection) => {
    if (!key) {
      console.error("Cannot select - Invalid key!");
      return;
    }

    const { multiselect } = this.props;
    if (!singleSelection && !multiselect) {
      console.warn("Multiselection is disabled");
    }

    if (multiselect && !singleSelection && this.state.selectedElements[key]) { // was previously selected, so deselect it
      this.setState(prevState => {
        const selectedElements = { ...prevState.selectedElements };
        delete selectedElements[key];

        return { selectedElements };
      });

      return;
    }

    this.setState((prevState, prevProps) => {
      const otherSelectedElements = singleSelection || !multiselect ? {} : prevState.selectedElements;
      const selectedElement = this.props.itemGetter?.(prevState.filteredList, key) ?? prevState.filteredList.find(x => x[prevProps.keyField] === key);

      return {
        selectedElements: {
          ...otherSelectedElements,
          [key]: selectedElement,
        },
        selectedGroups: {}
      };
    });
  };

  selectElements = (keys, selected, callback) => {
    if (!keys) {
      console.error("Cannot select - Invalid keys!");
      return;
    }

    const { multiselect } = this.props;
    if (!multiselect) {
      console.warn("Multiselection is disabled");
      return;
    }

    this.setState((prevState, prevProps) => {
      const selectedElements = {};

      if (selected) {
        const hasKey = element => element[prevProps.keyField] === key;

        for (var key of keys) {
          const selectedElement = this.props.itemGetter?.(prevState.fullList, key) ?? prevState.fullList.find(hasKey);
          selectedElements[key] = selectedElement;
        }
      }

      return {
        selectedElements
      };
    }, () => callback && callback());
  };

  selectGroup = (key, singleSelection) => {
    if (!key) {
      console.error("Cannot select group - Invalid key!");
      return;
    }

    const { multiselect, groupMultiselect } = this.props;
    if (!singleSelection && !groupMultiselect) {
      console.warn("Multiselection is disabled");
    }

    if (multiselect && !singleSelection && this.state.selectedGroups[key]) { // was previously selected, so deselect it
      this.setState((prevState, prevProps) => {
        const { groupGetter } = prevProps;
        const selectedGroups = { ...prevState.selectedGroups };
        delete selectedGroups[key];

        const selectedElements = { ...prevState.selectedElements };
        for (let elementKey of Object.keys(selectedElements)) {
          if (groupGetter(selectedElements[elementKey]) === key) {
            delete selectedElements[elementKey];
          }
        }

        return { selectedElements };
      });

      return;
    }

    this.setState((prevState, prevProps) => {
      const { groupGetter } = prevProps;

      const otherSelectedGroups = singleSelection || !groupMultiselect ? {} : prevState.selectedGroups;
      const selectedGroups = {
        ...otherSelectedGroups,
        [key]: prevState.filteredList.find(x => groupGetter(x) === key)
      };

      const selectedGroupsKeys = Object.keys(selectedGroups);
      const selectedElements = {};
      for (let element of prevState.fullList) {
        if (selectedGroupsKeys.includes(groupGetter(element))) {
          if (this.props.groupItems) {
            for (let item of this.props.groupItems(element)) {
              selectedElements[item[prevProps.keyField]] = item;
            }
          }
          else {
            selectedElements[element[prevProps.keyField]] = element;
          }
        }
      }

      return {
        selectedElements,
        selectedGroups
      };
    });
  };

  selectAll = () => {
    this.setState((prevState, prevProps) => ({
      selectedElements: prevState.filteredList.reduce((a, x) => {
        a[x[prevProps.keyField]] = x;
        return a;
      }, {})
    }));
  };

  deselectAll = () => {
    this.setState(() => ({ selectedElements: {} }));
  };

  areAllSelected = () => { // will return incorrect value when items were selected before filtering (remove selections when filtering?)
    return this.state.filteredList.length === Object.keys(this.state.selectedElements).length;
  };

  selectedCount = () => {
    return Object.values(this.state.selectedElements).filter(x => x).length;
  };

  areAnySelected = () => {
    return this.selectedCount() > 0;
  };

  render() {
    const {
      refresh,
      refreshComponents,
      onFilterChanged,
      selectElement,
      selectElements,
      selectGroup,
      selectAll,
      deselectAll,
      areAllSelected,
      selectedCount,
      areAnySelected
    } = this; // get callbacks

    return this.props.children(this.state, {
      refresh,
      refreshComponents,
      onFilterChanged,
      selectElement,
      selectElements,
      selectGroup,
      selectAll,
      deselectAll,
      areAllSelected,
      selectedCount,
      areAnySelected
    });
  }
}

FilteredList.propTypes = {
  children: PropTypes.func.isRequired,
  endpoint: PropTypes.func.isRequired,
  data: PropTypes.object,
  listFilter: PropTypes.func,
  listSorter: PropTypes.func,
  keyField: PropTypes.string,
  multiselect: PropTypes.bool,
  itemGetter: PropTypes.func,
  groupGetter: PropTypes.func,
  groupItems: PropTypes.func,
  dependencyId: PropTypes.number,
  listChangedCallback: PropTypes.func,
  keepSelectionsAfterRefresh: PropTypes.bool,
};

export default withAlert(FilteredList);
