import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Filter } from "../../../../../components";
import AddRole from "./AddRole";
import Auth from "../../../../../Auth";

class RolesFilter extends PureComponent {
  refresh = () => this.props.refresh();

  render() {
    return (
      <Auth.Consumer>
        {({ hasPermission, user }) => (
          <Filter
            refresh={this.refresh}
            onChange={this.props.onChange}
            addons={
              hasPermission("addingRoles")
                ? <AddRole clientId={this.props.clientId} user={user} onSuccess={this.props.onClientAdded} />
                : undefined
            } />
        )}
      </Auth.Consumer>
    );
  }
}

RolesFilter.propTypes = {
  clientId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  onClientAdded: PropTypes.func.isRequired,
};

export default RolesFilter;
