import { Component, createContext } from "react";
import { IntlProvider } from "react-intl";

const DEFAULT_LOCALE = global.location.search.match(/lang=(en|pl)/)?.[1] // try to get lang from query string
  || global.navigator.language.startsWith("pl") // get locale from browser
  ? "pl"
  : "en";

function loadLocaleData(locale) {
  switch (locale) {
    case "pl":
      return import("../compiled-lang/pl.json")
    default:
      return import("../compiled-lang/en.json")
  }
}

export const LocaleContext = createContext(DEFAULT_LOCALE);

export class LocaleContextProvider extends Component {
  state = {
    defaultLocale: DEFAULT_LOCALE,
    locale: DEFAULT_LOCALE,
    messages: []
  }

  componentDidMount() {
    this.setLocale(this.state.locale);
  }

  setLocale = locale => {
    loadLocaleData(locale)
      .then(messages => {
        this.setState(() => ({ locale, messages: messages.default }));
      });
  };

  render() {
    const { locale, messages } = this.state;

    return (
      <LocaleContext.Provider value={{ locale, setLocale: this.setLocale }}>
        <IntlProvider
            locale={locale}
            defaultLocale={DEFAULT_LOCALE}
            messages={messages}>
          {this.props.children}
        </IntlProvider>
      </LocaleContext.Provider>
    );
  };
}
