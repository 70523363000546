import React, { PureComponent } from "react";

import { LabelValue, LabelValueCheckbox, withLoading, ButtonWithConfirmationDialog } from "../../../../../components";
import {
  deleteClient,
  editClientName,
  editClientFullName,
  editClientVatid,
  editClientAddress,
  editClientContactPerson,
  editClientContactPersonPhoneNumber,
  assignSMTPConfig,
  editClientScanReminders,
  editClientAutoAcceptOrders,
  editClientNotes,
  editClientTerminalNeedsLogin,
  editClientTerminalBeepOffline,
  editClientTerminalForceStatusOrder,
  editClientTerminalParallelActions,
  editClientTerminalMenuExpenses,
  editClientTerminalMenuMap,
  editClientTerminalMenuDriverCard,
  editClientTerminalMenuAlert,
  editClientTerminalMenuWhatsApp,
  editClientTerminalMenuRouteView,
  editClientTerminalMenuUserNav,
  editClientTerminalFooter,
  editClientTerminalAlertEmail,
  editDataAvailabilityMonths,
  editDetectClientPoints,
  editClientPointsRadius,
  editTraderVisitDuration,
} from "../../../../../api";
import { withAlert } from "../../../../../components/Alert";
import Auth from "../../../../../Auth";
import SMTPConfig from "./SMTPConfig";
import isVAT from "../../../../../isVAT";

const parseAndValidateNumber = (value, minValue, maxValue) => {
  if (!value) return [ null, false ];

  let valueAsNumber = parseInt(value, 10);
  if (isNaN(valueAsNumber)) return [ null, false ];

  if (minValue !== undefined && minValue !== null && valueAsNumber < minValue) return [ null, false ];

  if (maxValue !== undefined && maxValue !== null && valueAsNumber > maxValue) return [ null, false ];

  return [ valueAsNumber, true ];
};

const TERMINAL_NEEDS_LOGIN_DESCRIPTION = "Terminal wyświetli okno logowania dla kierowców. Mogą się logować kierowcy, którzy mają podane login i hasło w ewidencji kierowców. Dotyczy pojazdów bez tachografu.";
const TERMINAL_BEEP_OFFLINE_DESCRIPTION = "Terminal będzie generował co 1 minutę sygnał dźwiękowy jeśli tablet nie ma połączenia z serwerem.";
const TERMINAL_FOOTER_DESCRIPTION = "Treść napisu na dole głównego ekranu aplikacji.";
const TERMINAL_ALERT_EMAIL_DESCRIPTION = "Adres mailowy, na który będzie przychodziła standardowa wiadomość o zgłoszeniu awarii przez kierowcę (wymaga flagi terminal_menu_alert). W przypadku konieczności wielu adresów, należy to rozwiązać po stronie serwera pocztowego.";
const TERMINAL_FORCE_STATUS_ORDER_DESCRIPTION = "Wymusza ustaloną kolejność zmiany statusów. Nie można wybrać poprzedniego ani tego samego statusu ani pomijać statusów.";
const TERMINAL_MENU_EXPENSES_DESCRIPTION = "Wyświetla w głównym menu pozycję Wydatki.";
const TERMINAL_MENU_MAP_DESCRIPTION = "Wyświetla w głównym menu pozycję Mapa.";
const TERMINAL_MENU_DRIVERCARD_DESCRIPTION = "Wyświetla w głównym menu pozycję Karta kierowcy.";
const TERMINAL_MENU_ALERT_DESCRIPTION = "Wyświetla w głównym menu pozycję Zgłoszenie serwisowe.";
const TERMINAL_MENU_WHATSAPP_DESCRIPTION = "Wyświetla w głównym menu pozycję WhatsApp.";
const TERMINAL_MENU_ROUTE_VIEW_DESCRIPTION = "Wyświetla w okienku nawigacyjnym pozycję Podgląd trasy.";
const TERMINAL_MENU_USERNAV_DESCRIPTION = "Wyświetla w okienku nawigacyjnym pozycję Trasa własna.";
const TERMINAL_PARALLEL_ACTIONS_DESCRIPTION = "Pozwala na zmianę statusów jednocześnie w wielu zadaniach zamiast tylko w jednym chronologiczne najstarszym.";

class ClientDetailsView extends PureComponent {
  showError = () => {
    this.props.showAlert("Wystąpił błąd.", "danger");
  };

  isVAT = vatid => {
    try {
      return isVAT(vatid, null, true);
    }
    catch {
      return null;
    }
  }

  deleteClient = () => {
    const { clientDetails } = this.props;

    deleteClient({
      clientId: clientDetails.id,
    })
      .then(r => {
        if (r.result === 0) {
          this.refresh();
        }
      })
      .catch(() => { });
  };

  saveClientName = name => {
    this.saveAndHandle(editClientName, { name });
  };

  saveFullName = fullName => {
    this.saveAndHandle(editClientFullName, { fullName });
  };
  
  saveVatid = vatid => {
    if (!this.isVAT(vatid)) {
      this.props.showAlert("NIP musi być podany", "danger");
      return false;
    }

    return this.save(editClientVatid, { vatid })
      .then(r => {
        if (r.result === 0) {
          this.props.showAlert("Zmiany zostały zapisane.", "success", this.refresh);
          return true;
        }
        else if (r.result === 1) {
          this.props.showAlert("Klient z takim numerem NIP już istnieje", "danger");
        }
        else {
          this.showError();
        }

        return false;
      })
      .catch(() => {
        this.showError();
        return false;
      });
  };

  saveAddress = address => {
    this.saveAndHandle(editClientAddress, { address });
  };

  saveContactPerson = contactPerson => {
    this.saveAndHandle(editClientContactPerson, { contactPerson });
  };

  saveContactPersonPhoneNumber = contactPersonPhoneNumber => {
    this.saveAndHandle(editClientContactPersonPhoneNumber, { contactPersonPhoneNumber });
  };

  saveSMTPConfig = smtpConfig => {
    this.saveAndHandle(assignSMTPConfig, { smtpConfig });
  };

  saveScanReminders = scanReminders => {
    return this.save(editClientScanReminders, { scanReminders });
  };
  
  saveAutoAcceptOrders = autoAcceptOrders => {
    return this.save(editClientAutoAcceptOrders, { autoAcceptOrders });
  };

  saveNotes = notes => {
    this.saveAndHandle(editClientNotes, { notes });
  };

  saveTerminalNeedsLogin = terminalNeedsLogin => {
    return this.save(editClientTerminalNeedsLogin, { terminalNeedsLogin });
  };

  saveTerminalBeepOffline = terminalBeepOffline => {
    return this.save(editClientTerminalBeepOffline, { terminalBeepOffline });
  };

  saveTerminalForceStatusOrder = terminalForceStatusOrder => {
    return this.save(editClientTerminalForceStatusOrder, { terminalForceStatusOrder });
  };

  saveTerminalParallelActions = terminalParallelActions => {
    return this.save(editClientTerminalParallelActions, { terminalParallelActions });
  };

  saveTerminalMenuExpenses = terminalMenuExpenses => {
    return this.save(editClientTerminalMenuExpenses, { terminalMenuExpenses });
  };

  saveTerminalMenuMap = terminalMenuMap => {
    return this.save(editClientTerminalMenuMap, { terminalMenuMap });
  };

  saveTerminalMenuDriverCard = terminalMenuDriverCard => {
    return this.save(editClientTerminalMenuDriverCard, { terminalMenuDriverCard });
  };

  saveTerminalMenuAlert = terminalMenuAlert => {
    return this.save(editClientTerminalMenuAlert, { terminalMenuAlert });
  };

  saveTerminalMenuWhatsApp = terminalMenuWhatsApp => {
    return this.save(editClientTerminalMenuWhatsApp, { terminalMenuWhatsApp });
  };

  saveTerminalMenuRouteView = terminalMenuRouteView => {
    return this.save(editClientTerminalMenuRouteView, { terminalMenuRouteView });
  };

  saveTerminalMenuUserNav = terminalMenuUserNav => {
    return this.save(editClientTerminalMenuUserNav, { terminalMenuUserNav });
  };

  saveTerminalFooter = terminalFooter => {
    this.saveAndHandle(editClientTerminalFooter, { terminalFooter });
  };

  saveTerminalAlertEmail = terminalAlertEmail => {
    this.saveAndHandle(editClientTerminalAlertEmail, { terminalAlertEmail });
  };

  saveDataAvailabilityMonths = value => {
    const [ dataAvailabilityMonths, isValid ] = parseAndValidateNumber(value);
    if (value && !isValid) { // show error only if filled in, because empty value is allowed (null)
      this.props.showAlert("Wartość musi być liczbą.", "danger");
      return Promise.resolve(false);
    }

    this.saveAndHandle(editDataAvailabilityMonths, { dataAvailabilityMonths });
  };

  saveDetectClientPoints = detectClientPoints => {
    return this.save(editDetectClientPoints, { detectClientPoints });
  };

  saveClientPointsRadius = value => {
    const [ clientPointsRadius, isValid ] = parseAndValidateNumber(value, 0, 2000);
    if (!isValid) {
      this.props.showAlert("Wartość musi być liczbą i mieścić się w zakresie 0-2000.", "danger");
      return Promise.resolve(false);
    }

    this.saveAndHandle(editClientPointsRadius, { clientPointsRadius });
  };

  saveTraderVisitDuration = value => {
    const [ traderVisitDuration, isValid ] = parseAndValidateNumber(value, 0, 180);
    if (!isValid) {
      this.props.showAlert("Wartość musi być liczbą i mieścić się w zakresie 0-180.", "danger");
      return Promise.resolve(false);
    }

    this.saveAndHandle(editTraderVisitDuration, { traderVisitDuration });
  };

  save = (endpoint, data) => {
    const { clientDetails } = this.props;

    return endpoint({
      clientId: clientDetails.id,
      ...data,
    });
  };

  saveAndHandle = (endpoint, data) => {
    this.save(endpoint, data)
      .then(r => {
        if (r.result === 0) {
          this.props.showAlert("Zmiany zostały zapisane.", "success", this.refresh);
        }
        else {
          this.showError();
        }
      })
      .catch(this.showError);
  };

  refresh = () => {
    this.props.refreshUsersList(this.props.refreshDetails);
  };

  render() {
    const { clientDetails, client, className } = this.props;

    if (!clientDetails) {
      return <div className={className} />;
    }

    const usersLength = Object.keys(client.users).length;
    const deleteMessage = client.activeDevicesCount || usersLength || client.activeRolesCount
      ? `Ten klient posiada ${client.activeDevicesCount} aktywnych urządzeń, ${usersLength} użytkowników i ${client.activeRolesCount} ról. Czy na pewno chcesz usunąć tego klienta?`
      : "Czy na pewno chcesz usunąć tego klienta?";

    return (
      <Auth.Consumer>
        {({ hasPermission, hasPermissions, user }) => {
          if (!user) {
            return null;
          }

          const canEditClients = hasPermission("editingClients");
          const canEditSalesDetails = canEditClients && hasPermission("fleetReportTraders");
          
          return (
            <div className="col-12">
              <h3>
                <span>Klient</span>

                {hasPermissions("deletingClients", "sysop") && !clientDetails.deleted && user.clientId !== clientDetails.id && (
                  <span className="ml-3">
                    <ButtonWithConfirmationDialog
                      outline
                      color="danger"
                      size="sm"
                      style={{ verticalAlign: "bottom" }}
                      onClick={this.deleteClient}
                      dialogTitle="Potwierdzenie"
                      dialogMessage={deleteMessage}
                      dialogAcceptButtonCaption="Tak"
                      dialogRejectButtonCaption="Nie">
                      Usuń
                    </ButtonWithConfirmationDialog>
                  </span>
                )}
              </h3>

              <dl className="row">
                <LabelValue label="Nazwa" value={clientDetails.name} editable={canEditClients} onEdited={this.saveClientName} />

                <LabelValue label="Pełna nazwa" value={clientDetails.fullName} editable={canEditClients} onEdited={this.saveFullName} />

                <LabelValue label="NIP" value={clientDetails.vatid} editable={canEditClients} onEdited={this.saveVatid} />

                <LabelValue label="Adres" value={clientDetails.address} editable={canEditClients} onEdited={this.saveAddress} />

                <LabelValue label="Osoba kontaktowa" value={clientDetails.contactPerson} editable={canEditClients} onEdited={this.saveContactPerson} />

                <LabelValue label="Tel. do osoby kontaktowej" value={clientDetails.contactPersonPhoneNumber} editable={canEditClients} onEdited={this.saveContactPersonPhoneNumber} />

                <LabelValue label="Konfiguracja SMTP" value={<SMTPConfig client={clientDetails} editable={hasPermission("configuringSMTP")} onSMTPConfigAssigned={this.refresh} />} customValueField />

                <LabelValue label="Dostęp do historii (mies.)" value={clientDetails.dataAvailabilityMonths} editable={canEditClients} onEdited={this.saveDataAvailabilityMonths} />

                <LabelValue label="Przypomnienie o skanowaniu" value={<LabelValueCheckbox
                  currentValue={clientDetails.scanReminders}
                  save={this.saveScanReminders}
                  id="scanReminders"
                  editable={canEditClients}
                  onChange={this.refresh}
                />} customValueField />

                <LabelValue label="Autoakceptacja zleceń" value={<LabelValueCheckbox
                  currentValue={clientDetails.autoAcceptOrders}
                  save={this.saveAutoAcceptOrders}
                  id="autoAcceptOrders"
                  editable={canEditClients}
                  onChange={this.refresh}
                />} customValueField />
                
                <LabelValue label="Notatki" value={clientDetails.notes} editable={canEditClients} onEdited={this.saveNotes} maxLength={65535} />

                <LabelValue label="Operator" value={clientDetails.operator?.name} />

                <LabelValue label="GUID" value={clientDetails.guid} />
              </dl>

              <h3>
                <span>Terminal</span>
              </h3>

              <dl className="row">
                <LabelValue label="Wymaga ręcznego logowania" description={TERMINAL_NEEDS_LOGIN_DESCRIPTION} value={<LabelValueCheckbox
                  currentValue={clientDetails.terminalNeedsLogin}
                  save={this.saveTerminalNeedsLogin}
                  id="terminalNeedsLogin"
                  editable={canEditClients}
                  onChange={this.refresh}
                />} customValueField />

                <LabelValue label="Sygnalizacja offline" description={TERMINAL_BEEP_OFFLINE_DESCRIPTION} value={<LabelValueCheckbox
                  currentValue={clientDetails.terminalBeepOffline}
                  save={this.saveTerminalBeepOffline}
                  id="terminalBeepOffline"
                  editable={canEditClients}
                  onChange={this.refresh}
                />} customValueField />

                <LabelValue
                  label="Stopka głównego widoku"
                  description={TERMINAL_FOOTER_DESCRIPTION}
                  value={clientDetails.terminalFooter}
                  editable={canEditClients}
                  onEdited={this.saveTerminalFooter}
                  maxLength={160}
                />

                <LabelValue
                  label="Adres do alertów"
                  description={TERMINAL_ALERT_EMAIL_DESCRIPTION}
                  value={clientDetails.terminalAlertEmail}
                  editable={canEditClients}
                  onEdited={this.saveTerminalAlertEmail}
                  maxLength={40}
                />

                <LabelValue label="Wymuszona kolejność statusów" description={TERMINAL_FORCE_STATUS_ORDER_DESCRIPTION} value={<LabelValueCheckbox
                  currentValue={clientDetails.terminalForceStatusOrder}
                  save={this.saveTerminalForceStatusOrder}
                  id="terminalForceStatusOrder"
                  editable={canEditClients}
                  onChange={this.refresh}
                />} customValueField />

                <LabelValue label="Jednoczesna realizacja wielu zadań" description={TERMINAL_PARALLEL_ACTIONS_DESCRIPTION} value={<LabelValueCheckbox
                  currentValue={clientDetails.terminalParallelActions}
                  save={this.saveTerminalParallelActions}
                  id="terminalParallelActions"
                  editable={canEditClients}
                  onChange={this.refresh}
                />} customValueField />

                <LabelValue label="Funkcja Wydatki" description={TERMINAL_MENU_EXPENSES_DESCRIPTION} value={<LabelValueCheckbox
                  currentValue={clientDetails.terminalMenuExpenses}
                  save={this.saveTerminalMenuExpenses}
                  id="terminalMenuExpenses"
                  editable={canEditClients}
                  onChange={this.refresh}
                />} customValueField />

                <LabelValue label="Funkcja Mapa" description={TERMINAL_MENU_MAP_DESCRIPTION} value={<LabelValueCheckbox
                  currentValue={clientDetails.terminalMenuMap}
                  save={this.saveTerminalMenuMap}
                  id="terminalMenuMap"
                  editable={canEditClients}
                  onChange={this.refresh}
                />} customValueField />

                <LabelValue label="Funkcja Karta kierowcy" description={TERMINAL_MENU_DRIVERCARD_DESCRIPTION} value={<LabelValueCheckbox
                  currentValue={clientDetails.terminalMenuDriverCard}
                  save={this.saveTerminalMenuDriverCard}
                  id="terminalMenuDriverCard"
                  editable={canEditClients}
                  onChange={this.refresh}
                />} customValueField />

                <LabelValue label="Funkcja Zgłoszenie serwisowe" description={TERMINAL_MENU_ALERT_DESCRIPTION} value={<LabelValueCheckbox
                  currentValue={clientDetails.terminalMenuAlert}
                  save={this.saveTerminalMenuAlert}
                  id="terminalMenuAlert"
                  editable={canEditClients}
                  onChange={this.refresh}
                />} customValueField />

                <LabelValue label="Funkcja WhatsApp" description={TERMINAL_MENU_WHATSAPP_DESCRIPTION} value={<LabelValueCheckbox
                  currentValue={clientDetails.terminalMenuWhatsApp}
                  save={this.saveTerminalMenuWhatsApp}
                  id="terminalMenuWhatsApp"
                  editable={canEditClients}
                  onChange={this.refresh}
                />} customValueField />

                <LabelValue label="Funkcja Podgląd trasy" description={TERMINAL_MENU_ROUTE_VIEW_DESCRIPTION} value={<LabelValueCheckbox
                  currentValue={clientDetails.terminalMenuRouteView}
                  save={this.saveTerminalMenuRouteView}
                  id="terminalMenuRouteView"
                  editable={canEditClients}
                  onChange={this.refresh}
                />} customValueField />

                <LabelValue label="Funkcja Trasa własna" description={TERMINAL_MENU_USERNAV_DESCRIPTION} value={<LabelValueCheckbox
                  currentValue={clientDetails.terminalMenuUserNav}
                  save={this.saveTerminalMenuUserNav}
                  id="terminalMenuUserNav"
                  editable={canEditClients}
                  onChange={this.refresh}
                />} customValueField />
              </dl>

              <h3>
                <span>Obsługa handlowców</span>
              </h3>

              <dl className="row">
                <LabelValue label="Wykrywanie odwiedzeń klientów" value={<LabelValueCheckbox
                  currentValue={clientDetails.detectClientPoints}
                  save={this.saveDetectClientPoints}
                  id="detectClientPoints"
                  editable={canEditSalesDetails}
                  onChange={this.refresh}
                />} customValueField />

                <LabelValue label="Promień odległości od klienta (metry)" value={clientDetails.clientPointsRadius} editable={canEditSalesDetails} onEdited={this.saveClientPointsRadius} defaultValue="0" />

                <LabelValue label="Minimalny czas trwania wizyty (min.)" value={clientDetails.traderVisitDuration} editable={canEditSalesDetails} onEdited={this.saveTraderVisitDuration} defaultValue="0" />
              </dl>
            </div>
          );
        }}
      </Auth.Consumer>
    );
  }
}

export default withAlert(withLoading(ClientDetailsView));
