import React, { PureComponent, createRef } from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";
import { VariableSizeList as List } from 'react-window';

import UsersClient from "./UsersClient";
import { withLoading } from "../../components";
import Auth from "../../Auth";

const groupUsers = data => {
  if (!data) {
    return [];
  }

  return data
    .map(x => {
      const { users, ...client } = x;
      const usersSorted = Object.values(users);
      usersSorted.sort((a, b) => a.login.localeCompare(b.login) || a.id - b.id);
      
      return {
        client,
        users: usersSorted,
      };
    })
    .sort((a, b) => a.client.name.localeCompare(b.client.name));
};

const getItemKey = (index, data) => `listclient#${data.usersClients[index].client.id}`;

class UsersList extends PureComponent {
  listRef = createRef();

  userOnClick = e => {
    e.preventDefault();
    const id = parseInt(e.currentTarget.id.replace("user#", ""), 10); // id of selected user

    this.props.selectUser(id, true); // single selection
  };

  groupOnClick = e => {
    e.preventDefault();
    const id = parseInt(e.currentTarget.id.replace("client#", ""), 10);

    this.props.selectGroup(id, true);
  };

  memoizeGroupedUsers = memoize(users => {
    return groupUsers(users);
  });

  refreshListCache = () => {
    this.listRef.current?.resetAfterIndex(0);
  };

  createItemData = memoize((usersClients, selectedGroups, selectedUsers, refresh, canAddUsers) => ({
    usersClients,
    userOnClick: this.userOnClick,
    selectUser: this.props.selectUser,
    groupOnClick: this.groupOnClick,
    selectedGroups,
    selectedUsers,
    refresh,
    canAddUsers
  }));

  render() {
    const { selectedUsers, selectedGroups, filteredUsers, className, refresh, height, width } = this.props;
    const usersClients = this.memoizeGroupedUsers(filteredUsers);
    
    return (
      <Auth.Consumer>
        {({ hasPermission }) => {
          const canAddUsers = hasPermission("addingUsers");
          const clientHeight = canAddUsers ? 49 : 44;
          const getItemSize = index => clientHeight + usersClients[index].users.length * 44;
          const itemData = this.createItemData(usersClients, selectedGroups, selectedUsers, refresh, canAddUsers);

          return (
            <List ref={this.listRef} className={className} itemCount={usersClients.length} itemSize={getItemSize}
                height={height} width={width} style={{ overflowY: "auto" }} itemKey={getItemKey} itemData={itemData}>
              {UsersClient}
            </List>
          );
        }}
      </Auth.Consumer>
    );
  }
}

UsersList.propTypes = {
  selectUser: PropTypes.func.isRequired,
  filteredUsers: PropTypes.array,
  selectedUsers: PropTypes.object,
  isFetching: PropTypes.bool,
  refresh: PropTypes.func,
};

export default withLoading(UsersList);
