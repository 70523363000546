import React, { Fragment } from "react";
import { ListGroupItem } from "reactstrap";
import classNames from "classnames";
import { formatUser } from "../../utils";

const UserName = ({ login, displayName }) => (
  <Fragment>
    <small
      style={{ width: "200px", whiteSpace: "nowrap", overflow: "hidden" }}
      title={login}>
        {login}
    </small>

    <small
      style={{ width: "90px", whiteSpace: "nowrap", overflow: "hidden", marginLeft: "3px" }}
      className="text-right text-muted" title={displayName}>
        <em>{displayName}</em>
    </small>
  </Fragment>
);

const User = ({ id, name, firstName, surname, login, enabled, selected, onClick }) => {
  const displayName = formatUser({ name, firstName, surname, login });

  const userName = <UserName login={login} displayName={displayName} />;

  return (
    <ListGroupItem
      action
      id={`user#${id}`}
      className="list-group-item list-group-item-action"
      tag="button"
      active={selected}
      onClick={onClick}
      style={{ height: "44px" }}>
      <div className={classNames("row no-gutters")}>
        {enabled
          ? userName
          : <del className="row no-gutters col-12">{userName}</del>
        }
      </div>
    </ListGroupItem>
  );
}

export default User;
