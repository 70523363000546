import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

import AssignDictionaryItem from "./AssignDictionaryItem";

class AssignDictionaryItemButton extends PureComponent {
  state = {
    isDropdownOpen: false,
  };

  optionsLoader = ({ searchPhrase }) => {
    if (!this.state.isDropdownOpen) {
      return Promise.resolve({ list: [] });
    }

    return this.props.endpoint({
      searchPhrase
    });
  };

  toggleDropdown = () => {
    this.setState(prevState => ({ isDropdownOpen: !prevState.isDropdownOpen }));
  };

  render() {
    const { selectedItem, onSelectedItemChanged, placeholder, dropdownClassName, buttonSize, labelField, isButtonDisabled, isMulti, buttonColor, dropdownMenuStyle, cache = true } = this.props;
    const { isDropdownOpen } = this.state;

    // const isButtonDisabled = !selectedItem || (currentItem && currentItem.id === selectedItem.id);

    return (
      <Fragment>
        <Dropdown isOpen={isDropdownOpen} toggle={this.toggleDropdown} className={dropdownClassName}>
          <DropdownToggle outline color="primary" size={buttonSize || "xs"}>
            Przypisz
          </DropdownToggle>
          <DropdownMenu className="col" style={dropdownMenuStyle}>
            <div className="col">
              <AssignDictionaryItem
                endpoint={this.optionsLoader}
                assign={this.props.assign}
                selectedItem={selectedItem}
                onSelectedItemChanged={onSelectedItemChanged}
                placeholder={placeholder}
                dropdownClassName={dropdownClassName}
                labelField={labelField}
                isButtonDisabled={isButtonDisabled}
                isMulti={isMulti}
                buttonColor={buttonColor}
                cache={cache}
              />
            </div>
          </DropdownMenu>
        </Dropdown>
      </Fragment>
    );
  }
}

AssignDictionaryItemButton.propTypes = {
  endpoint: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  assign: PropTypes.func.isRequired,
  selectedItem: PropTypes.object,
  onSelectedItemChanged: PropTypes.func,
  placeholder: PropTypes.string,
  dropdownClassName: PropTypes.string,
  buttonSize: PropTypes.string,
  labelField: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  needsConfirmation: PropTypes.bool,
  isButtonDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  buttonColor: PropTypes.string,
  dropdownMenuStyle: PropTypes.object,
};

export default AssignDictionaryItemButton;
