import React, { PureComponent } from "react";
import memoize from "memoize-one";
import { startOfMonth, endOfMonth } from "date-fns";
import isEqual from "lodash.isequal";

import { DataProvider } from "../";
import HistoryFilter from "./HistoryFilter";

import "./History.css"

class HistoryComponent extends PureComponent {
  state = {
    option: "1",
    dateStart: startOfMonth(new Date()),
    dateEnd: endOfMonth(new Date()),
    history: null,
    isFetching: false,
  };

  setHistory = (history, error) => {
    if (error) {
      return;
    }

    this.setState(() => ({ history }));
  };

  setRefreshHistory = fetchHistory => {
    this.refreshHistory = fetchHistory;
  };

  setFetching = isFetching => {
    this.setState(() => ({ isFetching }));
  };

  setOption = option => {
    this.setState(() => ({ option }));
  };

  setDates = (dateStart, dateEnd) => {
    this.setState(() => ({ dateStart, dateEnd }));
  };

  payload = memoize(data => ({ ...data }), isEqual);

  render() {
    const { endpoint, data, HistoryView } = this.props;
    const { option, dateStart, dateEnd, history, isFetching } = this.state;
    const payload = this.payload({ filterOption: option, dateStart, dateEnd, ...data });

    return (
      <div style={{ overflowY: "scroll", margin: "-1.25rem" }}>
        <div style={{ margin: "1.25rem" }}>
          <DataProvider
            endpoint={endpoint}
            data={payload}
            onDataChanged={this.setHistory}
            fetchMethodSetter={this.setRefreshHistory}
            onDataFetching={this.setFetching}
          />

          <HistoryFilter option={option} dateStart={dateStart} dateEnd={dateEnd} setOption={this.setOption} setDates={this.setDates} />

          <HistoryView isFetching={isFetching} history={history} refreshHistory={this.refreshHistory} />
        </div>
      </div>
    );
  }
}

export default HistoryComponent;
