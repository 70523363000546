import React from "react";
import { ListGroupItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Device = ({ description, deleted }) => (
  <>
    <small>{description}</small>
    <FontAwesomeIcon icon="truck" size="xs" className="ml-1" />
  </>
);

const Terminal = ({ sn, imei, deleted }) => (
  <>
    <small>{sn || imei}</small>
    <FontAwesomeIcon icon="tablet-alt" size="xs" className="ml-1" />
  </>
);

const SimCard = ({ index, style, data }) => {
  const { filteredSimCards, selectedSimCards, onClick } = data;
  const { id, ccid, phone, device, terminal } = filteredSimCards[index];

  return (
    <ListGroupItem
      action
      id={`simcard#${id}`}
      className="list-group-item list-group-item-action"
      tag="button"
      active={!!selectedSimCards[id]}
      onClick={onClick}
      style={style}>
      <div className="row no-gutters">
        <div className="col">
          <small>{ccid}</small>
        </div>

        <div className="col text-center">
          <small>{phone}</small>
        </div>

        <div className="col-5 text-right">
          {device
            ? <Device {...device} />
            : terminal
              ? <Terminal {...terminal} />
              : null
          }
        </div>
      </div>
    </ListGroupItem>
  );
}

export default SimCard;
