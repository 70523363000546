import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { ListGroup } from "reactstrap";

import Terminal from "./Terminal";

import "./TerminalsClient.css";

class TerminalsClient extends PureComponent {
  render() {
    const { client, terminals } = this.props;

    return (
      <Fragment>
        <div className="terminals-client-sticky py-2 px-3">
          <span className="mr-2 align-text-top">{client.name}</span>
          <small title="Liczba terminali">{terminals.length}</small>
        </div>

        <ListGroup flush>
          {terminals.map(terminal => (
            <Terminal {...terminal} key={terminal.id} onClick={this.props.terminalOnClick} selected={!!this.props.selectedTerminals[terminal.id]} />
          ))}
        </ListGroup>
      </Fragment>
    );
  }
}

TerminalsClient.propTypes = {
  terminalOnClick: PropTypes.func.isRequired,
  client: PropTypes.object,
  terminals: PropTypes.arrayOf(PropTypes.object),
};

export default TerminalsClient;
