import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Filter } from "../../components";
import AddSimCard from "./AddSimCard";

const tooltip = (
  <div className="text-left">
    <span>Filtrowanie po:</span>
    <ul className="mb-0 pl-4">
      <li>CCID i nr. telefonu</li>
      <li>nazwie pojazdu, nr. rejestracyjnym, nr. seryjnym, nr. IMEI i nr. biznesowym (e-TOLL)</li>
      <li>nr. IMEI i nr. seryjnym tabletu</li>
      <li>nazwie konta kart SIM</li>
      <li>nazwie klienta</li>
    </ul>
  </div>
);

class SimCardsFilter extends PureComponent {
  onSimCardAdded = id => {
    this.props.refresh(() => this.props.selectSimCard(id, true));
  }

  render() {
    const { options } = this.props;
    
    return (
      <Filter
        refresh={() => this.props.refresh()}
        onChange={this.props.onChange}
        options={options}
        tooltip={tooltip}
        maxWidth={336}
        addons={
          <AddSimCard onSuccess={this.onSimCardAdded} />
        } />
    );
  }
}

SimCardsFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default SimCardsFilter;
