import React, { Component, Fragment } from "react";
import { State } from "react-automata";
import { Redirect } from "react-router-dom";

import { DynamicConfirmButton, withStateMachine } from "../../components";
import { getChannel } from "../../stateMachineUtils";
import { login as apiLogin } from "../../api";

const failedLoginState = {
  on: {
    clearFailedLogin: "notLoggedIn",
  },
};

export const statechart = {
  initial: "notLoggedIn",
  states: {
    notLoggedIn: {
      on: {
        login: "loading",
      },
    },
    loading: {
      onEntry: "login",
      on: {
        SUCCESS: "loggedIn",
        FAILURE: "loginFailed",
        SERVER_ERROR: "connectionError"
      },
    },
    loggedIn: {
      onEntry: "onLoggedIn",
    },
    loginFailed: {
      ...failedLoginState
    },
    connectionError: {
      ...failedLoginState
    }
  },
};

export class Login extends Component {
  state = {
    login: "",
    password: "",
  };

  getRedirectLocation = () => {
    const searchParams = new URL(window.location).searchParams;

    return (
      this.props.location.state || {
        from: {
          pathname: searchParams ? searchParams.get("redir") || "/" : "/",
        },
      }
    );
  };

  onLoggedIn = () => {
    this.props.onLoggedIn();
  };

  onLogin = () => {
    this.props.transition("login");
  };

  login = () => {
    apiLogin({
      username: this.state.login,
      password: this.state.password
    })
      .then(response => {
        // const loggedIn = response.result === 0;

        // if (loggedIn) {
        //   this.setState(
        //     {
        //       redir: response.extra,
        //       login: undefined,
        //       password: undefined,
        //     },
        //     () => this.props.transition("SUCCESS")
        //   );
        // } else {
        //   this.props.transition("FAILURE");
        // }
        this.setState({
          login: undefined,
          password: undefined,
        }, () => this.props.transition("SUCCESS", response));
      })
      .catch(() => {
        this.props.transition("SERVER_ERROR")
      });
  };

  clearFailedLogin = () => {
    this.props.transition("clearFailedLogin");
  };

  onInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { login, password } = this.state;
    const { from } = this.getRedirectLocation();
    const labelConfirm =
      this.props.machineState.value === "loginFailed"
        ? "Błędny login lub hasło"
        : this.props.machineState.value === "loading" ? "Logowanie..." : "Błąd połączenia";

    return (
      <Fragment>
        <State channel={getChannel(this)} is="loggedIn">
          <Redirect to={from} />
        </State>

        <div className="signin-container">
          <form className="form-signin" onSubmit={e => e.preventDefault()}>
            <h2 className="form-signin-heading">
              Logowanie
              <div className="pull-right">
                <span
                  className="pull-right"
                  style={{
                    fontSize: "20px",
                    fontWeight: "200",
                    color: "#777",
                    textShadow: "0 1px 0 #fff",
                    marginTop: "6px",
                  }}
                />
              </div>
            </h2>
            <input
              type="text"
              name="login"
              className="form-control"
              placeholder="Użytkownik"
              required
              autoFocus
              autoCapitalize="off"
              defaultValue={login}
              onInput={this.onInputChange}
            />
            <input
              type="password"
              name="password"
              autoComplete="password"
              className="form-control"
              placeholder="Hasło"
              defaultValue={password}
              onInput={this.onInputChange}
            />
            <DynamicConfirmButton
              className="btn btn-lg btn-primary btn-block"
              type="submit"
              label="Zaloguj"
              confirmLabel={labelConfirm}
              confirmationTimeout={3000}
              onClick={this.onLogin}
              onConfirm={this.clearFailedLogin}
              onConfirmationTimedOut={this.clearFailedLogin}
            />
          </form>
        </div>
      </Fragment>
    );
  }
}

export default withStateMachine(statechart)(Login);
