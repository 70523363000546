import React, { PureComponent, Fragment } from "react";
import memoize from "memoize-one";

import { DataProvider } from "../../../../../components";
import DeviceServicesView from "./DeviceServicesView";
import { getDeviceServices } from "../../../../../api";

class DeviceServices extends PureComponent {
  state = {
    services: null,
    isFetching: false,
  };

  setServices = (services, error) => {
    if (error) {
      return;
    }

    this.setState(() => ({ services: services.list }));
  };

  setRefreshServices = fetchServices => {
    this.refreshServices = fetchServices;
  };

  setFetching = isFetching => {
    this.setState(() => ({ isFetching }));
  };

  payload = memoize(deviceId => ({ deviceId }));

  render() {
    const { services, isFetching } = this.state;
    const payload = this.payload(this.props.device.id);

    return (
      <Fragment>
        <DataProvider
          endpoint={getDeviceServices}
          data={payload}
          onDataChanged={this.setServices}
          fetchMethodSetter={this.setRefreshServices}
          onDataFetching={this.setFetching}
        />

        <DeviceServicesView isFetching={isFetching} services={services} device={this.props.device} refreshDevicesList={this.props.refreshDevicesList} refreshServices={this.refreshServices} />
      </Fragment>
    );
  }
}

export default DeviceServices;
