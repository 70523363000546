import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { NavLink, Switch, Route, Redirect } from "react-router-dom";
import { Nav as NavMenu, NavItem, Card, CardHeader, CardBody } from "reactstrap";

import Services from "./services";

class Pages extends PureComponent {
  render() {
    const { selectedOperators, refreshOperators } = this.props;

    return (
      <Card className="h-100">
        <CardHeader>
          <NavMenu card tabs className="mt-2 mt-md-0">
            <NavItem><NavLink to="/dashboard/services" exact className="nav-link">Pakiety</NavLink></NavItem>
          </NavMenu>
        </CardHeader>

        <CardBody style={{ overflowY: "auto" }}>
          <Switch>
            <Route path="/dashboard/services" render={props => <Services {...props} operator={Object.values(selectedOperators)[0]} refreshOperators={refreshOperators} />} />
            <Redirect to="/dashboard/services" />
          </Switch>
        </CardBody>
      </Card>
    );
  }
}

Pages.propTypes = {
  selectedOperators: PropTypes.object.isRequired,
  refreshOperators: PropTypes.func.isRequired,
};

export default Pages;
