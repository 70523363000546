import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

class Dialog extends PureComponent {
  render() {
    return (
      <Modal isOpen={this.props.isOpen} backdrop="static" centered>
        <ModalHeader>{this.props.title}</ModalHeader>
        <ModalBody>
          {this.props.message}
        </ModalBody>
        <ModalFooter>
          {this.props.accept && <Button color={this.props.acceptColor || "primary"} onClick={this.props.accept}>{this.props.acceptCaption || "Ok"}</Button>}
          <Button color="secondary" onClick={this.props.reject}>{this.props.rejectCaption || "Anuluj"}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

Dialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  accept: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired,
  title: PropTypes.string,
  acceptColor: PropTypes.string,
  acceptCaption: PropTypes.string,
  rejectCaption: PropTypes.string
};

export default Dialog;
