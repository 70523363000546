import React, { Component, Fragment } from "react";
import {
  Button,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  CustomInput
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { withAlert } from "../../components/Alert";
import { addSimCard, getSimAccountsList } from "../../api";
import { DictionarySelect } from "../../components";
import { debounce, formatPhone } from "../../utils";

class AddSimCard extends Component {
  state = {
    modalIsOpen: false,
    simAccount: null,
    ccid: "89480",
    phone: "",
    phoneDisplay: "",
    pin: null,
    puk: null,
    roamingEnabled: false,
    privateAPN: false,
    notes: null,
  };

  toggleModal = callback => {
    const cb = callback && callback.apply ? callback : undefined;

    this.setState(prevState => ({
      modalIsOpen: !prevState.modalIsOpen,
      simAccount: null,
      ccid: "89480",
      phone: "",
      phoneDisplay: "",
      pin: null,
      puk: null,
      roamingEnabled: false,
      privateAPN: false,
      notes: null,
    }), cb);
  };

  addSimCard = () => {
    const {
      simAccount,
      ccid,
      phone,
      pin,
      puk,
      roamingEnabled,
      privateAPN,
      notes
    } = this.state;

    addSimCard({
      simAccountId: simAccount.id,
      ccid,
      phone,
      pin,
      puk,
      roamingEnabled,
      privateAPN,
      notes
    })
      .then(r => {
        if (r.result === 0) {
          this.props.showAlert("Dodano kartę SIM", "success");
          this.toggleModal(() => this.props.onSuccess(r.id));
        }
        else {
          this.props.showAlert("Błąd dodawania karty sim. Sprawdź CCID oraz numer telefonu.", "danger");
        }
      })
      .catch(() => {
        this.props.showAlert("Wystąpił błąd", "danger");
      });
  };

  onPhoneChange = e => {
    const value = (e.target.value || "")
      .replace(/^0/, "") // remove leading 0
      .split(" ")
      .join(""); 

    const valueDisplay = formatPhone(value);

    this.setState(() => ({ phone: value, phoneDisplay: valueDisplay }));
  };

  onInput = e => {
    const { name, value } = e.target;

    this.setState(() => ({ [name]: value }));
  };

  onChange = e => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      this.setState(() => ({ [name]: checked }));
    }
    else {
      this.setState(() => ({ [name]: value }));
    }
  };

  onCCIDFocus = e => {
    const len = e.target.value.length;
    e.target.setSelectionRange(len, len);
  };

  optionsLoader = debounce((filter, callback) => {
    getSimAccountsList({
      searchPhrase: filter
    })
      .then(r => r.list)
      .then(callback)
      .catch(this.onError);
  }, 500);

  onError = () => {
    this.props.showAlert("Could not fetch SIM accounts", "danger");
  };

  setSimAccount = simAccount => {
    this.setState(() => ({ simAccount }));
  };

  render() {
    const { modalIsOpen, simAccount, phone, phoneDisplay, ccid, pin, puk, roamingEnabled, privateAPN } = this.state;

    const phoneIsValid = /^[1-9]\d{8}$/.test(phone);
    const ccidIsValid = /^\d{19}$/.test(ccid);
    const pinIsValid = /^\d{4}$/.test(pin);
    const pukIsValid = /^\d{7,8}$/.test(puk);

    const submitDisabled = !phoneIsValid
      || !ccidIsValid
      || !pinIsValid
      || !pukIsValid;

    return (
      <Fragment>
        <InputGroupAddon addonType="append">
          <Button title="Dodaj kartę SIM" onClick={this.toggleModal}><FontAwesomeIcon icon="plus" /></Button>
        </InputGroupAddon>

        <Modal isOpen={modalIsOpen} toggle={this.toggleModal} backdrop="static" autoFocus={false}>
          <ModalHeader toggle={this.toggleModal}>Dodawanie karty SIM</ModalHeader>
          <ModalBody>
            <Form>
            <FormGroup row>
                <Label sm={3}>Konto</Label>
                <Col sm={9}>
                  <DictionarySelect
                    endpoint={this.optionsLoader}
                    onChange={this.setSimAccount}
                    value={simAccount}
                    cache
                    autoFocus
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Nr telefonu</Label>
                <Col sm={9}>
                  <Input type="text" name="phone" id="phone" onChange={this.onPhoneChange} value={phoneDisplay} invalid={!phoneIsValid} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>CCID</Label>
                <Col sm={9}>
                  <Input type="text" name="ccid" id="ccid" onChange={this.onChange} value={ccid} onFocus={this.onCCIDFocus} invalid={!ccidIsValid} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>PIN</Label>
                <Col sm={9}>
                  <Input type="text" name="pin" id="pin" onInput={this.onInput} invalid={!pinIsValid} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>PUK</Label>
                <Col sm={9}>
                  <Input type="text" name="puk" id="puk" onInput={this.onInput} invalid={!pukIsValid} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={3} />
                <Col sm={9}>
                  <CustomInput type="checkbox" name="roamingEnabled" id="roamingEnabled" onChange={this.onChange} checked={roamingEnabled}
                    label="Roaming" />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={3} />
                <Col sm={9}>
                  <CustomInput type="checkbox" name="privateAPN" id="privateAPNEnabled" onChange={this.onChange} checked={privateAPN}
                    label="Prywatny APN" />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Notatki</Label>
                <Col sm={9}>
                  <Input type="textarea" name="notes" id="notes" onInput={this.onInput} maxLength={64} />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.addSimCard} disabled={submitDisabled}>Dodaj</Button>
            <Button color="secondary" onClick={this.toggleModal}>Anuluj</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default withAlert(AddSimCard);
