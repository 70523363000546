import React, { Component, Fragment } from "react";
import { CustomInput } from "reactstrap";
import PropTypes from "prop-types";

import Auth from "../../../../../../../Auth";
import { withLoading, SaveCancelBar } from "../../../../../../../components";
import { withAlert } from "../../../../../../../components/Alert";
import { roleReassignPermissions } from "../../../../../../../api";
import { boolToInt } from "../../../../../../../utils";

const getChangedPermissions = (permissionsGroups, selectedPermissions) => {
  return permissionsGroups.reduce((a, x) => {
    return x.permissions.reduce((ac, y) => {
      const selected = !!selectedPermissions[y.id];
      if (selected && !y.selected) {
        ac.selected.push(y);
      }
      else if (!selected && y.selected) {
        ac.deselected.push(y);
      }

      return ac;
    }, a);
  }, { selected: [], deselected: [] });
};


class PermissionsView extends Component {
  handleResponse = r => {
    if (r.result === 0) {
      this.props.showAlert("Zmiany zostały zapisane!", "success", this.props.refreshPermissionsList);
    }
    else {
      this.handleError();
    }
  };

  handleError = () => {
    this.props.showAlert("Zmiany nie zostały zapisane!", "danger");
  };

  cancel = (selectElements) => () => {
    selectElements(this.props.permissions.reduce((a, x) => {
      if (x.selected) {
        a.push(x.id);
      }

      return a;
    }, []), true);
  };

  save = (selected, deselected) => () => {
    roleReassignPermissions({
      roleId: this.props.role.id,
      addIds: selected.map(x => x.id),
      removeIds: deselected.map(x => x.id)
    })
      .then(this.handleResponse)
      .catch(this.handleError);
  };

  select = e => {
    const id = parseInt(e.target.id.replace("pid#", ""), 10);

    this.props.selectElement(id, false);
  };

  render() {
    const { permissionsGroups, className, selectedPermissions, selectElements } = this.props;

    if (!permissionsGroups) {
      return <div className={className} />;
    }

    const { selected, deselected } = getChangedPermissions(permissionsGroups, selectedPermissions);
    const isDirty = !!(selected.length || deselected.length);

    return (
      <Auth.Consumer>
        {({ hasPermission }) => {
          const canManageRoles = hasPermission("managingRoles");
          const hasAccessToDeveloperSettings = hasPermission("accessToDeveloperSettings");
          const colCount = boolToInt(canManageRoles) + boolToInt(hasAccessToDeveloperSettings) + 2;

          return (
            <div style={{ overflowY: "scroll", margin: "-1.25rem", height: "calc(100% - 1.5rem)" }}>
              <div style={{ margin: "1.25rem" }}>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {canManageRoles && <th style={{ width: "50px" }}>&nbsp;</th>}
                      {hasAccessToDeveloperSettings && <th>Nazwa</th>}
                      <th>Opis</th>
                      <th>Uwagi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {permissionsGroups.map(permissionsGroup => (
                      <Fragment key={permissionsGroup.group.id}>
                        <tr>
                          <td colSpan={colCount}>
                            <strong style={{ fontSize: "1rem" }}>{permissionsGroup.group.name}</strong>
                          </td>
                        </tr>

                        {permissionsGroup.permissions.map(permission => (
                          <tr key={`pid#${permission.id}`}>
                            {canManageRoles && (
                              <td>
                                <CustomInput type="checkbox" id={`pid#${permission.id}`} checked={!!selectedPermissions[permission.id]} onChange={this.select} />
                              </td>
                            )}
                            {hasAccessToDeveloperSettings && <td title={permission.name}>{permission.name}</td>}
                            <td>{permission.description}</td>
                            <td>{permission.note}</td>
                          </tr>
                        ))}
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* <div className="d-flex justify-content-end p-3 save-cancel-bar">
                <div className="d-flex align-items-baseline justify-content-between w-25">
                  <span>Dodane: {selected.length}</span>
                  <span>Usunięte: {deselected.length}</span>
                  <div>
                    <button type="button" className="btn btn-sm btn-secondary mr-1" disabled={!isDirty} onClick={this.cancel(selectElements)}>Anuluj</button>
                    <button type="button" className="btn btn-sm btn-primary" disabled={!isDirty} onClick={this.save(selected, deselected)}>Zapisz</button>
                  </div>
                </div>
              </div> */}

              <SaveCancelBar isDirty={isDirty} cancel={this.cancel(selectElements)} save={this.save(selected, deselected)}>
                <Fragment>
                  <span className="mr-2">Dodane: {selected.length}</span>
                  <span className="mr-2">Usunięte: {deselected.length}</span>
                </Fragment>
              </SaveCancelBar>
            </div>
          );
        }}
      </Auth.Consumer>
    );
  }
}

PermissionsView.propTypes = {
  role: PropTypes.object.isRequired,
  refreshPermissionsList: PropTypes.func.isRequired,
  selectElement: PropTypes.func.isRequired,
  selectElements: PropTypes.func.isRequired,
  permissionsGroups: PropTypes.array,
  permissions: PropTypes.array,
};

export default withAlert(withLoading(PermissionsView));
