import React, { Component, createRef } from "react";

import Pages from "./pages";
import { FilteredList } from "../../components";
import { getClientsAndUsers } from "../../api";
import UsersContainer from "./UsersContainer";

const OPERATOR_PREFIX = "operator:";

const filterOptions = [
  {
    label: OPERATOR_PREFIX,
    value: OPERATOR_PREFIX,
    prefix: true
  }
];

const compareStrings = (field, phrase) => {
  return field && field.toLocaleLowerCase().includes(phrase);
};

const isClientMatchingKeyword = (client, keyword) => {
  const phrase = keyword.value.toLocaleLowerCase();

  if (phrase.startsWith(OPERATOR_PREFIX)) {
    if (client.operator) {
      const loginOrId = phrase.replace(OPERATOR_PREFIX, "");

      const result = compareStrings(client.operator.id + "", loginOrId)
        || compareStrings(client.operator.login, loginOrId);

      return result;
    }
    return false;
  }
  else {
    const clientNameMatch = compareStrings(client.name, phrase);
    const clientVatidMatch = compareStrings(client.vatid, phrase);

    if (clientNameMatch || clientVatidMatch) {
      return true;
    }

    for (let user of Object.values(client.users)) {
      const userMatch = compareStrings(user.firstName, phrase)
        || compareStrings(user.surname, phrase)
        || compareStrings(user.login, phrase);

      if (userMatch) {
        return true;
      }
    }
  }

  return false;
};

const filterBasedOnKeywords = (client, keywords) => {
  for (const keyword of keywords) {
    const isKeywordMatching = isClientMatchingKeyword(client, keyword);

    if (!isKeywordMatching) {
      return false;
    }
  }
    
  return true;
};

const filterUsers = (data, keywords) => {
  if (!keywords || !keywords.length) {
    return data;
  }

  return data.filter(x => filterBasedOnKeywords(x, keywords));
};

const filterFormatOptionLabel = (option, { context }) => {
  return (
    <span>
      <span>{option.label}</span>
      {option.value === OPERATOR_PREFIX && <span className="text-muted">login_operatora</span>}
    </span>
  );
};

const getClientId = client => client.id;

const getUser = (clients, key) => clients.find(client => client.users[key])?.users[key];

const getUsersFromGroup = group => Object.values(group.users);

class Users extends Component {
  usersListRef = createRef();

  getClientsAndUsers = (data, signal) => {
    return getClientsAndUsers(data, signal)
      .then(r => {
        if (r.result === 0) {
          const clients = r.list.reduce((c, client) => {
            const users = client.users.reduce((u, user) => {
              u[user.id] = user;
              user.client = { id: client.id };

              return u;
            }, {});

            c.push({
              ...client,
              users,
            });

            return c;
          }, []);

          return Promise.resolve({ result: 0, list: clients });
        }

        return Promise.reject(r);
      });
  };

  renderPages = ({selectedElements, selectedGroups, selectedCount, refresh}) => {
    return selectedCount > 0 || Object.keys(selectedGroups).length
      ? <Pages selectedUsers={selectedElements} selectedGroups={selectedGroups} refreshUsersList={refresh} />
      : <p className="p-3">Wybierz klienta lub użytkownika</p>;
  };

  listChangedCallback = () => {
    this.usersListRef.current?.refreshListCache();
  };

  render() {
    return (
      <FilteredList endpoint={this.getClientsAndUsers} listFilter={filterUsers} keyField="id" itemGetter={getUser} groupGetter={getClientId} groupItems={getUsersFromGroup} listChangedCallback={this.listChangedCallback} keepSelectionsAfterRefresh>
        {({ filteredList, selectedElements, selectedGroups, isFetching }, { refresh, refreshComponents, onFilterChanged, selectElement, selectElements, selectGroup, selectedCount }) =>
          <div className="content">
            <UsersContainer
              onFilterChanged={onFilterChanged}
              refresh={refresh}
              filteredList={filteredList}
              selectedElements={selectedElements}
              selectedGroups={selectedGroups}
              selectElement={selectElement}
              selectGroup={selectGroup}
              isFetching={isFetching}
              usersListRef={this.usersListRef}
              filterOptions={filterOptions}
              filterFormatOptionLabel={filterFormatOptionLabel}
            />

            <section>
              {this.renderPages({selectedElements, selectedGroups, selectedCount: selectedCount(), refresh})} {/* TODO: add new params */}
            </section>
          </div>
        }
      </FilteredList>
    );
  }
}

export default Users;
