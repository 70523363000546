import React, { Component, PureComponent, Fragment } from "react";
import { Form, FormGroup, Label, Col, Input, FormFeedback, CustomInput, Button, Alert, Container, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isEmail from "validator/es/lib/isEmail";
import isMobilePhone from "validator/es/lib/isMobilePhone";
import { FormattedMessage, injectIntl } from "react-intl";
import { formatDistanceToNow } from "date-fns";

import { checkEtollDevice, registerEtollAccount, restoreStoreSession } from "../../api";
import { withAlert } from "../../components/Alert";

import "./EtollRegistrationForm.css";
import PaymentDialog from "./PaymentDialog";
import { LocaleContext } from "../../components/LocaleContextProvider";

const dateTimeFormat = new Intl.DateTimeFormat("pl-PL", {
  timeZone: "UTC",
  timeZoneName: "short",
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hour12: false
});

const formatDateTime = dateTimeString => dateTimeFormat.format(new Date(dateTimeString));

class EtollDeviceInfo extends PureComponent {
  static contextType = LocaleContext;

  state = {
    refreshing: false,
  };
  lastLocationRef = React.createRef();
  lastEtollDateRef = React.createRef();

  highlightChange = node => {
    if (node.classList.contains("form-field-highlight")) {
      node.classList.remove("form-field-highlight");
    }
    node.classList.add("form-field-highlight");

    setTimeout(() => {
      node.classList.remove("form-field-highlight");
    }, 1200);
  };

  componentDidUpdate(props) {
    if (props.longitude !== this.props.longitude || props.latitude !== this.props.latitude || props.lastLocationDate !== this.props.lastLocationDate) {
      this.highlightChange(this.lastLocationRef.current);
    }

    if (props.lastEtollDate !== this.props.lastEtollDate) {
      this.highlightChange(this.lastEtollDateRef.current);
    }
  }

  refresh = () => {
    this.setState(() => ({ refreshing: true }), () => {
      this.props.refresh(() => {
        this.setState(() => ({ refreshing: false }));
      });
    });
  };

  render() {
    const { registrationDate, expirationDate, longitude, latitude, lastLocationDate, lastEtollDate, etollNumber, etollPin, intl } = this.props;
    const { refreshing } = this.state;
    const hasLocationData = longitude && latitude && lastLocationDate;

    const expirationDateObj = Date.parse(expirationDate);
    const secondsUntilSubscriptionEnd = (expirationDateObj - Date.now()) / 1000;
    const expirationTimeDistance = formatDistanceToNow(expirationDateObj, { locale: global.__localeData__[this.context.locale] });
    const formattedExpirationDate = formatDateTime(expirationDate);

    return (
      <Container>
        <div className="text-right">
          <Button type="button" color="link" onClick={this.refresh}>
            <FontAwesomeIcon icon="sync-alt" size="sm" title={intl.formatMessage({ id: "etoll:refresh", defaultMessage: "Odśwież" })} className="mr-1" spin={refreshing} />
            <FormattedMessage id="etoll:refresh" defaultMessage="Odśwież" />
          </Button>
        </div>

        <Row className="justify-content-center">
          <Col sm={5} className="text-sm-right font-weight-bold"><FormattedMessage id="etoll:activeFrom" defaultMessage="Aktywne od:" /></Col>
          <Col sm={4} className="text-sm-left text-right">
            {formatDateTime(registrationDate)}
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col sm={5} className="text-sm-right font-weight-bold"><FormattedMessage id="etoll:activeTo" defaultMessage="Aktywne do:" /></Col>
          <Col sm={4} className="text-sm-left text-right">
            {formattedExpirationDate}
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col sm={5} className="text-sm-right font-weight-bold"><FormattedMessage id="etoll:lastData" defaultMessage="Ostatnie dane:" /></Col>
          <Col sm={4} className="text-sm-left text-right">
            <div ref={this.lastLocationRef}>
              {hasLocationData ? (
                <div>
                  <a href={`https://google.com/maps/place/${latitude},${longitude}`} target="_blank" rel="noopener noreferrer">{latitude} {longitude}</a><br />
                  {formatDateTime(lastLocationDate)}
                </div>
              ) : (
                <span className="text-danger"><FormattedMessage id="etoll:noData" defaultMessage="Brak danych" /></span>
              )}
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col sm={5} className="text-sm-right font-weight-bold"><FormattedMessage id="etoll:lastEtollData" defaultMessage="Ostatnie dane e-TOLL:" /></Col>
          <Col sm={4} className="text-sm-left text-right">
            <div ref={this.lastEtollDateRef}>
              {lastEtollDate
                ? formatDateTime(lastEtollDate)
                : <span className="text-danger"><FormattedMessage id="etoll:noData" defaultMessage="Brak danych" /></span>
              }
            </div>
          </Col>
        </Row>

        {secondsUntilSubscriptionEnd > 0 && secondsUntilSubscriptionEnd < (60*60*24*30 * 2) && ( // ends within 2 months
          <div className="my-2">
            <Alert color="warning" className="row" fade={false}>
              <FormattedMessage
                id="etoll:subscriptionEndNotice"
                defaultMessage="Bieżący abonament wygasa za {time}. Aby przedłużyć abonament, kliknij w poniższy przycisk."
                values={{
                  // eslint-disable-next-line react/style-prop-object
                  time: expirationTimeDistance
                }}
              />
            </Alert>

            <PaymentDialog
              etollNumber={etollNumber}
              etollPin={etollPin}
              activeToDate={formattedExpirationDate}
            />
          </div>
        )}
      </Container>
    );
  }
};

class EtollRegistrationForm extends Component {
  state = {
    etollNumber: "",
    etollPin: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    gtacAccepted: false,
    gtacosAccepted: false,
    tosAccepted: false,
    rodoAccepted: false,
    processingAccepted: false,
    registered: false,
    registrationVisible: false,
    deviceInfo: null,
    allChecked: false,
  };

  componentDidMount() {
    const queryStringParams = new URLSearchParams(global.location.search);
    const sessionId = queryStringParams.get("sessionId");

    if (sessionId) {
      const { intl } = this.props;

      restoreStoreSession({ sessionId })
        .then(r => {
          if (r.result === 0) {
            const { etollNumber, etollPin } = r;
            this.setState(
              () => ({ etollNumber, etollPin, subscriptionRenewed: true }),
              () => this.checkDevice(() => this.props.showAlert(intl.formatMessage({ id: "etoll:paymentSuccessful", defaultMessage: "Abonament został przedłużony!" }), "success")));
          }
          else if (r.result === 1) {
            this.props.showAlert(
              intl.formatMessage({ id: "etoll:sessionExpired", defaultMessage: "Sesja wygasła. Zaloguj się ponownie." }),
              "danger",
              () => {
                queryStringParams.delete("sessionId");
                global.location.search = queryStringParams.toString();
              }
            );
          }
          else if (r.result === 2) {
            this.setState(() => ({ paymentInProcess: true }), () => setTimeout(() => global.location.reload(), 5000));
          }
          else {
            this.props.showAlert(intl.formatMessage({ id: "etoll:generalError", defaultMessage: "Wystąpił błąd" }), "danger");
          }
        })
        .catch(() => {
          this.props.showAlert(intl.formatMessage({ id: "etoll:generalError", defaultMessage: "Wystąpił błąd" }), "danger");
        });
    }
  }

  onInput = e => {
    const { name, value } = e.target;
    this.setState(() => ({ [name]: value }));
  };

  onChange = e => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      const { gtacAccepted, gtacosAccepted, tosAccepted, rodoAccepted, processingAccepted } = { ...this.state, [name]: checked };
      const allChecked = gtacAccepted && gtacosAccepted && tosAccepted && rodoAccepted && processingAccepted;
      this.setState(() => ({ [name]: checked, allChecked }));
    }
    else {
      this.setState(() => ({ [name]: value }));
    }
  };

  checkDevice = callback => {
    const { etollNumber, etollPin } = this.state;
    const { intl } = this.props;
    
    checkEtollDevice({
      etollNumber: etollNumber.toUpperCase(),
      etollPin,
    }).then(r => {
      if (callback && callback.call) {
        callback(r.result);
      }

      if (r.result === 0) {
        this.setState(() => ({ registrationVisible: false, deviceInfo: r }));
      }
      else if (r.result === 1) {
        this.props.showAlert(intl.formatMessage({ id: "etoll:registrationCheckError:1", defaultMessage: "Niezarejestrowane urządzenie" }), "primary", () => this.setState(() => ({ registrationVisible: true })));
      }
      else {
        this.props.showAlert(intl.formatMessage({ id: "etoll:registrationCheckErrorNotFound", defaultMessage: "Nie znaleziono urządzenia" }), "danger");
      }
    })
    .catch(() => {
      if (callback && callback.call) {
        callback(false);
      }

      this.props.showAlert(intl.formatMessage({ id: "etoll:generalError", defaultMessage: "Wystąpił błąd" }), "danger");
    });
  };

  register = () => {
    const { intl } = this.props;
    const { etollNumber, etollPin, companyName, email, phoneNumber, gtacAccepted, gtacosAccepted, tosAccepted, rodoAccepted, processingAccepted } = this.state;

    registerEtollAccount({
      etollNumber: etollNumber.toUpperCase(),
      etollPin,
      companyName,
      email,
      phoneNumber,
      gtacAccepted,
      gtacosAccepted,
      tosAccepted,
      rodoAccepted,
      processingAccepted
    })
      .then(r => {
        if (r.result === 0) {
          this.setState(() => ({ registered: true }), this.checkDevice);
        }
        else if (r.result === 1) {
          this.props.showAlert(intl.formatMessage({ id: "etoll:registrationError:1", defaultMessage: "Popraw błędy w formularzu" }), "danger");
        }
        else if (r.result === 2) {
          this.props.showAlert(intl.formatMessage({ id: "etoll:registrationError:2", defaultMessage: "Nie znaleziono urządzenia" }), "danger");
        }
        else {
          this.props.showAlert(intl.formatMessage({ id: "etoll:generalError", defaultMessage: "Wystąpił błąd" }), "danger");
        }
      })
      .catch(() => {
        this.props.showAlert(intl.formatMessage({ id: "etoll:generalError", defaultMessage: "Wystąpił błąd" }), "danger");
      });
  };

  selectAllCheckboxes = () => {
    const { allChecked } = this.state;

    this.setState(() => ({
      allChecked: !allChecked,
      gtacAccepted: !allChecked,
      gtacosAccepted: !allChecked,
      tosAccepted: !allChecked,
      rodoAccepted: !allChecked,
      processingAccepted: !allChecked,
    }));
  };

  render() {
    const {
      etollNumber,
      etollPin,
      companyName,
      email,
      phoneNumber,
      gtacAccepted,
      gtacosAccepted,
      tosAccepted,
      rodoAccepted,
      processingAccepted,
      registered,
      registrationVisible,
      deviceInfo,
      allChecked,
      paymentInProcess,
      subscriptionRenewed,
    } = this.state;
    const { intl } = this.props;

    const etollNumberInvalid = !etollNumber || !/^[a-z]\d{2}-[a-z]{2}\d{2}[a-z]{2}-\d$/i.test(etollNumber);
    const etollPinInvalid = !etollPin || !/^\d{4}$/i.test(etollPin);
    const phoneNumberValidationRequired = !email || !!phoneNumber;
    const phoneNumberValidationFailed = phoneNumberValidationRequired && !isMobilePhone(phoneNumber, "any", { strictMode: true });
    const emailValidationRequired = !phoneNumber || !!email;
    const emailValidationFailed = emailValidationRequired && !isEmail(email);
    
    const submitDisabled = etollNumberInvalid || etollPinInvalid || !allChecked
      || (phoneNumberValidationRequired && phoneNumberValidationFailed)
      || (emailValidationRequired && emailValidationFailed);

      
      return (
        <LocaleContext.Consumer>
        {context => {
          const localeFileSuffix = context.locale === "pl" ? "" : "_en";
          
          return (
            <Fragment>
              {!deviceInfo && !paymentInProcess && !subscriptionRenewed && (
                <Fragment>
                  <Form>
                    <FormGroup row>
                      <Label for="etollNumber" sm={4}><FormattedMessage id="etoll:businessId" defaultMessage="Identyfikator biznesowy" /></Label>
                      <Col sm={8}>
                        <Input type="text" name="etollNumber" id="etollNumber" onInput={this.onInput} autoFocus valid={!etollNumberInvalid} invalid={etollNumberInvalid} />
                        <FormFeedback><FormattedMessage id="etoll:businessIdFeedback" defaultMessage="Wprowadź poprawny identyfikator biznesowy" /></FormFeedback>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for="etollPin" sm={4}>PIN</Label>
                      <Col sm={8}>
                        <Input type="text" name="etollPin" id="etollPin" onInput={this.onInput} valid={!etollPinInvalid} invalid={etollPinInvalid} />
                        <FormFeedback><FormattedMessage id="etoll:etollPinFeedback" defaultMessage="Wprowadź poprawny PIN" /></FormFeedback>
                      </Col>
                    </FormGroup>

                    {registrationVisible && (
                      <Fragment>
                        <FormGroup row>
                          <Label for="companyName" sm={4}>
                            <FormattedMessage id="etoll:companyNameLabel" defaultMessage="Nazwa firmy lub imię i nazwisko" />
                          </Label>
                          <Col sm={8}>
                            <Input type="text" name="companyName" id="companyName" onInput={this.onInput} valid={!!companyName} />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label for="email" sm={4}>E-mail</Label>
                          <Col sm={8}>
                            <Input type="text" name="email" id="email" onInput={this.onInput} valid={emailValidationRequired && !emailValidationFailed} invalid={emailValidationFailed} />
                            <FormFeedback><FormattedMessage id="etoll:emailFeedback" defaultMessage="Wprowadź prawidłowy adres e-mail" /></FormFeedback>
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label for="phoneNumber" sm={4}>
                            <FormattedMessage id="etoll:phoneNumberLabel" defaultMessage="Numer telefonu komórkowego" />
                          </Label>
                          <Col sm={8}>
                            <Input type="text" name="phoneNumber" id="phoneNumber" onInput={this.onInput} valid={phoneNumberValidationRequired && !phoneNumberValidationFailed}
                              invalid={phoneNumberValidationFailed} placeholder={intl.formatMessage({ id: "etoll:phoneNumberPlaceholder", defaultMessage: "np. +48 500600700" })} />
                            <FormFeedback><FormattedMessage id="etoll:phoneNumberFeedback" defaultMessage="Wprowadź prawidłowy numer telefonu komórkowego" /></FormFeedback>
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Col sm={4} />
                          <Col sm={8} style={{ position: "relative" }}>
                            <CustomInput type="checkbox" name="allChecked" id="allChecked" onChange={this.selectAllCheckboxes} checked={allChecked}
                              label={<FormattedMessage id="etoll:selectAllCheckboxes" defaultMessage="Zaakceptuj wszystko" />}></CustomInput>
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Col sm={4} />
                          <Col sm={8} style={{ position: "relative" }}>
                            <CustomInput type="checkbox" name="gtacAccepted" id="gtacAccepted" onChange={this.onChange} checked={gtacAccepted} valid={gtacAccepted || allChecked}
                              invalid={!gtacAccepted}
                              label={
                                <FormattedMessage id="etoll:gtac" defaultMessage="Zaakceptuj <link>Ogólne Warunki Usług</link>" values={{
                                  link: chunks => (
                                    <a
                                      href={`https://map.sigen.pl/doc/owu/qr_owu_2021_10_08${localeFileSuffix}.pdf`}
                                      target="_blank"
                                      rel="noopener noreferrer">
                                        {chunks}
                                      </a>
                                  )}}
                                />
                              }>
                              <FormFeedback><FormattedMessage id="etoll:gtacFeedback" defaultMessage="Akceptacja Ogólnych Warunków Usług jest wymagana" /></FormFeedback>
                            </CustomInput>
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Col sm={4} />
                          <Col sm={8} style={{ position: "relative" }}>
                            <CustomInput type="checkbox" name="gtacosAccepted" id="gtacosAccepted" onChange={this.onChange} checked={gtacosAccepted} valid={gtacosAccepted || allChecked}
                              invalid={!gtacosAccepted} label={
                                <FormattedMessage id="etoll:gtacos" defaultMessage="Zaakceptuj <link>Ogólne Warunki Sprzedaży</link>" values={{
                                  link: chunks => (
                                    <a
                                      href={`https://map.sigen.pl/doc/owu/qr_dev_2021_10_08${localeFileSuffix}.pdf`}
                                      target="_blank"
                                      rel="noopener noreferrer">
                                        {chunks}
                                    </a>
                                )}}
                                />
                              }>
                              <FormFeedback><FormattedMessage id="etoll:etacosFeedback" defaultMessage="Akceptacja Ogólnych Warunków Sprzedaży jest wymagana" /></FormFeedback>
                            </CustomInput>
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Col sm={4} />
                          <Col sm={8} style={{ position: "relative" }}>
                            <CustomInput type="checkbox" name="tosAccepted" id="tosAccepted" onChange={this.onChange} checked={tosAccepted} valid={tosAccepted || allChecked}
                              invalid={!tosAccepted} label={
                                <FormattedMessage id="etoll:tos" defaultMessage="Zaakceptuj <link>Regulamin Usługi e-TOLL</link>" values={{
                                  link: chunks => (
                                    <a
                                      href={`https://map.sigen.pl/doc/owu/qr_etoll_2021_10_08${localeFileSuffix}.pdf`}
                                      target="_blank"
                                      rel="noopener noreferrer">
                                        {chunks}
                                    </a>
                                )}}
                                />
                              }>
                              <FormFeedback><FormattedMessage id="etoll:tosFeedback" defaultMessage="Akceptacja Regulaminu Usługi e-TOLL jest wymagana" /></FormFeedback>
                            </CustomInput>
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Col sm={4} />
                          <Col sm={8} style={{ position: "relative" }}>
                            <CustomInput type="checkbox" name="rodoAccepted" id="rodoAccepted" onChange={this.onChange} checked={rodoAccepted} valid={rodoAccepted || allChecked}
                              invalid={!rodoAccepted} label={
                                <FormattedMessage id="etoll:rodo" defaultMessage="Zgoda na <link>Przetwarzanie Danych Osobowych</link>" values={{
                                  link: chunks => (
                                    <a
                                      href="http://setivo.pl/regulamin#rodo"
                                      target="_blank"
                                      rel="noopener noreferrer">
                                        {chunks}
                                    </a>
                                  )
                                }}
                                />
                              }>
                              <FormFeedback><FormattedMessage id="etoll:rodoFeedback" defaultMessage="Zgoda jest wymagana" /></FormFeedback>
                            </CustomInput>
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Col sm={4} />
                          <Col sm={8} style={{ position: "relative" }}>
                            <CustomInput type="checkbox" name="processingAccepted" id="processingAccepted" onChange={this.onChange} checked={processingAccepted} valid={processingAccepted || allChecked}
                              invalid={!processingAccepted} label={
                                <FormattedMessage id="etoll:processing" defaultMessage="Zgoda na przesyłanie informacji drogą elektroniczną" />
                              }>
                              <FormFeedback><FormattedMessage id="etoll:processingFeedback" defaultMessage="Zgoda jest wymagana" /></FormFeedback>
                            </CustomInput>
                          </Col>
                        </FormGroup>
                      </Fragment>
                    )}
                  </Form>

                  {registrationVisible && (
                    <Button color="primary" className="float-right" onClick={this.register} disabled={submitDisabled}
                      title={submitDisabled ? intl.formatMessage({ id: "etoll:formErrorsTitle", defaultMessage: "Popraw błędy w formularzu" }) : ""}>
                        <FormattedMessage id="etoll:register" defaultMessage="Zarejestruj" />
                    </Button>
                  )}

                  {(!deviceInfo && !registrationVisible) && (
                    <Button color="primary" className="float-right" onClick={this.checkDevice} disabled={etollNumberInvalid || etollPinInvalid}
                      title={etollNumberInvalid || etollPinInvalid ? intl.formatMessage({ id: "etoll:formErrorsTitle", defaultMessage: "Popraw błędy w formularzu" }) : ""}>
                        <FormattedMessage id="etoll:formSend" defaultMessage="Sprawdź" />
                    </Button>
                  )}
                </Fragment>
              )}

              {registered && (
                <Alert color="success"><FormattedMessage id="etoll:registrationSuccess" defaultMessage="Rejestracja zakończona powodzeniem!" /></Alert>
              )}

              {paymentInProcess && (
                <Alert color="info" fade={false}><FormattedMessage id="etoll:paymentInProgress" defaultMessage="Oczekiwanie na potwierdzenie płatności..." /></Alert>
              )}

              {subscriptionRenewed && (
                <Alert color="success" fade={false}>
                  <FormattedMessage
                    id="etoll:subscriptionRenewedInfo"
                    defaultMessage="Abonament został pomyślnie przedłużony! Faktura VAT zostanie przesłana na podany wcześniej adres e-mail."
                  />
                </Alert>
              )}

              {deviceInfo && (
                <EtollDeviceInfo
                  {...deviceInfo}
                  etollNumber={etollNumber}
                  etollPin={etollPin}
                  refresh={this.checkDevice}
                  intl={intl} />
              )}
            </Fragment>
          );
        }}
      </LocaleContext.Consumer>
    );
  }
}

export default withAlert(injectIntl(EtollRegistrationForm));
