import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { CustomInput } from "reactstrap";

import { withLoading, DeviceTableRow as Device, SaveCancelBar } from "./";
import { withAlert } from "./Alert";
import { boolToInt } from "../utils";

class DevicesPickerTable extends Component {
  handleResponse = r => {
    if (r.result === 0) {
      this.props.showAlert("Zmiany zostały zapisane!", "success", this.props.refreshDevicesList);
    }
    else {
      this.handleError();
    }
  };

  handleError = () => {
    this.props.showAlert("Zmiany nie zostały zapisane!", "danger");
  };

  cancel = selectElements => () => {
    selectElements(this.props.devices.reduce((a, x) => {
      if (x.selected) {
        a.push(x.id);
      }

      return a;
    }, []), true);
  };

  save = () => {
    this.props.save()// make sure the save methd is returning a promise)
      .then(this.handleResponse)
      .catch(this.handleError);
  };

  selectGroup = (group, selected) => () => {
    let devicesIds = [];
    if (selected) { // all selected before and all in group
      devicesIds = [ ...Object.values(this.props.selectedDevices).filter(x => x).map(x => x.id), ...group.devices.map(x => x.id)];
    }
    else { // all selected before without all in group
      devicesIds = [ ...Object.values(this.props.selectedDevices).filter(x => !group.devices.find(y => y.id === x.id)).map(x => x.id) ];
    }

    this.props.selectElements(devicesIds, true);
  };

  isGroupSelected = group => {
    return group.devices.every(x => !!this.props.selectedDevices[x.id]);
  };

  render() {
    const {
      devicesGroups,
      className,
      selectedDevices,
      selectElement,
      selectElements,
      selected,
      deselected,
      isDirty,
      canAssignDevices,
      canAccessDeveloperSettings,
      selectAll,
      deselectAll,
      areAllSelected,
      canAccessSimCards,
    } = this.props;

    if (!devicesGroups) {
      return <div className={className} />;
    }
    
    const allSelected = areAllSelected();
    const colCount = boolToInt(canAccessDeveloperSettings) + 9;
    return (
      <Fragment>
        <div style={{ height: "calc(100% - 1rem)", overflowY: "auto" }}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th style={{ width: "50px" }}>
                  <CustomInput type="checkbox" id="selectAllDevices" checked={allSelected} onChange={allSelected ? deselectAll : selectAll} disabled={!canAssignDevices} />
                </th>
                <th>Nazwa</th>
                <th>Marka i model</th>
                <th>Nr rejestracyjny</th>
                <th>Nr boczny</th>
                <th>Nr seryjny</th>
                <th style={{ width: "130px" }}>IMEI</th>
                <th style={{ width: "180px" }}>CCID</th>
                <th style={{ width: "130px"}}>Nr tel.</th>
                {canAccessDeveloperSettings && <th style={{ width: "40px" }}>Id</th>}
              </tr>
            </thead>
            <tbody>
              {devicesGroups.map(devicesGroup => {
                const isGroupSelected = this.isGroupSelected(devicesGroup);

                return (
                  <Fragment key={devicesGroup.group.id}>
                    <tr>
                      <td>
                        <CustomInput type="checkbox" id={`group#${devicesGroup.group.id}`} checked={isGroupSelected} onChange={this.selectGroup(devicesGroup, !isGroupSelected)} disabled={!canAssignDevices} />
                      </td>

                      <td colSpan={colCount - 1}>
                        <strong style={{ fontSize: "1rem" }}>{devicesGroup.group.name || "Bez nazwy"}</strong>
                      </td>
                    </tr>

                    {devicesGroup.devices.map(device => (
                      <Device
                        showCheckboxes
                        key={device.id}
                        device={device}
                        hasReassignPermission={canAssignDevices}
                        canAccessDeveloperSettings={canAccessDeveloperSettings}
                        selected={!!selectedDevices[device.id]}
                        selectElement={selectElement}
                        canAccessSimCards={canAccessSimCards}
                      />
                    ))}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>

        <SaveCancelBar isDirty={isDirty} cancel={this.cancel(selectElements)} save={this.save}>
          <Fragment>
            <span className="mr-2">Dodane: {selected.length}</span>
            <span className="mr-2">Usunięte: {deselected.length}</span>
          </Fragment>
        </SaveCancelBar>
      </Fragment>
    );
  }
}

DevicesPickerTable.propTypes = {
  refreshDevicesList: PropTypes.func.isRequired,
  selectElement: PropTypes.func.isRequired,
  selectElements: PropTypes.func.isRequired,
  canAssignDevices: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  devicesGroups: PropTypes.array,
  devices: PropTypes.array,
};

export default withAlert(withLoading(DevicesPickerTable));
