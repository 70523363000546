import React, { createRef, PureComponent } from "react";

import Pages from "./pages";
import { FilteredList } from "../../components";
import { getSimCards } from "../../api";
import SimCardsContainer from "./SimCardsContainer";

import "./SimCards.css";

const DEVICE_OR_TERMINAL_ASSIGNED_PREFIX = "przypisane";
const DEVICE_AND_TERMINAL_NOT_ASSIGNED_PREFIX = "nieprzypisane";
const CLIENT_PREFIX = "klient:"

const filterOptions = [
  {
    label: DEVICE_AND_TERMINAL_NOT_ASSIGNED_PREFIX,
    value: DEVICE_AND_TERMINAL_NOT_ASSIGNED_PREFIX
  },
  {
    label: DEVICE_OR_TERMINAL_ASSIGNED_PREFIX,
    value: DEVICE_OR_TERMINAL_ASSIGNED_PREFIX
  },
  {
    label: CLIENT_PREFIX,
    value: CLIENT_PREFIX,
    prefix: true
  }
];

const compareStrings = (field, phrase) => field && field.toLocaleLowerCase().includes(phrase);

const isSimCardMatchingKeyword = (simCard, keyword) => {
  const phrase = keyword.value.toLocaleLowerCase();

  if (keyword.value === DEVICE_OR_TERMINAL_ASSIGNED_PREFIX) {
    return !!simCard.device || !!simCard.terminal;
  }
  else if (keyword.value === DEVICE_AND_TERMINAL_NOT_ASSIGNED_PREFIX) {
    return !simCard.device && !simCard.terminal;
  }
  else if (keyword.value.startsWith(CLIENT_PREFIX)) {
    if (simCard.device && simCard.device.clientName) {
      const clientName = keyword.value.replace(CLIENT_PREFIX, "").toLocaleLowerCase();

      const result = compareStrings(simCard.device.clientName, clientName);

      return result;
    }
    else if (simCard.terminal && simCard.terminal.clientName) {
      const clientName = keyword.value.replace(CLIENT_PREFIX, "").toLocaleLowerCase();

      const result = compareStrings(simCard.terminal.clientName, clientName);

      return result;
    }

    return false;
  }

  const isdeviceMatching = simCard.device
    && (
      compareStrings(simCard.device.description, phrase)
      || compareStrings(simCard.device.vrn, phrase)
      || compareStrings(simCard.device.serialNumber, phrase)
      || compareStrings(simCard.device.imei, phrase)
      || compareStrings(simCard.device.etollNumber, phrase)
    );

  if (isdeviceMatching) {
    return true;
  }
  
  const isTerminalMatching = simCard.terminal
    && (
      compareStrings(simCard.terminal.imei, phrase)
      || compareStrings(simCard.terminal.sn, phrase)
    );

  return isTerminalMatching
    || compareStrings(simCard.ccid, phrase)
    || compareStrings(simCard.phone, phrase)
    || compareStrings(simCard.accountName, phrase);
};

const filterBasedOnKeywords = (simCard, keywords) => {
  for (const keyword of keywords) {
    const isKeywordMatching = isSimCardMatchingKeyword(simCard, keyword);
    
    if (!isKeywordMatching) {
      return false;
    }
  }
  
  return true;
};

const filterSimCards = (data, keywords) => {
  if (!keywords || !keywords.length) {
    return data;
  }
  
  return data.filter(x => filterBasedOnKeywords(x, keywords));
};

const sortSimCards = data => {
  return data.sort((a, b) => 
    a.ccid.localeCompare(b.ccid, { numeric: true }) // sort by ccid asc
  );
};

const filterFormatOptionLabel = (option, { context }) => {
  return (
    <span>
      <span>{option.label}</span>
      {option.value === CLIENT_PREFIX && <span className="text-muted">nazwa_klienta</span>}
    </span>
  );
};

class SimCards extends PureComponent {
  simCardsListRef = createRef();
    
  renderPages = (selectedElements, selectedCount, refresh) => {
    return selectedCount > 0
      ? <Pages selectedSimCards={selectedElements} selectedCount={selectedCount} refreshSimCardsList={refresh} />
      : <p className="p-3">Wybierz przynajmniej jedną kartę SIM</p>;
  };

  getSimCard = (simCards, key) => simCards.find(simCard => simCard.id === parseInt(key, 10));

  listChangedCallback = () => {
    this.simCardsListRef.current?.refreshListCache();
  };

  render() {
    const {
      match: {
        params: { simCardId },
      },
    } = this.props;

    return (
      <FilteredList endpoint={getSimCards} keyField="id" itemGetter={this.getSimCard} listFilter={filterSimCards} listSorter={sortSimCards} listChangedCallback={this.listChangedCallback} keepSelectionsAfterRefresh urlSelectKey={simCardId}>
        {({ fullList, filteredList, selectedElements, isFetching }, { refresh, refreshComponents, onFilterChanged, selectElement, selectAll, deselectAll, areAllSelected, selectedCount }) =>
          <div className="content-sim">
            <SimCardsContainer
              onFilterChanged={onFilterChanged}
              refresh={refresh}
              filteredList={filteredList}
              selectedElements={selectedElements}
              selectElement={selectElement}
              isFetching={isFetching}
              simCardsListRef={this.simCardsListRef}
              filterOptions={filterOptions}
              filterFormatOptionLabel={filterFormatOptionLabel}
            />

            <section>
              {this.renderPages(selectedElements, selectedCount(), refresh)}
            </section>
          </div>
        }
      </FilteredList>
    );
  }
}

export default SimCards;
