import React, { Component } from "react";
import PropTypes from "prop-types";

import UsersView from "./UsersView";
import { roleAvailableUsers } from "../../../../../../../api";
import { FilteredList } from "../../../../../../../components";

class Users extends Component {
  onListChangedCallback = (filteredList, init) => {
    if (init) {
      const selectedElements = filteredList.reduce((a, x) => {
        if (x.selected) {
          a.push(x.id);
        }

        return a;
      }, []);
  
      if (selectedElements.length) {
        this.selectElements(selectedElements, true);
      }
    }
  };

  render() {
    const { role, refreshRolesList } = this.props;
    const roleId = role.id;
    const _this = this;

    return (
      <FilteredList multiselect dependencyId={roleId} endpoint={roleAvailableUsers} data={{ roleId }} keyField="id" listChangedCallback={this.onListChangedCallback}>
        {({ fullList, filteredList, selectedElements, isFetching }, { refresh, refreshComponents, onFilterChanged, selectElement, selectAll, deselectAll, areAllSelected, selectedCount, selectElements }) => {
          _this.selectElements = selectElements;

          return (<UsersView isFetching={isFetching} role={role} users={fullList} usersFiltered={filteredList} refreshUsersList={refresh} selectedUsers={selectedElements} selectElement={selectElement} selectElements={selectElements} refreshRolesList={refreshRolesList} />);
        }}
      </FilteredList>
    );
  }
}

Users.propTypes = {
  role: PropTypes.object.isRequired,
};

export default Users;
