export const debounce = (func, debounceInterval = 200) => {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      timeout = null;
      func(...args);
    }, debounceInterval);
  };
};

export const isFunction = func => func && typeof func === "function";
export const isBoolean = value => value === true || value === false;

export const generateRandomString = (length, includeDigits = true, includeSpecialChars = true) => {
  const result = [];
  const chars = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
  
  if (includeDigits) {
    const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    chars.push(...digits);
  }
  
  if (includeSpecialChars) {
    const specialChars = ["!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_"];
    chars.push(...specialChars);
  }

  for (let i = 0; i < length; i++) {
    result.push(chars[Math.floor(Math.random() * chars.length)]);
  }

  return result.join("");
};

export const generateRandomPassword = () => {
  let pass = "";

  do {
    pass = generateRandomString(6, true, false);
  }
  while (!isValidPassword(pass));

  return pass;
};

export const isValidPassword = password => {
  return password && password.length >= 6 && /\d+/.test(password);
};

export const boolToInt = value => value ? 1 : 0;

export const formatPhone = phone =>
  phone && phone.length
    ? phone.match(/.{1,3}/g)
      .join(" ")
    : "";

export const formatUser = user =>
  user.firstName && user.surname
    ? `${user.firstName} ${user.surname}`
    : (user.name || user.login);
