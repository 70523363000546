import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import {
  Navbar,
  NavbarToggler,
  Collapse,
  NavbarBrand,
  Nav as NavMenu,
  NavItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Auth from "../../Auth";
import { isProduction } from "../../config";

class Nav extends Component {
  state = {
    isMenuOpen: false,
  };

  logout = logout => () => logout(this.onLoggedOut);

  onLoggedOut = () => {
    //this.props.history.push("/login", { from: this.props.location });
    // window.location.href = `${window.location.origin}/login?redir=${
    //   window.location.pathname
    // }`; // TODO: don't add redir param
    window.location.href = `${window.location.origin}/login`;
  };

  toggleMenu = () => {
    this.setState(prevState => ({ isMenuOpen: !prevState.isMenuOpen }));
  };

  formatUser = user =>
    user.firstName && user.surname ? `${user.firstName} ${user.surname}` : (user.name || user.login);

  render() {
    const { pages } = this.props;

    return (
      <Auth.Consumer>
        {({ user, logout }) => (
          <Navbar
            color={isProduction ? "dark" : "dark-dev"}
            dark
            expand="sm"
            className="sticky-top">
            <NavbarBrand href="#">
              SkautAdmin
              {isProduction ? (
                ""
              ) : (
                  <small style={{ fontSize: "70%" }}>dev</small>
                )}
            </NavbarBrand>
            <NavbarToggler
              label="Toggle navigation"
              onClick={this.toggleMenu}
            />
            <Collapse navbar isOpen={this.state.isMenuOpen}>
              {!!user && (
                <NavMenu navbar className="mt-2 mt-md-0">
                  {pages.map(page => (
                    <NavItem key={page.path}>
                      <NavLink to={page.path} className="nav-link">
                        {page.name}
                      </NavLink>
                    </NavItem>
                  ))}
                </NavMenu>
              )}

              {!!user && (
                <form className="ml-auto form-inline navbar-right mt-2 mt-md-0">
                  <div
                    className="form-group mr-2"
                    style={{ color: "#9d9d9d" }}>
                    <FontAwesomeIcon icon="user" size="sm" fixedWidth />
                    <span>{this.formatUser(user)}</span>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-danger"
                      type="button"
                      onClick={this.logout(logout)}>
                      Wyloguj
                    </button>
                  </div>
                </form>
              )}

              {!user && (
                <span className="ml-auto mr-2" style={{ color: "#9d9d9d" }}>
                  Gość
                </span>
              )}
            </Collapse>
          </Navbar>
        )}
      </Auth.Consumer>
    );
  }
}

export default withRouter(Nav);
