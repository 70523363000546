import React, { Component, PureComponent } from "react";
import { Button, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import { isSameDay, parseISO } from "date-fns";

import { withAlert } from "../../../../../components/Alert";
import { endService } from "../../../../../api";

import "react-datepicker/dist/react-datepicker.css";

class DateInput extends PureComponent {
  render() {
    return (
      <input
        type="text"
        style={{ padding: "5px 8px", height: "auto", display: "inline-block", width: "100px" }}
        {...this.props}
      />
    );
  }
}

class EndServiceButton extends Component {
  state = {
    isDropdownOpen: false,
    endDate: new Date(),
  };

  endService = () => {
    endService({
      serviceId: this.props.id,
      end: this.state.endDate,
    })
      .then(r => {
        if (r.result === 0) {
          this.props.showAlert("Pakiet został zakończony", "success");
          this.onSuccess();
        }
      })
      .catch(() => {
        this.props.showAlert("Błąd serwera", "danger");
      });
  };

  onSuccess = () => {
    this.toggleDropdown(this.props.onSuccess);
  }

  toggleDropdown = cb => {
    const callback = cb && cb.apply ? cb : undefined;

    this.setState(prevState => ({
      isDropdownOpen: !prevState.isDropdownOpen,
      endDate: prevState.isDropdownOpen ? new Date() : prevState.endDate,
    }), callback);
  };

  setEndDate = endDate => {
    this.setState(() => ({ endDate }));
  }

  render() {
    const { isDropdownOpen, endDate } = this.state;
    const { startDate } = this.props;
    
    return (
      <Dropdown isOpen={isDropdownOpen} toggle={this.toggleDropdown} direction="up">
        <DropdownToggle color="danger" size="xs">
          Zakończ
        </DropdownToggle>
        <DropdownMenu>
          <div className="col text-danger">Przed zakończeniem pakietu, upewnij się, że klient został za niego rozliczony!</div>

          <div className="col d-flex" style={{ width: "210px" }}>
            <DatePicker
              className={classNames("form-control", { "is-warning": isSameDay(endDate, parseISO(this.props.endDate)) })}
              customInput={<DateInput />}
              locale="pl"
              dateFormat="yyyy-MM-dd"
              selected={endDate}
              onChange={this.setEndDate}
              selectsStart={startDate}
              placeholderText="data zakończenia"
              popperPlacement="left"
              popperModifiers={{
                offset: {
                  enabled: true,
                  offset: "130px, 10px",
                },
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: "scrollParent",
                },
              }}
            />
            <Button color="danger" className="ml-2" style={{ verticalAlign: "unset" }} outline onClick={this.endService}>Zapisz</Button>
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default withAlert(EndServiceButton);
