import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Filter } from "../../components";
import AddVersion from "./AddVersion";
import Auth from "../../Auth";

const tooltip = (
  <div className="text-left">
    <span>Filtrowanie po:</span>
    <ul className="mb-0 pl-4">
      <li>IMEI, nr. seryjnym i wersji aplikacji</li>
      <li>nazwie klienta</li>
      <li>nazwie, nr. rejestracyjnym i nr. pojazdu</li>
      <li>nazwie klienta pojazdu</li>
    </ul>
  </div>
);

class TerminalsFilter extends PureComponent {
  refresh = () => this.props.refresh();

  render() {
    const { selectAll, deselectAll, allSelected } = this.props;

    return (
      <Auth.Consumer>
        {({ hasPermission }) => {
          const canAddTerminalAppVersion = hasPermission("addingTerminalAppVersions");

          return <Filter
            selectAll={selectAll}
            deselectAll={deselectAll}
            refresh={this.refresh}
            allSelected={allSelected}
            onChange={this.props.onChange}
            tooltip={tooltip}
            maxWidth={canAddTerminalAppVersion ? 220 : 260}
            addons={
              canAddTerminalAppVersion
                ? <AddVersion />
                : null
            }
          />;
        }}
      </Auth.Consumer>
    );
  }
}

TerminalsFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectAll: PropTypes.func.isRequired,
  deselectAll: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  allSelected: PropTypes.bool,
};

export default TerminalsFilter;
